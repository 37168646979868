import React from 'react';
import styled from '@emotion/styled';
import Select from '../../components/common/Select/Select';
import { useNavigate } from 'react-router-dom';
import { Shipment, ShipmentStatus } from '../../types/models/shipment';

interface ShipmentTableProps {
  type: 'inbound' | 'outbound';
  data: Shipment[];
  starredShipments: string[];
  onToggleStar: (trackingNumber: string) => void;
  studyFilter: string;
  statusFilter: string;
  startDate: Date | null;
  endDate: Date | null;
  onStudyFilterChange: (value: string, type: 'inbound' | 'outbound') => void;
  onStatusFilterChange: (value: string, type: 'inbound' | 'outbound') => void;
  onDateChange: (dates: [Date | null, Date | null], type: 'inbound' | 'outbound') => void;
  showMoreButton: React.ReactNode;
}

const getStatusColor = (status: string): string => {
  switch (status) {
    case 'Cancelled':
      return '#FF0000';
    case 'On Time':
      return '#00A651';
    case 'Delayed':
      return '#FFA500';
    default:
      return '#666666';
  }
};

const getStatusText = (status: ShipmentStatus): string => {
  switch (status) {
    case ShipmentStatus.CANCELLED:
      return 'Cancelled';
    case ShipmentStatus.IN_TRANSIT:
      return 'On Time';
    case ShipmentStatus.DELIVERED:
      return 'Delivered';
    case ShipmentStatus.PENDING:
      return 'Delayed';
    case ShipmentStatus.FAILED_PICKUP:
      return 'Failed Pickup';
    default:
      return status;
  }
};

const ShipmentTable: React.FC<ShipmentTableProps> = ({ 
  type, 
  data, 
  starredShipments,
  onToggleStar,
  studyFilter,
  statusFilter,
  startDate,
  endDate,
  onStudyFilterChange,
  onStatusFilterChange,
  onDateChange,
  showMoreButton
}) => {
  const navigate = useNavigate();

  return (
    <TableContainer>
      <TableHeader>
        <TableTitle>{type === 'inbound' ? 'Inbound' : 'Outbound'}</TableTitle>
        <FilterContainer>
          <FilterGroup>
            <FilterLabel>Status</FilterLabel>
            <Select
              placeholder="Status"
              value={statusFilter}
              onChange={(value) => onStatusFilterChange(value, type)}
              options={[
                { value: 'all', label: 'All Statuses' },
                { value: 'Delayed', label: 'Delayed' },
                { value: 'Delivered', label: 'Delivered' }
              ]}
            />
          </FilterGroup>
          <FilterGroup>
            <FilterLabel>Date Range</FilterLabel>
            <DateRangeContainer>
              <StyledInput
                type="date"
                value={startDate ? startDate.toISOString().split('T')[0] : ''}
                onChange={(e) => {
                  const newStartDate = e.target.value ? new Date(e.target.value) : null;
                  onDateChange([newStartDate, endDate], type);
                }}
                placeholder="Start date"
              />
              <span>to</span>
              <StyledInput
                type="date"
                value={endDate ? endDate.toISOString().split('T')[0] : ''}
                onChange={(e) => {
                  const newEndDate = e.target.value ? new Date(e.target.value) : null;
                  onDateChange([startDate, newEndDate], type);
                }}
                placeholder="End date"
              />
            </DateRangeContainer>
          </FilterGroup>
        </FilterContainer>
      </TableHeader>

      <Table>
        <thead>
          <tr>
            <Th>Tracking #</Th>
            <Th>{type === 'inbound' ? 'Origin' : 'Destination'}</Th>
            <Th>Study</Th>
            <Th>Status</Th>
            <Th>Expected Arrival</Th>
            <Th></Th>
          </tr>
        </thead>
        <tbody>
          {data.map((shipment) => {
            const statusText = getStatusText(shipment.status);
            return (
              <TableRow 
                key={shipment.tracking_number}
                onClick={() => navigate(`/shipment-manager/${shipment.id}`)}
              >
                <Td>{shipment.tracking_number}</Td>
                <Td>
                  {type === 'inbound' ? shipment.origin : shipment.destination}
                </Td>
                <Td>{shipment.trial_id}</Td>
                <Td>
                  <StatusText color={getStatusColor(statusText)}>
                    {statusText}
                  </StatusText>
                </Td>
                <Td>
                  {shipment.expected_delivery ? new Date(shipment.expected_delivery).toLocaleString('en-US', {
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                  }) : 'Unknown'}
                </Td>
                <Td>
                  <StarButton 
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onToggleStar(shipment.tracking_number);
                    }}
                    isStarred={starredShipments.includes(shipment.tracking_number)}
                  >
                    ★
                  </StarButton>
                </Td>
              </TableRow>
            );
          })}
          {showMoreButton && (
            <TableRow>
              <Td colSpan={7} style={{ textAlign: 'center', padding: '16px' }}>
                {showMoreButton}
              </Td>
            </TableRow>
          )}
        </tbody>
      </Table>
    </TableContainer>
  );
};

const TableContainer = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px 24px 24px 8px;
  margin-top: 24px;
  border: 1px solid #E0E0E0;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-left: 16px;
`;

const TableTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  color: #666;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  text-align: left;
  padding: 12px 16px;
  border-bottom: 1px solid #E0E0E0;
  color: #666;
  font-weight: 500;
  font-size: 14px;
`;

const Td = styled.td`
  padding: 12px 16px;
  border-bottom: 1px solid #E0E0E0;
  color: #666;
  font-size: 14px;
`;

const TableRow = styled.tr`
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const StatusText = styled.span<{ color: string }>`
  color: ${props => props.color};
  font-weight: 500;
`;

const SampleIdContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const SampleIdPill = styled.span<{ success: boolean }>`
  padding: 4px 8px;
  border-radius: 16px;
  background: ${props => props.success ? '#E8F5E9' : '#FFEBEE'};
  color: ${props => props.success ? '#00A651' : '#FF0000'};
  font-size: 12px;
  font-weight: 500;
`;

const StarButton = styled.button<{ isStarred: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.isStarred ? '#FFD700' : '#E0E0E0'};
  font-size: 20px;
  padding: 4px;
  transition: color 0.2s ease;
  
  &:hover {
    color: ${props => props.isStarred ? '#FFC000' : '#FFD700'};
  }
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const FilterLabel = styled.label`
  font-size: 12px;
  color: #666;
  font-weight: 500;
`;

const DateRangeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  
  span {
    color: #666;
    font-size: 14px;
  }
`;

const StyledInput = styled.input`
  padding: 8px 12px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  font-size: 14px;
  color: #666;
  background: white;
  
  &:focus {
    outline: none;
    border-color: #0066cc;
  }
  
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
`;

export default ShipmentTable; 