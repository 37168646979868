import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../client';
import { ENDPOINTS } from '../config';
import type { ApiResponse, PaginatedApiResponse } from '../../types/api';
import type { ContactPerson } from '../../types/models/contact-person';

interface ContactPersonFilters {
  role?: string;
  relationship?: string;
  search?: string;
}

export const contactPersonApi = {
  // Basic CRUD
  getContactPersons: async (params?: ContactPersonFilters): Promise<ApiResponse<PaginatedApiResponse<ContactPerson>>> => {
    return api.get(ENDPOINTS.contactPersons.base, { params });
  },

  getContactPerson: async (id: string): Promise<ApiResponse<ContactPerson>> => {
    return api.get(ENDPOINTS.contactPersons.byId(id));
  },

  createContactPerson: async (data: Partial<ContactPerson>): Promise<ApiResponse<ContactPerson>> => {
    return api.post(ENDPOINTS.contactPersons.base, data);
  },

  updateContactPerson: async (id: string, data: Partial<ContactPerson>): Promise<ApiResponse<ContactPerson>> => {
    return api.put(ENDPOINTS.contactPersons.byId(id), data);
  },

  deleteContactPerson: async (id: string): Promise<ApiResponse<void>> => {
    return api.delete(ENDPOINTS.contactPersons.byId(id));
  },

  // Role-based queries
  getByRole: async (role: string): Promise<ApiResponse<PaginatedApiResponse<ContactPerson>>> => {
    return api.get(`${ENDPOINTS.contactPersons.base}/by-role/${role}`);
  },

  // Relationship-based queries
  getByRelationship: async (relationship: string): Promise<ApiResponse<PaginatedApiResponse<ContactPerson>>> => {
    return api.get(`${ENDPOINTS.contactPersons.base}/by-relationship/${relationship}`);
  },

  // Associated entities
  getPatientContacts: async (patientId: string): Promise<ApiResponse<ContactPerson[]>> => {
    return api.get(`${ENDPOINTS.contactPersons.base}/patient/${patientId}`);
  },

  getSiteContacts: async (siteId: string): Promise<ApiResponse<ContactPerson[]>> => {
    return api.get(`${ENDPOINTS.contactPersons.base}/site/${siteId}`);
  },

  searchContactPersons: async (filters?: ContactPersonFilters) => {
    console.log('🔍 Searching contacts with filters:', filters);
    try {
      const response = await api.get<ApiResponse<ContactPerson[]>>(
        ENDPOINTS.contactPersons.base,
        { 
          params: {
            ...filters,
            search: filters?.search?.trim(),
            limit: 10
          }
        }
      );
      console.log('📬 Contact persons API response:', response);
      console.log('📋 Contact persons data:', response.data);
      return response.data;
    } catch (error) {
      console.error('❌ Error fetching contacts:', error);
      throw error;
    }
  }
};

// React Query hooks
export const useContactPersons = (params?: ContactPersonFilters) => {
  return useQuery(
    ['contactPersons', params], 
    () => contactPersonApi.searchContactPersons(params),
    {
      enabled: true,
      staleTime: 30000, // Cache for 30 seconds
      keepPreviousData: true // Keep showing previous results while loading new ones
    }
  );
};

export const useContactPerson = (id: string) => {
  return useQuery(
    ['contactPerson', id], 
    () => contactPersonApi.getContactPerson(id),
    { enabled: !!id }
  );
};

export const useCreateContactPerson = () => {
  return useMutation(contactPersonApi.createContactPerson);
};

export const useUpdateContactPerson = () => {
  return useMutation(
    ({ id, data }: { id: string; data: Partial<ContactPerson> }) => 
      contactPersonApi.updateContactPerson(id, data)
  );
};

export const useDeleteContactPerson = () => {
  return useMutation(contactPersonApi.deleteContactPerson);
};

export const usePatientContacts = (patientId: string) => {
  return useQuery(
    ['patientContacts', patientId], 
    () => contactPersonApi.getPatientContacts(patientId),
    { enabled: !!patientId }
  );
};

export const useSiteContacts = (siteId: string) => {
  return useQuery(
    ['siteContacts', siteId], 
    () => contactPersonApi.getSiteContacts(siteId),
    { enabled: !!siteId }
  );
};

export const useContactsByRole = (role: string) => {
  return useQuery(
    ['contactsByRole', role], 
    () => contactPersonApi.getByRole(role),
    { enabled: !!role }
  );
};

export const useContactsByRelationship = (relationship: string) => {
  return useQuery(
    ['contactsByRelationship', relationship], 
    () => contactPersonApi.getByRelationship(relationship),
    { enabled: !!relationship }
  );
}; 