import React, { useState } from 'react';
import styled from '@emotion/styled';
import PageLayout from '../../components/common/Layout/PageLayout';
import { useNavigate } from 'react-router-dom';
import { usePickup } from '../../contexts/PickupContext';

const PickupDetails: React.FC = () => {
  const navigate = useNavigate();
  const { updatePickupData, generateConfirmation } = usePickup();
  
  const [formData, setFormData] = useState({
    totalPackages: '',
    pickupDate: '',
    earliestTime: '',
    latestTime: '',
    locationInstructions: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validate required fields
    if (!formData.totalPackages || !formData.pickupDate || !formData.earliestTime || !formData.latestTime) {
      alert('Please fill in all required fields');
      return;
    }

    // Update context with form data
    updatePickupData({
      totalPackages: Number(formData.totalPackages),
      pickupDate: formData.pickupDate,
      earliestTime: formData.earliestTime,
      latestTime: formData.latestTime,
      locationInstructions: formData.locationInstructions
    });

    // Generate confirmation number and site ID
    generateConfirmation();
    
    // Navigate to confirmation page
    navigate('/schedule-pickup/confirmation');
  };

  return (
    <PageLayout title="Schedule Pickups">
      <FormContainer onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Total No. of Packages*</Label>
          <Input 
            type="number"
            name="totalPackages"
            value={formData.totalPackages}
            onChange={handleChange}
            placeholder="Value"
            required
            min="1"
          />
        </FormGroup>

        <FormGroup>
          <Label>Date*</Label>
          <Input 
            type="date"
            name="pickupDate"
            value={formData.pickupDate}
            onChange={handleChange}
            required
            min={new Date().toISOString().split('T')[0]}
          />
        </FormGroup>

        <FormGroup>
          <Label>Earliest Available Time*</Label>
          <Input 
            type="time"
            name="earliestTime"
            value={formData.earliestTime}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Latest Available Time*</Label>
          <Input 
            type="time"
            name="latestTime"
            value={formData.latestTime}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Notes for Driver</Label>
          <TextArea 
            name="locationInstructions"
            value={formData.locationInstructions}
            onChange={handleChange}
            placeholder="Value"
          />
        </FormGroup>

        <SubmitButton type="submit">Submit</SubmitButton>
      </FormContainer>
    </PageLayout>
  );
};

const FormContainer = styled.form`
  max-width: 800px;
  margin: 0 auto;
  padding: 24px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 24px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  font-size: 14px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  font-size: 14px;
  min-height: 100px;
  resize: vertical;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  background: #333;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #000;
  }
`;

export default PickupDetails; 