import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import PageLayout from '../../components/common/Layout/PageLayout';
import InventoryLevelsWidget from '../../components/Inventory/InventoryLevelsWidget';
import globeImage from '../../assets/shipmentmanagerglobe.png';


// Sample data for the inventory widget
const sampleInventoryData = [
  {
    studyId: 'EVGR-A-001',
    kits: [
      { name: 'Timestrip and Box v0.2', inventory: 3, threshold: 5, restockStatus: 'Scheduled' as const },
      { name: 'Timestrip and Box v0.1', inventory: 6, threshold: 5, restockStatus: 'Pending' as const },
      { name: 'Tasso Kit v0.1', inventory: 4, threshold: 3, restockStatus: 'None' as const }
    ]
  },
  {
    studyId: 'EVGR-A-002',
    kits: [
      { name: 'Tasso Kit v0.1', inventory: 5, threshold: 2, restockStatus: 'None' as const }
    ]
  },
  {
    studyId: 'EVGR-A-003',
    kits: [
      { name: 'Timestrip and Box v0.1', inventory: 5, threshold: 2, restockStatus: 'None' as const }
    ]
  }
];

const InventoryLevelsPage: React.FC = () => {
  const navigate = useNavigate();
  
  console.log('InventoryLevelsPage is rendering');

  return (
    <PageLayout>
      <Container>
        <HeaderContainer>
          <Title>Inventory Levels</Title>
          <ActionButtonsContainer>
            <ActionButton onClick={() => navigate('/send-kits')}>
              Send Out Kits
            </ActionButton>
            <ActionButton onClick={() => navigate('/shipment-manager')}>
              Track My Shipments
            </ActionButton>
            <ActionButton onClick={() => navigate('/schedule-pickup')}>
              Schedule Pickups
            </ActionButton>
          </ActionButtonsContainer>
        </HeaderContainer>

        <GlobeImage src={globeImage} alt="World Map" />

        <Section>
          <InventoryLevelsWidget />
        </Section>
      </Container>
    </PageLayout>
  );
};

const Container = styled.div`
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 500;
  margin: 0;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const ActionButton = styled.button`
  padding: 12px 24px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

const Section = styled.div`
  margin-bottom: 32px;
`;

const GlobeImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 32px;
`;

export default InventoryLevelsPage;
