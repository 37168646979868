import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../client';
import { ENDPOINTS } from '../config';
import type { ApiResponse, PaginatedApiResponse, QueryParams } from '../../types/api';
import type { Site } from '../../types/models/site';
import type { Address } from '../../types/models/address';

export interface SiteFilters extends QueryParams {
  trialId?: string;
  status?: 'active' | 'inactive';
  search?: string;
}

export const siteApi = {
  // Basic CRUD
  getSites: async (params?: SiteFilters): Promise<ApiResponse<Site[]>> => {
    return api.get(ENDPOINTS.sites.base, { params });
  },

  getSite: async (id: string): Promise<ApiResponse<Site>> => {
    return api.get(ENDPOINTS.sites.byId(id));
  },

  createSite: async (data: Partial<Site>): Promise<ApiResponse<Site>> => {
    return api.post(ENDPOINTS.sites.base, data);
  },

  updateSite: async (id: string, data: Partial<Site>): Promise<ApiResponse<Site>> => {
    return api.put(ENDPOINTS.sites.byId(id), data);
  },

  deleteSite: async (id: string): Promise<ApiResponse<void>> => {
    return api.delete(ENDPOINTS.sites.byId(id));
  },

  // Address validation and management
  validateAddress: async (address: Partial<Address>): Promise<ApiResponse<Address>> => {
    return api.post(`${ENDPOINTS.sites.base}/validate-address`, address);
  },

  // Batch operations
  bulkCreate: async (sites: Partial<Site>[]): Promise<ApiResponse<Site[]>> => {
    return api.post(`${ENDPOINTS.sites.base}/bulk`, sites);
  },

  // Get addresses for a trial
  getTrialAddresses: async (trialId: string): Promise<ApiResponse<Address[]>> => {
    return api.get(ENDPOINTS.addresses.base, { params: { trialId } });
  }
};

// React Query Hooks
export const useSites = (params?: SiteFilters) => {
  return useQuery(['sites', params], () => siteApi.getSites(params));
};

export const useSite = (id: string) => {
  return useQuery(['site', id], () => siteApi.getSite(id), {
    enabled: !!id,
  });
};

export const useCreateSite = () => {
  return useMutation(siteApi.createSite);
};

export const useUpdateSite = () => {
  return useMutation(
    ({ id, data }: { id: string; data: Partial<Site> }) => 
      siteApi.updateSite(id, data)
  );
};

export const useDeleteSite = () => {
  return useMutation(siteApi.deleteSite);
};

export const useValidateAddress = () => {
  return useMutation(siteApi.validateAddress);
};

export const useBulkCreateSites = () => {
  return useMutation(siteApi.bulkCreate);
};

export const useTrialAddresses = (trialId: string) => {
  return useQuery(
    ['trial', trialId, 'addresses'], 
    () => siteApi.getTrialAddresses(trialId),
    {
      enabled: !!trialId,
    }
  );
}; 