import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors } from '../constants/breakpoints';
import { Address } from '../../types/models/address';
import { useAddresses } from '../../api/endpoints/addresses';

interface AddressSelectionModalProps {
  onClose: () => void;
  onSelect: (address: Address) => void;
}

const AddressSelectionModal: React.FC<AddressSelectionModalProps> = ({
  onClose,
  onSelect
}) => {
  const { data: addresses } = useAddresses();
  const [searchTerm, setSearchTerm] = useState('');

  const addressList = Array.isArray(addresses) ? addresses : [];
  const filteredAddresses = addressList.filter((address: Address) => 
    address.street.toLowerCase().includes(searchTerm.toLowerCase()) ||
    address.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
    address.state.toLowerCase().includes(searchTerm.toLowerCase()) ||
    address.postal_code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Modal onClick={onClose}>
      <Content onClick={e => e.stopPropagation()}>
        <Header>
          <Title>Select Address</Title>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </Header>

        <SearchInput
          type="text"
          placeholder="Search addresses..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />

        <AddressList>
          {filteredAddresses.map((address: Address, index: number) => (
            <AddressItem key={index} onClick={() => onSelect(address)}>
              <div>{address.street}</div>
              <div>{`${address.city}, ${address.state} ${address.postal_code}`}</div>
            </AddressItem>
          ))}
          {filteredAddresses.length === 0 && (
            <NoResults>
              {searchTerm ? 'No addresses found' : 'Start typing to search addresses'}
            </NoResults>
          )}
        </AddressList>
      </Content>
    </Modal>
  );
};

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Content = styled.div`
  background: white;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 32rem;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid ${colors.border};
`;

const Title = styled.h2`
  margin: 0;
  font-size: 1.25rem;
  color: ${colors.text.primary};
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${colors.text.secondary};
  padding: 0.5rem;
  
  &:hover {
    color: ${colors.text.primary};
  }
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  border: none;
  border-bottom: 1px solid ${colors.border};
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }
`;

const AddressList = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
`;

const AddressItem = styled.div`
  padding: 0.75rem;
  border: 1px solid ${colors.border};
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  
  &:hover {
    background-color: #f5f5f5;
  }
  
  div:first-of-type {
    font-weight: 500;
    margin-bottom: 0.25rem;
  }
  
  div:last-of-type {
    color: ${colors.text.secondary};
    font-size: 0.875rem;
  }
`;

const NoResults = styled.div`
  text-align: center;
  color: ${colors.text.secondary};
  padding: 2rem;
`;

export default AddressSelectionModal;
