import React from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../components/common/Layout/PageLayout';
import ShipmentLabelRow from '../../components/Shipment/ShipmentLabelRow';
import { ShipmentService } from '../../api/endpoints/shipments';
import { useSendOutKits } from '../../contexts/SendOutKitsContext';
import { useTrials } from '../../hooks/useTrials';
import { useShipments } from '../../api/endpoints/shipments';
import ShipmentHeader from '../../components/Shipment/ShipmentHeader';

const PackagesConfirmed: React.FC = () => {
  const navigate = useNavigate();
  const { 
    selectedTrialId, 
    selectedShipment,
    outboundShipment,
    returnShipment
  } = useSendOutKits();
  const { trials } = useTrials();
  const { data: shipments } = useShipments();

  // Format devices into a content string
  const formatContents = (devices: Array<{ name: string; quantity: number }>) => {
    return devices.map(device => `${device.name} (${device.quantity})`).join(', ');
  };

  // Calculate total quantity of devices
  const calculateQuantity = (devices: Array<{ quantity: number }>) => {
    return devices.reduce((sum, device) => sum + device.quantity, 0).toString();
  };

  const handleLabelClick = async (shipmentId: string) => {
    try {
      const labelBlob = await ShipmentService.getLabel(shipmentId);
      const url = window.URL.createObjectURL(labelBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `shipping-label-${shipmentId}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading label:', error);
    }
  };

  return (
    <PageLayout title="Send Out Kits">
      <Container>
        <ShipmentHeader 
          selectedTrialId={selectedTrialId}
          selectedShipmentId={selectedShipment}
          onTrialSelect={() => {}}
          onShipmentSelect={() => {}}
          trials={trials || []}
          shipments={shipments?.outboundData || []}
          readOnly={true}
        />
        <Title>Packages Confirmed</Title>
        
        <SectionContainer>
          <SectionTitle>Outbound Shipment</SectionTitle>
          <ShipmentLabelRow
            shipmentId={selectedShipment || 'outbound-pending'}
            quantity={calculateQuantity(outboundShipment.devices)}
            contents={formatContents(outboundShipment.devices)}
            service={outboundShipment.service}
            onLabelClick={() => handleLabelClick(selectedShipment || 'outbound-pending')}
          />
        </SectionContainer>

        {returnShipment && (
          <SectionContainer>
            <SectionTitle>Return Shipment</SectionTitle>
            <ShipmentLabelRow
              shipmentId={`return-${selectedShipment || 'pending'}`}
              quantity={calculateQuantity(returnShipment.devices)}
              contents={formatContents(returnShipment.devices)}
              service={returnShipment.service}
              onLabelClick={() => handleLabelClick(`return-${selectedShipment || 'pending'}`)}
            />
          </SectionContainer>
        )}

        <ButtonContainer>
          <Button onClick={() => navigate('/send-kits')}>Send More Kits</Button>
          <Button onClick={() => navigate('/shipment-manager')}>
            Go to Shipment Manager
          </Button>
        </ButtonContainer>
      </Container>
    </PageLayout>
  );
};

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 2rem;
`;

const SectionContainer = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

export default PackagesConfirmed; 