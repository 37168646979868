import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import PageLayout from '../../components/common/Layout/PageLayout';
import { ShipmentStatus } from '../../types/models/shipment';

interface DummyShipment {
  id: string;
  tracking_number: string;
  origin: string;
  destination: string;
  trial_id: string;
  status: ShipmentStatus;
  expected_delivery: string;
  sample_ids?: string[];
  type: 'inbound' | 'outbound';
  created_at: string;
  carrier: string;
  service_type: string;
}

const getStatusColor = (status: ShipmentStatus): string => {
  switch (status) {
    case ShipmentStatus.CANCELLED:
      return '#FF0000';
    case ShipmentStatus.IN_TRANSIT:
      return '#FFA500';
    case ShipmentStatus.DELIVERED:
      return '#00A651';
    case ShipmentStatus.PENDING:
      return '#666666';
    case ShipmentStatus.FAILED_PICKUP:
      return '#FF0000';
    default:
      return '#000000';
  }
};

const SiteShipmentDetails: React.FC = () => {
  const { trackingNumber } = useParams();
  const navigate = useNavigate();

  // Mock data - in a real app, this would come from an API
  const mockShipments: DummyShipment[] = [
    {
      id: '1',
      tracking_number: '788208439645',
      origin: 'SiteID-000',
      destination: 'SiteID-001',
      trial_id: 'EVGR-A-001',
      status: ShipmentStatus.CANCELLED,
      expected_delivery: '',
      sample_ids: ['9946', '9947'],
      type: 'inbound',
      created_at: new Date().toISOString(),
      carrier: 'UPS',
      service_type: 'Ground'
    },
    {
      id: '2',
      tracking_number: '788208439646',
      origin: 'SiteID-001',
      destination: 'SiteID-002',
      trial_id: 'EVGR-A-001',
      status: ShipmentStatus.PENDING,
      expected_delivery: '2024-02-24T10:30:00',
      type: 'inbound',
      created_at: new Date().toISOString(),
      carrier: 'FedEx',
      service_type: 'Express'
    },
    {
      id: '3',
      tracking_number: '788208439647',
      origin: 'SiteID-002',
      destination: 'SiteID-000',
      trial_id: 'EVGR-A-001',
      status: ShipmentStatus.IN_TRANSIT,
      expected_delivery: '2024-01-24T10:30:00',
      sample_ids: ['9948'],
      type: 'outbound',
      created_at: new Date().toISOString(),
      carrier: 'DHL',
      service_type: 'International'
    }
  ];

  const shipment = mockShipments.find(s => s.tracking_number === trackingNumber);

  if (!shipment) {
    return (
      <PageLayout>
        <Container>
          <HeaderContainer>
            <Title>Shipment Not Found</Title>
          </HeaderContainer>
          <EmptyState>The requested shipment could not be found.</EmptyState>
        </Container>
      </PageLayout>
    );
  }

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  return (
    <PageLayout>
      <Container>
        <HeaderContainer>
          <Title>Shipment Details</Title>
          <ActionButtonsContainer>
            <ActionButton onClick={() => navigate(-1)}>
              Back to Site
            </ActionButton>
          </ActionButtonsContainer>
        </HeaderContainer>

        <ShipmentCard>
          <ShipmentHeader>
            <div>
              <ShipmentType>{shipment.type === 'inbound' ? 'Inbound' : 'Outbound'} Shipment</ShipmentType>
              <TrackingNumber>Tracking #: {shipment.tracking_number}</TrackingNumber>
            </div>
            <StatusBadge status={shipment.status}>
              {shipment.status}
            </StatusBadge>
          </ShipmentHeader>

          <DetailGrid>
            <DetailSection>
              <SectionTitle>Shipment Information</SectionTitle>
              <DetailItem>
                <Label>Carrier:</Label>
                <Value>{shipment.carrier}</Value>
              </DetailItem>
              <DetailItem>
                <Label>Service:</Label>
                <Value>{shipment.service_type}</Value>
              </DetailItem>
              <DetailItem>
                <Label>Trial ID:</Label>
                <Value>{shipment.trial_id}</Value>
              </DetailItem>
              <DetailItem>
                <Label>Created:</Label>
                <Value>{formatDate(shipment.created_at)}</Value>
              </DetailItem>
              {shipment.expected_delivery && (
                <DetailItem>
                  <Label>Expected Delivery:</Label>
                  <Value>{formatDate(shipment.expected_delivery)}</Value>
                </DetailItem>
              )}
            </DetailSection>

            <DetailSection>
              <SectionTitle>Route Information</SectionTitle>
              <DetailItem>
                <Label>Origin:</Label>
                <Value>{shipment.origin}</Value>
              </DetailItem>
              <DetailItem>
                <Label>Destination:</Label>
                <Value>{shipment.destination}</Value>
              </DetailItem>
            </DetailSection>

            {shipment.sample_ids && shipment.sample_ids.length > 0 && (
              <DetailSection>
                <SectionTitle>Sample Information</SectionTitle>
                <DetailItem>
                  <Label>Sample IDs:</Label>
                  <Value>{shipment.sample_ids.join(', ')}</Value>
                </DetailItem>
              </DetailSection>
            )}
          </DetailGrid>
        </ShipmentCard>
      </Container>
    </PageLayout>
  );
};

const Container = styled.div`
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 500;
  margin: 0;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const ActionButton = styled.button`
  padding: 12px 24px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

const ShipmentCard = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid #E0E0E0;
`;

const ShipmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #E0E0E0;
`;

const ShipmentType = styled.h2`
  font-size: 24px;
  margin: 0 0 8px 0;
`;

const TrackingNumber = styled.div`
  color: #666;
  font-size: 16px;
`;

const StatusBadge = styled.div<{ status: ShipmentStatus }>`
  padding: 8px 16px;
  border-radius: 16px;
  background-color: ${props => getStatusColor(props.status)}20;
  color: ${props => getStatusColor(props.status)};
  font-weight: 500;
`;

const DetailGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 32px;
`;

const DetailSection = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.h3`
  font-size: 18px;
  margin: 0 0 16px 0;
`;

const DetailItem = styled.div`
  margin-bottom: 12px;
`;

const Label = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
`;

const Value = styled.div`
  color: #666;
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 48px;
  background: #f5f5f5;
  border-radius: 8px;
  color: #666;
`;

export default SiteShipmentDetails;
