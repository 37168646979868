import { useQuery } from '@tanstack/react-query';
import { shipmentsApi } from '../api/endpoints/shipments';
import type { ShipmentFilters, ShipmentResponse } from '../api/endpoints/shipments';
import type { ApiResponse } from '../types/api';

export const useShipments = (trialId?: string) => {
  const queryKey = ['shipments', trialId];
  
  return useQuery(queryKey, () => 
    shipmentsApi.getShipments(trialId), {
      select: (data) => ({
        inboundData: data.inboundData || [],
        outboundData: data.outboundData || []
      })
    }
  );
}; 