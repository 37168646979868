import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import PageLayout from '../../components/common/Layout/PageLayout';
import InventoryLevelsTable from '../../components/Inventory/InventoryLevelsTable';
import InventoryLevelsWidget from '../../components/Inventory/InventoryLevelsWidget';
import HistoricalShipmentsTable from '../../components/Shipments/HistoricalShipmentsTable';
import { useNavigate, useParams } from 'react-router-dom';
import worldmap from '../../assets/shipmentmanagerglobe.png';
import { ShipmentStatus } from '../../types/models/shipment';
import { siteApi } from '../../api/endpoints/sites';
import type { Site } from '../../types/models/site';

const SitePage: React.FC = () => {
  const navigate = useNavigate();
  const { siteId } = useParams();
  const [selectedSite, setSelectedSite] = useState<Site | null>(null);
  const [sites, setSites] = useState<Site[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  
  // Separate filter states for InventoryLevelsTable
  const [inventoryStudyFilter, setInventoryStudyFilter] = useState('all');
  const [inventoryStatusFilter, setInventoryStatusFilter] = useState('all');
  const [inventoryTimeFilter, setInventoryTimeFilter] = useState('all');
  
  // Separate filter states for HistoricalShipmentsTable
  const [shipmentsStudyFilter, setShipmentsStudyFilter] = useState('all');
  const [shipmentsStatusFilter, setShipmentsStatusFilter] = useState('all');
  const [shipmentsTimeFilter, setShipmentsTimeFilter] = useState('all');
  
  const [starredShipments, setStarredShipments] = useState<string[]>([]);

  useEffect(() => {
    const fetchSites = async () => {
      try {
        const response = await siteApi.getSites();
        const fetchedSites = response.data as Site[];
        setSites(fetchedSites);
        
        // If there's a siteId in the URL, select that site
        if (siteId) {
          const site = fetchedSites.find(s => s.site_id === siteId);
          setSelectedSite(site || null);
        }
        
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching sites:', error);
        setIsLoading(false);
        setSites([]);
      }
    };

    fetchSites();
  }, [siteId]);

  // Mock data for inventory levels
  const mockInventoryData = [
    {
      studyId: 'EVGR-A-001',
      kits: [
        { name: 'Timestrip and Box v0.2', inventory: 5, threshold: 10, restockStatus: 'Scheduled' as const },
        { name: 'Timestrip and Box v0.1', inventory: 8, threshold: 15, restockStatus: 'None' as const }
      ]
    },
    {
      studyId: 'EVGR-A-002',
      kits: [
        { name: 'Timestrip and Box v0.1', inventory: 4, threshold: 3, restockStatus: 'Pending' as const }
      ]
    },
    {
      studyId: 'EVGR-A-003',
      kits: [
        { name: 'Timestrip and Box v0.1', inventory: 5, threshold: 2, restockStatus: 'None' as const }
      ]
    }
  ];

  const handleRestock = (studyId: string, kitName: string) => {
    console.log(`Restocking ${kitName} for study ${studyId}`);
  };

  // Mock data for now - replace with API calls
  const mockInboundShipments = [
    {
      id: '1',
      tracking_number: '788208439645',
      origin: 'SiteID-000',
      destination: 'SiteID-001',
      trial_id: 'EVGR-A-001',
      status: ShipmentStatus.CANCELLED,
      expected_delivery: '',
      sample_ids: ['9946', '9947'],
      type: 'inbound',
      created_at: new Date().toISOString(),
      carrier: 'UPS',
      service_type: 'Ground'
    },
    {
      id: '2',
      tracking_number: '788208439646',
      origin: 'SiteID-001',
      destination: 'SiteID-002',
      trial_id: 'EVGR-A-001',
      status: ShipmentStatus.PENDING,
      expected_delivery: '2024-02-24T10:30:00',
      type: 'inbound',
      created_at: new Date().toISOString(),
      carrier: 'FedEx',
      service_type: 'Express'
    }
  ];

  const mockOutboundShipments = [
    {
      id: '3',
      tracking_number: '788208439647',
      origin: 'SiteID-002',
      destination: 'SiteID-000',
      trial_id: 'EVGR-A-001',
      status: ShipmentStatus.IN_TRANSIT,
      expected_delivery: '2024-01-24T10:30:00',
      sample_ids: ['9948'],
      type: 'outbound',
      created_at: new Date().toISOString(),
      carrier: 'DHL',
      service_type: 'International'
    }
  ];

  const handleToggleStar = (rowId: string) => {
    setStarredShipments(prev => 
      prev.includes(rowId)
        ? prev.filter(id => id !== rowId)
        : [...prev, rowId]
    );
  };

  const handleSiteChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const siteId = e.target.value;
    const site = sites.find(s => s.site_id === siteId);
    setSelectedSite(site || null);
    
    // Update the URL
    if (siteId) {
      navigate(`/sites/${siteId}`);
    } else {
      navigate('/sites');
    }
  };

  return (
    <PageLayout>
      <Container>
        <HeaderContainer>
          <Title>Individual Site Page</Title>
          <ActionButtonsContainer>
            <ActionButton onClick={() => navigate('/send-kits')}>
              Send Out Kits
            </ActionButton>
            <ActionButton onClick={() => navigate('/track-shipments')}>
              Track My Shipments
            </ActionButton>
            <ActionButton onClick={() => navigate('/schedule-pickup')}>
              Schedule Pickups
            </ActionButton>
          </ActionButtonsContainer>
        </HeaderContainer>

        <SiteSelector>
          <SiteSelectorLabel>Select a Site</SiteSelectorLabel>
          <StyledSelect
            value={selectedSite?.site_id || ''}
            onChange={handleSiteChange}
          >
            <option value="">Please select a site</option>
            {sites.map((site) => (
              <option key={site.site_id} value={site.site_id}>
                {site.site_id}
              </option>
            ))}
          </StyledSelect>
        </SiteSelector>

        {selectedSite ? (
          <>
            <ContentGrid>
              <MapSection>
                <WorldMap src={worldmap} alt="World Map" />
              </MapSection>

              <SiteDetailsSection>
                <SiteHeader>
                  <SiteId>Site ID# {selectedSite?.site_id}</SiteId>
                  <ProfileImage />
                </SiteHeader>

                <DetailItem>
                  <Label>Type:</Label>
                  <Value>Patient Home</Value>
                </DetailItem>

                <DetailItem>
                  <Label>Address:</Label>
                  <Value>
                    {selectedSite?.address || 'No address available'}
                  </Value>
                </DetailItem>

                <DetailItem>
                  <Label>Patient Identifier:</Label>
                  <Value>
                    {selectedSite?.patient_identifier || 'No patient identifier available'}
                  </Value>
                </DetailItem>

                <DetailItem>
                  <Label>Email:</Label>
                  <Value>
                    {selectedSite?.email || 'No email available'}
                  </Value>
                </DetailItem>
              </SiteDetailsSection>
            </ContentGrid>

            <InventoryLevelsTable
              data={mockInventoryData}
              onRestock={handleRestock}
              studyFilter={inventoryStudyFilter}
              statusFilter={inventoryStatusFilter}
              timeFilter={inventoryTimeFilter}
              onStudyFilterChange={setInventoryStudyFilter}
              onStatusFilterChange={setInventoryStatusFilter}
              onTimeFilterChange={setInventoryTimeFilter}
            />

            <HistoricalShipmentsTable
              inboundShipments={mockInboundShipments}
              outboundShipments={mockOutboundShipments}
              starredShipments={starredShipments}
              onToggleStar={handleToggleStar}
              studyFilter={shipmentsStudyFilter}
              statusFilter={shipmentsStatusFilter}
              timeFilter={shipmentsTimeFilter}
              onStudyFilterChange={setShipmentsStudyFilter}
              onStatusFilterChange={setShipmentsStatusFilter}
              onTimeFilterChange={setShipmentsTimeFilter}
              onSeeAllClick={() => {}}
              currentSiteId={selectedSite.site_id}
            />
          </>
        ) : (
          <EmptyState>
            {isLoading ? 'Loading sites...' : 'Please select a site to view details'}
          </EmptyState>
        )}
      </Container>
    </PageLayout>
  );
};

// Styled components
const Container = styled.div`
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 500;
  margin: 0;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const ActionButton = styled.button`
  padding: 12px 24px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
  margin-bottom: 48px;
  align-items: stretch;
`;

const MapSection = styled.div`
  height: 300px;
  border-radius: 8px;
  overflow: hidden;
`;

const WorldMap = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SiteDetailsSection = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid #E0E0E0;
`;

const SiteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const SiteId = styled.h2`
  font-size: 24px;
  margin: 0;
`;

const ProfileImage = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #E0E0E0;
`;

const DetailItem = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
`;

const Value = styled.div`
  color: #666;
`;

const SiteSelector = styled.div`
  margin-bottom: 2rem;
`;

const SiteSelectorLabel = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  font-size: 1rem;
  background-color: white;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #000;
  }
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 3rem;
  color: #666;
  font-size: 1.125rem;
  background: #f5f5f5;
  border-radius: 8px;
  margin-top: 2rem;
`;

export default SitePage;
