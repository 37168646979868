import React, { useState, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import PageLayout from '../../components/common/Layout/PageLayout';
import { breakpoints, colors, spacing } from '../../components/constants/breakpoints';
import NextButton from '../../components/common/Button/NextButton';
import ShipmentHeader from '../../components/Shipment/ShipmentHeader';
import { useNavigate } from 'react-router-dom';
import { useAddresses } from '../../hooks/useAddresses';
import { useDebounce } from '../../hooks/useDebounce';
import type { Address } from '../../types/models/address';
import { useKits } from '../../hooks/useKits';
import type { Kit } from '../../types/models/kit';
import { useSendOutKits } from '../../contexts/SendOutKitsContext';
import { useTrials } from '../../hooks/useTrials';
import { useShipments } from '../../hooks/useShipments';
import { useOriginAddress } from '../../api/endpoints/addresses';
import { useShippingSelection } from '../../contexts/ShippingSelectionContext';
import { useCollectionDevices } from '../../hooks/useCollectionDevices';
import type { Device } from '../../types/models/device';
import type { ReturnShipment } from '../../contexts/SendOutKitsContext';
import type { ContactPerson } from '../../types/models/contact-person';
import type { ShipmentDetails } from '../../types/models/shipment-data';
import { useContactPersons, useCreateContactPerson } from '../../api/endpoints/contact-persons';
import type { FormData } from '../../types/models/form-data';
import api from '../../api/client';
import { ENDPOINTS } from '../../api/config';

// Add this interface near other interfaces at the top
interface ReturnSectionProps {
  showReferences?: boolean;
}

const CreateLabels: React.FC = () => {
  const navigate = useNavigate();
  const { 
    selectedTrialId,
    selectedProfile,
    outboundShipment,
    setOutboundShipment,
    returnShipment,
    setReturnShipment,
    collectionDevices,
    selectedShipment,
    updateReturnShipment,
    updateOutboundShipment,
    formData,
    setFormData,
    clearStorage
  } = useSendOutKits();

  // Initialize local form data from context
  const [localFormData, setLocalFormData] = useState<any>(formData || {
    country: '',
    addressLine1: '',
    addressLine2: '',
    zip: '',
    city: '',
    state: '',
    contactName: '',
    company: '',
    phoneNumber: '',
    phoneExtension: '',
    email: ''
  });

  // Add logging for initial state
  useEffect(() => {
    console.log('🏁 CreateLabels Initial State:', {
      outboundShipment,
      returnShipment,
      formData,
      selectedKit,
      collectionDevices
    });
  }, []);

  // Add logging for outboundShipment changes
  useEffect(() => {
    console.log('📦 Outbound Shipment Updated:', {
      outboundShipment,
      devices: outboundShipment.devices,
      packageDetails: outboundShipment.packageDetails
    });
  }, [outboundShipment]);

  // Add logging for returnShipment changes
  useEffect(() => {
    console.log('🔄 Return Shipment Updated:', {
      returnShipment,
      devices: returnShipment?.devices,
      packageDetails: returnShipment?.packageDetails
    });
  }, [returnShipment]);

  // Add logging for form data changes
  useEffect(() => {
    console.log('📝 Form Data Updated:', {
      formData,
      localFormData
    });
  }, [formData, localFormData]);

  // Save to context whenever local form data changes
  useEffect(() => {
    setFormData(localFormData);
  }, [localFormData, setFormData]);

  // Update local form data
  const updateFormData = (updates: Partial<typeof localFormData>) => {
    setLocalFormData((prev: typeof localFormData) => ({
      ...prev,
      ...updates
    }));
  };

  // Add email addresses state
  const [emailAddresses, setEmailAddresses] = useState<string[]>(['']);
  const [isAddressFromBook, setIsAddressFromBook] = useState(false);
  
  // Add status message state
  const [statusMessage, setStatusMessage] = useState<{text: string; type: 'error' | 'success' | 'warning'} | null>(null);

  const [createReturn, setCreateReturn] = useState(false);
  const [returnToOrigin, setReturnToOrigin] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(
    outboundShipment?.address || null
  );
  const [selectedKit, setSelectedKit] = useState<string>('');
  const [returnService, setReturnService] = useState<string>('');
  const [errors, setErrors] = useState<string[]>([]);
  const [addressBookSelection, setAddressBookSelection] = useState<Address | null>(null);
  const [weight, setWeight] = useState<string>('');
  const [dimensions, setDimensions] = useState<{
    length: string;
    width: string;
    height: string;
  }>({
    length: '',
    width: '',
    height: ''
  });
  const [showReferences, setShowReferences] = useState(false);
  const [returnQuantity, setReturnQuantity] = useState<string>('1');
  const [deviceSearchTerm, setDeviceSearchTerm] = useState('');
  const [isDeviceSearchFocused, setIsDeviceSearchFocused] = useState(false);
  const [holdAtLocation, setHoldAtLocation] = useState(false);
  const [emailLabel, setEmailLabel] = useState(false);
  const [showNewAddressModal, setShowNewAddressModal] = useState(false);
  const [showNewContactModal, setShowNewContactModal] = useState(false);
  const [hasNewContact, setHasNewContact] = useState(false);

  // Separate outbound shipping options states
  const [outboundHoldAtLocation, setOutboundHoldAtLocation] = useState(false);
  const [outboundEmailLabel, setOutboundEmailLabel] = useState(false);
  const [outboundShowReferences, setOutboundShowReferences] = useState(false);

  // Separate return shipping options states
  const [returnHoldAtLocation, setReturnHoldAtLocation] = useState(false);
  const [returnEmailLabel, setReturnEmailLabel] = useState(false);
  const [returnShowReferences, setReturnShowReferences] = useState(false);

  const [addNewAddressChecked, setAddNewAddressChecked] = useState(false);

  // Add new states for checkboxes
  const [saveNewAddress, setSaveNewAddress] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [notificationEmail, setNotificationEmail] = useState('');
  const [addressType, setAddressType] = useState<'residential' | 'clinic' | 'hospital' | 'other'>('residential');

  const { trials } = useTrials();
  const { data: shipments } = useShipments();

  const debouncedSearch = useDebounce(searchTerm, 300);
  const debouncedDeviceSearch = useDebounce(deviceSearchTerm, 300);

  const { 
    data: addressesResponse, 
    isLoading: addressesLoading,
    isFetching: addressesFetching 
  } = useAddresses("");  // Fetch all addresses once

  const { data: kits, isLoading: kitsLoading } = useKits() as { 
    data: Kit[] | undefined, 
    isLoading: boolean 
  };

  const { 
    data: originAddressResponse, 
    isLoading: originLoading 
  } = useOriginAddress();

  const originAddress = originAddressResponse as Address;

  const handleContactKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!filteredContacts.length) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setContactHighlightedIndex(prev => 
          prev < filteredContacts.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setContactHighlightedIndex(prev => prev > 0 ? prev - 1 : 0);
        break;
      case 'Enter':
        e.preventDefault();
        if (contactHighlightedIndex >= 0) {
          handleContactSelect(filteredContacts[contactHighlightedIndex]);
        }
        break;
      case 'Escape':
        setContactSearchTerm('');
        setContactHighlightedIndex(-1);
        break;
    }
  };

  const { 
    selectedService, 
    setSelectedService,
    availableServices,
    getServiceDetails 
  } = useShippingSelection();

  const [sameAsOutbound, setSameAsOutbound] = useState(false);

  const {
    data: devicesList,
    isLoading: devicesLoading,
    isFetching: devicesFetching
  } = useCollectionDevices(debouncedDeviceSearch);

  const filteredDevices = useMemo(() => {
    const devices = (devicesList || []) as Device[];
    if (!debouncedDeviceSearch) {
      return devices.slice(0, 5); // Show first 5 when no search
    }
    
    const searchTerm = debouncedDeviceSearch.toLowerCase();
    return devices.filter((device: Device) => 
      device.device_name.toLowerCase().includes(searchTerm)
    );
  }, [devicesList, debouncedDeviceSearch]);

  const [contactSearchTerm, setContactSearchTerm] = useState('');
  const [contactHighlightedIndex, setContactHighlightedIndex] = useState(-1);
  const [isContactSearchFocused, setIsContactSearchFocused] = useState(false);
  const [selectedContact, setSelectedContact] = useState<ContactPerson | null>(null);
  const [isContactDropdownVisible, setIsContactDropdownVisible] = useState(false);
  const debouncedContactSearch = useDebounce(contactSearchTerm, 300);
  
  // Update to use proper hook with search filter
  const { 
    data: contactPersonsResponse, 
    isLoading: contactsLoading,
    isFetching: contactsFetching 
  } = useContactPersons({
    search: debouncedContactSearch
  });

  // Create contact mutation
  const createContactMutation = useCreateContactPerson();

  // Update filtered contacts to handle paginated response
  const filteredContacts = useMemo(() => {
    const contacts = Array.isArray(contactPersonsResponse) ? contactPersonsResponse : [];
    
    if (!debouncedContactSearch) {
      return contacts;
    }
    
    const searchTermLower = debouncedContactSearch.toLowerCase();
    return contacts.filter((contact: ContactPerson) => {
      const nameMatch = contact.name?.toLowerCase()?.includes(searchTermLower) || false;
      const emailMatch = contact.email?.toLowerCase()?.includes(searchTermLower) || false;
      const phoneMatch = contact.phone_number?.toLowerCase()?.includes(searchTermLower) || false;
      const companyMatch = contact.company?.toLowerCase()?.includes(searchTermLower) || false;
      const extensionMatch = contact.phone_extension?.toLowerCase()?.includes(searchTermLower) || false;
      return nameMatch || emailMatch || phoneMatch || companyMatch || extensionMatch;
    });
  }, [contactPersonsResponse, debouncedContactSearch]);

  const handleReturnCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setCreateReturn(isChecked);
    
    console.log('🔄 Creating Return Shipment - Full Debug:', {
      outboundDevices: outboundShipment.devices,
      collectionDevices,
      isChecked,
      formData,  // Added logging
      selectedKit,  // Added logging
      returnShipment  // Added logging
    });
    
    if (isChecked) {
      // Use outbound devices if they exist, otherwise use collection devices
      const devicesToUse = outboundShipment.devices.length > 0 
        ? outboundShipment.devices 
        : collectionDevices;

      console.log('📦 Using devices for return:', devicesToUse);
      
      setReturnShipment({
        service: '',
        address: null,
        deliveryDate: null,
        options: {
          requireSignature: false,
          holdAtLocation: false,
          locationZip: ''
        },
        devices: devicesToUse.map(device => {
          console.log('📦 Mapping return device:', device);  // Added logging
          return {
            id: device.id || String(Math.random()),
            name: device.name,
            quantity: device.quantity
          };
        }),
        packageDetails: {
          weight: weight,
          dimensions: dimensions
        }
      });
    } else {
      setReturnShipment(null);
    }
  };

  const handleEmailNotificationChange = (checked: boolean) => {
    updateFormData({
      emailNotifications: checked,
      notificationEmails: checked ? localFormData.notificationEmails : ['']
    });
    setEmailNotifications(checked);
  };

  const handleEmailAddressChange = (index: number, value: string) => {
    const newEmails = [...localFormData.notificationEmails];
    newEmails[index] = value;
    updateFormData({
      notificationEmails: newEmails
    });
    setEmailAddresses(newEmails);
  };

  const handleNext = async () => {
    try {
      console.log('📦 Navigation State:', {
        outboundDevices: outboundShipment.devices,
        returnDevices: returnShipment?.devices,
        collectionDevices
      });

      const newErrors: string[] = [];

      // Validate outbound shipment
      if (!outboundShipment.service) {
        newErrors.push("Please select an outbound shipping service");
      }
      
      // Validate return shipment if enabled
      if (createReturn) {
        if (!returnShipment?.service) {
          newErrors.push("Please select a return shipping service");
        }
        if (!returnShipment?.address) {
          newErrors.push("Please select a return address");
        }
      }

      setErrors(newErrors);

      if (newErrors.length === 0) {
        navigate('/send-kits/confirmation');
      }
    } catch (error) {
      console.error('Error:', error);
      // ... error handling ...
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!filteredAddresses.length) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setHighlightedIndex(prev => 
          prev < filteredAddresses.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex(prev => prev > 0 ? prev - 1 : 0);
        break;
      case 'Enter':
        e.preventDefault();
        if (highlightedIndex >= 0) {
          handleAddressSelect(filteredAddresses[highlightedIndex]);
        }
        break;
      case 'Escape':
        setSearchTerm('');
        setHighlightedIndex(-1);
        break;
    }
  };

  const handleAddressSelect = (address: Address) => {
    setSelectedAddress(address);
    setSearchTerm('');
    setHighlightedIndex(-1);
    // Don't immediately close the dropdown
    setTimeout(() => setIsSearchFocused(false), 100);
    
    updateFormData({
      ...localFormData,
      country: address.country || '',
      addressLine1: address.street || '',
      city: address.city || '',
      state: address.state || '',
      zip: address.postal_code || ''
    });

    if (returnShipment) {
      updateReturnShipment({
        ...returnShipment,
        address: address
      });
    }
  };

  const filteredAddresses = useMemo(() => {
    const addresses = addressesResponse || [];
    
    if (!debouncedSearch) {
      return addresses.slice(0, 5);
    }
    
    const searchTermLower = debouncedSearch.toLowerCase();
    return addresses.filter((address: Address) => {
      const streetMatch = address.street?.toLowerCase().includes(searchTermLower);
      const cityMatch = address.city?.toLowerCase().includes(searchTermLower);
      const stateMatch = address.state?.toLowerCase().includes(searchTermLower);
      const postalMatch = address.postal_code?.toLowerCase().includes(searchTermLower);
      
      return streetMatch || cityMatch || stateMatch || postalMatch;
    });
  }, [addressesResponse, debouncedSearch]);

  const handleReturnToOriginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setReturnToOrigin(isChecked);
    
    if (isChecked && originAddress) {
      // Update the selected address display
      setSelectedAddress(originAddress);
      
      // Fill in the form data with origin address
      updateFormData({
        ...localFormData,
        country: originAddress.country || '',
        addressLine1: originAddress.street || '',
        city: originAddress.city || '',
        state: originAddress.state || '',
        zip: originAddress.postal_code || ''
      });

      // Update return shipment if it exists
      if (returnShipment) {
        updateReturnShipment({
          ...returnShipment,
          address: originAddress
        });
      }
    } else {
      // Clear form data when unchecking
      setSelectedAddress(null);
      updateFormData({
        ...localFormData,
        country: '',
        addressLine1: '',
        city: '',
        state: '',
        zip: ''
      });

      // Clear address from return shipment if it exists
      if (returnShipment) {
        updateReturnShipment({
          ...returnShipment,
          address: null
        });
      }
    }
  };

  const handleReturnServiceSelect = (serviceName: string) => {
    console.log('🔄 Setting Return Service:', {
      collectionDevices,
      outboundDevices: outboundShipment.devices,
      currentReturnDevices: returnShipment?.devices,
      sameAsOutbound,
      serviceName
    });

    setReturnService(serviceName);
    if (returnShipment) {
      // Determine which devices to use
      const devicesToUse = sameAsOutbound 
        ? outboundShipment.devices 
        : returnShipment.devices.length > 0
          ? returnShipment.devices  // Keep existing return devices
          : outboundShipment.devices.length > 0
            ? outboundShipment.devices  // Use outbound devices as fallback
            : collectionDevices;  // Use collection devices as last resort

      // Calculate delivery date as 1 week after outbound pickup
      const deliveryDate = outboundShipment.pickupDate 
        ? new Date(outboundShipment.pickupDate.getTime() + (7 * 24 * 60 * 60 * 1000))
        : null;

      console.log('📦 Using devices for return service:', devicesToUse);
      console.log('📅 Setting return delivery date:', deliveryDate);

      setReturnShipment({
        ...returnShipment,
        service: serviceName,
        deliveryDate: deliveryDate,
        devices: devicesToUse.map(device => ({
          id: device.id || String(Math.random()),
          name: device.name,
          quantity: device.quantity
        })),
        packageDetails: sameAsOutbound ? {
          sameAsOutbound: true
        } : {
          ...returnShipment.packageDetails,
          weight: weight,
          dimensions: {
            length: dimensions.length,
            width: dimensions.width,
            height: dimensions.height
          }
        }
      });
    }
  };

  const handleServiceSelect = (serviceName: string) => {
    console.log('🚀 Setting Outbound Service - Detailed Debug:', {
      serviceName,
      collectionDevices: collectionDevices.map(d => ({
        id: d.id,
        name: d.name,
        quantity: d.quantity
      })),
      existingDevices: outboundShipment.devices.map(d => ({
        id: d.id,
        name: d.name,
        quantity: d.quantity
      }))
    });

    setSelectedService(serviceName);
    const updatedDevices = collectionDevices.length > 0 
      ? collectionDevices.map(device => {
          console.log('📦 Mapping collection device:', {
            id: device.id,
            name: device.name,
            quantity: device.quantity,
            originalDevice: device
          });
          return {
            id: device.id || String(Math.random()),
            name: device.name,
            quantity: device.quantity
          };
        })
      : outboundShipment.devices;
    
    console.log('📦 Final devices being set (detailed):', updatedDevices.map(d => ({
      id: d.id,
      name: d.name,
      quantity: d.quantity
    })));

    setOutboundShipment({
      ...outboundShipment,
      service: serviceName,
      pickupDate: new Date(),
      devices: updatedDevices,
      packageDetails: {
        weight,
        dimensions,
        kitName: selectedKit
      }
    });
  };

  const handleWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Only allow numbers and decimal point
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setWeight(value);
      
      // Update return shipment with new weight
      if (returnShipment && !sameAsOutbound) {
        setReturnShipment({
          ...returnShipment,
          packageDetails: {
            ...returnShipment.packageDetails,
            weight: value,
            dimensions: dimensions
          }
        });
      }
    }
  };

  const handleDimensionChange = (dimension: 'length' | 'width' | 'height', value: string) => {
    setDimensions(prev => {
      const updated = { ...prev, [dimension]: value };
      
      // Update return shipment with new dimensions
      if (returnShipment && !sameAsOutbound) {
        setReturnShipment({
          ...returnShipment,
          packageDetails: {
            ...returnShipment.packageDetails,
            weight: weight,
            dimensions: updated
          }
        });
      }
      
      return updated;
    });
  };

  const handleSameAsOutboundChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setSameAsOutbound(isChecked);
    
    console.log('🔄 Same as Outbound Change - Full Debug:', {
      isChecked,
      outboundShipment,
      outboundDevices: outboundShipment.devices,
      outboundPackageDetails: outboundShipment.packageDetails,
      outboundKit: outboundShipment.packageDetails?.kitName,
      currentSelectedKit: selectedKit,
      outboundContactInfo: outboundShipment.contactInfo
    });
    
    if (isChecked) {
      // Use outbound package details if they exist, otherwise use defaults
      const packageDetails = outboundShipment.packageDetails || {
        weight: '4.0',
        dimensions: {
          length: '1',
          width: '2',
          height: '3'
        }
      };

      // Update dimensions and weight state with actual values, ensuring they're not undefined
      setDimensions(packageDetails.dimensions || {
        length: '',
        width: '',
        height: ''
      });
      setWeight(packageDetails.weight || '');
      
      // Get the kit information from outbound shipment or devices
      const outboundKit = outboundShipment.devices[0]?.name || outboundShipment.packageDetails?.kitName || '';
      console.log('📦 Outbound Kit Info:', {
        outboundKit,
        devices: outboundShipment.devices,
        packageDetails: outboundShipment.packageDetails,
        fullOutboundShipment: outboundShipment
      });

      if (outboundKit) {
        console.log('✅ Setting kit from outbound:', outboundKit);
        setSelectedKit(outboundKit);
      } else {
        console.log('⚠️ No outbound kit found');
      }
      
      // Get the quantity from outbound devices
      const outboundQuantity = outboundShipment.devices[0]?.quantity || 1;
      console.log('📊 Setting quantity:', outboundQuantity);
      setReturnQuantity(String(outboundQuantity));
      
      // Update return shipment state with outbound devices, kit, and package details
      setReturnShipment({
        ...returnShipment,
        devices: outboundShipment.devices.map(device => ({
          id: device.id,
          name: device.name,
          quantity: device.quantity
        })),
        options: {
          ...outboundShipment.options,
          requireSignature: outboundShipment.options.requireSignature,
          holdAtLocation: outboundShipment.options.holdAtLocation,
          locationZip: outboundShipment.options.locationZip
        },
        contactInfo: outboundShipment.contactInfo ? {
          ...outboundShipment.contactInfo
        } : undefined,
        deliveryDate: outboundShipment.pickupDate 
          ? new Date(outboundShipment.pickupDate.getTime() + (7 * 24 * 60 * 60 * 1000))
          : null,
        packageDetails: {
          ...packageDetails,
          sameAsOutbound: true,
          kitName: outboundKit
        }
      });
    } else {
      // Reset package details when unchecking
      setDimensions({
        length: '',
        width: '',
        height: ''
      });
      setWeight('');
      setSelectedKit(''); // Clear the selected kit
      setReturnQuantity('1'); // Reset quantity to default
      
      // Reset return shipment package details
      setReturnShipment({
        ...returnShipment,
        packageDetails: {
          weight: '',
          dimensions: {
            length: '',
            width: '',
            height: ''
          },
          sameAsOutbound: false,
          kitName: ''
        },
        contactInfo: undefined // Clear contact info when unchecking
      });
    }
  };

  // Add this function to get kit options including outbound kit if needed
  const getKitOptions = () => {
    const kitOptions = kits || [];
    const outboundKitName = outboundShipment.packageDetails?.kitName;
    
    if (outboundKitName && !kitOptions.some(kit => kit.kit_name === outboundKitName)) {
      // Add the outbound kit to options if it's not already there
      return [...kitOptions, { kit_name: outboundKitName }];
    }
    
    return kitOptions;
  };

  const handleSignatureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    console.log('Setting outbound signature requirement:', isChecked);
    
    // Update only outbound shipment signature requirement
    setOutboundShipment({
      ...outboundShipment,
      options: {
        ...outboundShipment.options,
        requireSignature: isChecked,
        holdAtLocation: outboundShipment.options?.holdAtLocation || false,
        locationZip: outboundShipment.options?.locationZip || ''
      }
    });
  };

  const handleReturnSignatureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    console.log('Setting return signature requirement:', isChecked);
    
    if (returnShipment) {
      setReturnShipment({
        ...returnShipment,
        options: {
          ...returnShipment.options,
          requireSignature: isChecked,
          holdAtLocation: returnShipment.options?.holdAtLocation || false,
          locationZip: returnShipment.options?.locationZip || ''
        }
      });
    }
  };

  // Keep this effect for monitoring device changes
  useEffect(() => {
    console.log('🔍 Devices Updated:', {
      collectionDevices,
      outboundDevices: outboundShipment.devices,
      returnDevices: returnShipment?.devices
    });
  }, [collectionDevices, outboundShipment.devices, returnShipment?.devices]);

  // Add this near the other useEffect hooks
  useEffect(() => {
    console.log('📦 Package Details State Changed:', {
      outboundPackageDetails: outboundShipment.packageDetails,
      returnPackageDetails: returnShipment?.packageDetails,
      formState: {
        selectedKit,
        dimensions,
        weight,
        returnQuantity
      }
    });
  }, [outboundShipment.packageDetails, returnShipment?.packageDetails, selectedKit, dimensions, weight, returnQuantity]);


  const handleAddressSubmit = async () => {
    try {
      const uniqueLabel = `Return Address - ${localFormData.addressLine1} ${Date.now()}`;
      
      const response = await api.post(ENDPOINTS.addresses.base, {
        street: localFormData.addressLine1,
        street2: localFormData.addressLine2,
        city: localFormData.city,
        state: localFormData.state,
        postal_code: localFormData.zip,
        country: localFormData.country,
        label: uniqueLabel
      });

      if (response.status === 200) {
        const newAddress = response.data;
        handleAddressSelect(newAddress);
        // Only close the modal, keep the checkbox checked
        setShowNewAddressModal(false);
        updateFormData({
          ...localFormData,
          country: '',
          addressLine1: '',
          addressLine2: '',
          zip: '',
          city: '',
          state: '',
        });
      } else {
        const errorData = response.data;
        console.error('Error saving address:', errorData);
        // On error, uncheck everything
        setShowNewAddressModal(false);
        setAddNewAddressChecked(false);
      }
    } catch (error) {
      console.error('Error submitting address:', error);
      // On error, uncheck everything
      setShowNewAddressModal(false);
      setAddNewAddressChecked(false);
    }
  };

  const handleContactSubmit = async () => {
    try {
      console.log('🔄 Starting Contact Submit:', {
        formData: localFormData,
        currentReturnShipment: returnShipment,
        hasNewContact
      });

      const result = await createContactMutation.mutateAsync({
        name: localFormData.contactName,
        company: localFormData.company,
        phone_number: localFormData.phoneNumber,
        phone_extension: localFormData.phoneExtension || '+1',
        email: localFormData.email,
        role: 'Return Contact'
      });

      if (result?.data) {
        const newContact = result.data;
        console.log('✅ Contact Created:', newContact);

        // Create contact info from the new contact
        const contactInfo = {
          name: newContact.name || '',
          email: newContact.email || '',
          phoneNumber: newContact.phone_number || '',
          phoneExtension: newContact.phone_extension || '',
          company: newContact.company || ''
        };
        
        console.log('📝 Setting Contact Info:', contactInfo);

        // If no return shipment exists, create a new one
        if (!returnShipment) {
          console.log('🆕 Creating new return shipment with contact');
          setReturnShipment({
            service: '',
            address: null,
            deliveryDate: null,
            options: {
              requireSignature: false,
              holdAtLocation: false
            },
            devices: [],
            contactInfo
          });
        } else {
          console.log('📝 Updating existing return shipment with contact');
          // Update existing return shipment
          setReturnShipment({
            ...returnShipment,
            contactInfo
          });
        }

        // Keep track that we have a new contact
        console.log('✅ Setting hasNewContact to true');
        setHasNewContact(true);
        // Close modal but keep the checkbox checked
        setShowNewContactModal(false);
        // Reset form data
        updateFormData({
          ...localFormData,
          contactName: '',
          company: '',
          phoneNumber: '',
          phoneExtension: '',
          email: '',
        });
      } else {
        console.error('❌ No contact data in response');
      }
    } catch (error) {
      console.error('❌ Error saving contact:', error);
    }
  };

  const handleReturnContactUpdate = (field: string, value: string) => {
    if (returnShipment) {
      console.log('🔄 Updating return contact:', {
        field,
        value,
        currentContactInfo: returnShipment.contactInfo
      });
      
      // Initialize with required fields if no contact info exists
      const updatedContactInfo = returnShipment.contactInfo || {
        name: '',
        email: '',
        phoneNumber: '',
        phoneExtension: '',
        company: ''
      };
      
      const newContactInfo = {
        ...updatedContactInfo,
        [field]: value
      };
      
      console.log('📝 New contact info:', newContactInfo);
      
      updateReturnShipment({
        ...returnShipment,
        contactInfo: newContactInfo
      });
    }
  };

  const handleContactSelect = (contact: ContactPerson) => {
    console.log('🎯 Contact selected:', contact);
    
    // Update form data with all contact information
    updateFormData({
      ...localFormData,
      contactName: contact.name || '',
      phoneNumber: contact.phone_number || '',
      phoneExtension: contact.phone_extension || '+1',
      email: contact.email || '',
      company: contact.company || ''
    });

    // Update return shipment context with contact information
    if (returnShipment) {
      setReturnShipment({
        ...returnShipment,
        contactInfo: {
          name: contact.name || '',
          phoneNumber: contact.phone_number || '',
          phoneExtension: contact.phone_extension || '+1',
          email: contact.email || '',
          company: contact.company || ''
        }
      });
    }

    // Update UI state
    setSelectedContact(contact);
    setContactSearchTerm(contact.name || '');
    setIsContactDropdownVisible(false);
  };


  const SectionHeader = styled.h3`
    font-size: 1rem;
    margin-bottom: ${spacing.xs};
    color: ${colors.text.primary};
  `;



  // Add new state for test dropdown
  const [testContactSearchTerm, setTestContactSearchTerm] = useState('');
  const [testContactHighlightedIndex, setTestContactHighlightedIndex] = useState(-1);

  // Add new state for test dropdown visibility
  const [isTestDropdownVisible, setIsTestDropdownVisible] = useState(false);

  // Add this new component near the other styled components
  const ContactSearchContainer = styled.div`
    width: 100%;
    position: relative;
  `;

  const ContactSearchInput = styled.input`
    width: 100%;
    padding: 12px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    font-size: 16px;
    background-color: white;

    &:focus {
      outline: none;
      border-color: #000;
    }
  `;

  const ContactDropdown = styled.div`
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    background: white;
    border: 1px solid ${colors.border};
    border-radius: 4px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 9999;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-top: 0;
    scrollbar-width: thin;
    scrollbar-color: #999 #f5f5f5;
    
    &::-webkit-scrollbar {
      width: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background: #f5f5f5;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: #999;
      border-radius: 4px;
      border: 2px solid #f5f5f5;
    }
  `;

  const ContactOption = styled.div<{ isHighlighted?: boolean }>`
    padding: 12px;
    cursor: pointer;
    background: ${props => props.isHighlighted ? '#f5f5f5' : 'white'};

    &:hover {
      background: #f5f5f5;
    }

    div:first-of-type {
      font-weight: 500;
      margin-bottom: 4px;
    }

    div:last-of-type {
      font-size: 14px;
      color: #666;
    }
  `;

  return (
    <PageLayout title="Send Out Kits">
      <Container>
        <ShipmentHeader 
          selectedTrialId={selectedTrialId}
          selectedShipmentId={selectedShipment}
          onTrialSelect={() => {}}
          onShipmentSelect={() => {}}
          trials={trials || []}
          shipments={shipments?.outboundData || []}
          readOnly={true}
        />

        {outboundShipment.address && (
          <SelectedAddressSection>
            <SectionTitle>Delivery Address</SectionTitle>
            <SelectedAddress>
              <p>{outboundShipment.address.street}</p>
              <p>{`${outboundShipment.address.city}, ${outboundShipment.address.state} ${outboundShipment.address.postal_code}`}</p>
            </SelectedAddress>
          </SelectedAddressSection>
        )}

        {collectionDevices.length > 0 && (
          <DevicesSection>
            <SectionTitle>Collection Devices</SectionTitle>
            {collectionDevices.map((device, index) => (
              <DeviceItem key={index}>
                {device.name} (Qty: {device.quantity})
              </DeviceItem>
            ))}
          </DevicesSection>
        )}

        <ServiceSection>
          <SectionTitle>Select Outbound Service</SectionTitle>
          <ServiceHeader>
            <DropoffSelect>
              <option>Find A Dropoff</option>
            </DropoffSelect>
            <LocationInput placeholder="Find FedEx Nearby" />
            <TimeInput placeholder="Earliest Pickup Time" />
            <TimeInput placeholder="Latest Pickup Time" />
          </ServiceHeader>

          <ServiceOptions>
            {availableServices.map((service) => (
              <ServiceCard
                key={service.name}
                isSelected={selectedService === service.name}
                onClick={() => handleServiceSelect(service.name)}
              >
                <ServiceName>{service.name}</ServiceName>
                <ServiceDetails>
                  <DeliveryTime>{service.deliveryTime}</DeliveryTime>
                  <Price>{service.price}</Price>
                </ServiceDetails>
              </ServiceCard>
            ))}
          </ServiceOptions>

          <ShippingOptions>
            <CheckboxRow>
              <CheckboxOption>
                <Checkbox 
                  type="checkbox"
                  id="requireSignature"
                  checked={outboundShipment.options?.requireSignature || false}
                  onChange={handleSignatureChange}
                />
                <CheckboxLabel htmlFor="requireSignature">Require Signature</CheckboxLabel>
              </CheckboxOption>
              <CheckboxOption>
                <Checkbox 
                  type="checkbox"
                  id="outboundHoldAtLocation"
                  checked={outboundHoldAtLocation}
                  onChange={(e) => setOutboundHoldAtLocation(e.target.checked)}
                />
                <CheckboxLabel htmlFor="outboundHoldAtLocation">Hold at Local FedEx Location</CheckboxLabel>
                {outboundHoldAtLocation && (
                  <BaseInputAbsolute 
                    placeholder="Enter ZIP to Select Location" 
                    style={{ width: '14rem' }}
                  />
                )}
              </CheckboxOption>
              <CheckboxOption>
                <Checkbox 
                  type="checkbox"
                  id="outboundEmailLabel"
                  checked={outboundEmailLabel}
                  onChange={(e) => setOutboundEmailLabel(e.target.checked)}
                />
                <CheckboxLabel htmlFor="outboundEmailLabel">Email Outbound Shipping Label</CheckboxLabel>
                {outboundEmailLabel && (
                  <BaseInputAbsolute 
                    type="email"
                    placeholder="Email" 
                    style={{ width: '14rem' }}
                  />
                )}
              </CheckboxOption>
              <CheckboxOption>
                <Checkbox 
                  type="checkbox"
                  id="outboundReferences"
                  checked={outboundShowReferences}
                  onChange={(e) => setOutboundShowReferences(e.target.checked)}
                />
                <CheckboxLabel htmlFor="outboundReferences">Add Shipment References</CheckboxLabel>
                {outboundShowReferences && (
                  <div style={{ position: 'absolute', top: '100%', left: '2rem', marginTop: '0.5rem', display: 'flex', flexDirection: 'column', gap: '0.5rem', zIndex: 1 }}>
                    <BaseInput placeholder="Shipment ID" style={{ width: '15rem' }} />
                    <BaseInput placeholder="P.O. No." style={{ width: '15rem' }} />
                    <BaseInput placeholder="Invoice No." style={{ width: '15rem' }} />
                    <BaseInput placeholder="Department No." style={{ width: '15rem' }} />
                  </div>
                )}
              </CheckboxOption>
            </CheckboxRow>
            <CheckboxRow style={{ marginTop: '0.5rem' }}>
              <CheckboxOption style={{ marginLeft: '0' }}>
                <Checkbox 
                  type="checkbox"
                  id="return"
                  checked={createReturn}
                  onChange={handleReturnCheckbox}
                />
                <CheckboxLabel htmlFor="return">Create a Return Label</CheckboxLabel>
              </CheckboxOption>
            </CheckboxRow>
          </ShippingOptions>
        </ServiceSection>

        {createReturn && (
          <ReturnSection showReferences={returnShowReferences}>
            <SectionTitle>
              Create Corresponding Return Label(s)
            </SectionTitle>
              <Section>
                <ReturnCheckboxRow>
                  <CheckboxOption>
                    <Checkbox 
                      type="checkbox"
                      id="returnToOrigin"
                      onChange={handleReturnToOriginChange}
                      checked={returnToOrigin}
                      disabled={originLoading || !originAddress}
                    />
                    <CheckboxLabel htmlFor="returnToOrigin">
                      {originLoading 
                        ? 'Loading origin address...' 
                        : originAddress 
                          ? 'Return to Origin Address' 
                          : 'No origin address available'
                      }
                    </CheckboxLabel>
                  </CheckboxOption>
                </ReturnCheckboxRow>

                <StyledSubtitle>Deliver To</StyledSubtitle>
                <SearchBar>
                  <SearchInput 
                    type="text" 
                    placeholder={addressesLoading ? "Loading addresses..." : "Search Address Book"}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onFocus={() => setIsSearchFocused(true)}
                    onBlur={(e) => {
                      setTimeout(() => setIsSearchFocused(false), 200);
                    }}
                    disabled={addressesLoading}
                  />
                  {isSearchFocused && (debouncedSearch || !addressesLoading) && (
                    <AddressDropdown>
                      {addressesFetching ? (
                        <LoadingAddressOption>
                          <LoadingText>Searching addresses...</LoadingText>
                        </LoadingAddressOption>
                      ) : filteredAddresses.length > 0 ? (
                        filteredAddresses.map((address: Address, index: number) => (
                          <AddressOption 
                            key={address.id}
                            onClick={() => handleAddressSelect(address)}
                            isHighlighted={index === highlightedIndex}
                          >
                            <div>{address.street}</div>
                            <div>{address.city}, {address.state}</div>
                          </AddressOption>
                        ))
                      ) : (
                        <LoadingAddressOption>
                          <LoadingText>No addresses found</LoadingText>
                        </LoadingAddressOption>
                      )}
                    </AddressDropdown>
                  )}
                </SearchBar>

                {selectedAddress && (
                  <SelectedAddress>
                    <p>{selectedAddress.street}</p>
                    <p>{`${selectedAddress.city}, ${selectedAddress.state} ${selectedAddress.postal_code}`}</p>
                    <p>{selectedAddress.country}</p>
                  </SelectedAddress>
                )}

                <DeliveryForm>
                  <FormColumns>
                  <Section>
                <SectionHeader>Search Contacts</SectionHeader>
                
                {/* Contact Search Dropdown */}
                <FormGroup style={{ position: 'relative', zIndex: 100 }}>
                  <SearchBar style={{ position: 'relative' }}>
                    <SearchInput 
                      type="text" 
                      placeholder={contactsLoading ? "Loading contacts..." : "Search contacts by name, email, or phone"}
                      value={contactSearchTerm}
                      onChange={(e) => setContactSearchTerm(e.target.value)}
                      onFocus={() => setIsContactDropdownVisible(true)}
                      onBlur={() => {
                        setTimeout(() => {
                          setIsContactDropdownVisible(false);
                        }, 200);
                      }}
                    />
                    {isContactDropdownVisible && (
                      <AddressDropdown 
                        onMouseDown={(e) => e.preventDefault()}
                        style={{ 
                          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                          border: '1px solid #E0E0E0',
                          zIndex: 1000
                        }}
                      >
                        {contactsFetching ? (
                          <LoadingAddressOption>
                            <LoadingText>Searching contacts...</LoadingText>
                          </LoadingAddressOption>
                        ) : filteredContacts.length > 0 ? (
                          filteredContacts.map((contact: ContactPerson, index: number) => (
                            <AddressOption 
                              key={contact.id}
                              onMouseDown={(e) => {
                                e.preventDefault();
                                handleContactSelect(contact);
                                setContactSearchTerm(contact.name || '');
                                setIsContactDropdownVisible(false);
                              }}
                              isHighlighted={index === contactHighlightedIndex}
                            >
                              <div style={{ fontWeight: 500 }}>{contact.name}</div>
                              {contact.company && (
                                <div style={{ fontSize: '14px', color: '#666' }}>{contact.company}</div>
                              )}
                              <div style={{ fontSize: '14px', color: '#666' }}>
                                {contact.phone_extension} {contact.phone_number}
                              </div>
                              {contact.email && (
                                <div style={{ fontSize: '14px', color: '#666' }}>{contact.email}</div>
                              )}
                            </AddressOption>
                          ))
                        ) : (
                          <LoadingAddressOption>
                            <LoadingText>
                              {debouncedContactSearch ? 'No contacts found' : 'Start typing to search contacts'}
                            </LoadingText>
                          </LoadingAddressOption>
                        )}
                      </AddressDropdown>
                    )}
                  </SearchBar>
                </FormGroup>

                {/* Contact Form Fields */}
                <div style={{ marginTop: '24px' }}>
                  <FormGroup>
                    <Input 
                      type="text" 
                      placeholder="Company"
                      value={localFormData.company}
                      onChange={(e) => updateFormData({ company: e.target.value })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input 
                      type="tel" 
                      placeholder="Phone Number"
                      value={localFormData.phoneNumber}
                      onChange={(e) => updateFormData({ phoneNumber: e.target.value })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input 
                      type="text" 
                      placeholder="Phone Extension" 
                      value={localFormData.phoneExtension}
                      onChange={(e) => updateFormData({ phoneExtension: e.target.value })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input 
                      type="email" 
                      placeholder="Email"
                      value={localFormData.email}
                      onChange={(e) => updateFormData({ email: e.target.value })}
                    />
                  </FormGroup>
                </div>

                
              </Section>
                  {/* <ContactColumn>
                    <SectionHeader>Contact Details</SectionHeader>
                    <FormGroup>
                      <SearchBar>
                        <SearchInput 
                          type="text" 
                          placeholder="Contact Name"
                          value={contactSearchTerm}
                          onChange={(e) => setContactSearchTerm(e.target.value)}
                          onKeyDown={handleContactKeyDown}
                          onFocus={() => {
                            //console.log('🔍 Contact search focused');
                            setIsContactSearchFocused(true);
                          }}
                          onBlur={() => {
                            setTimeout(() => {
                              setIsContactSearchFocused(false);
                            }, 200);
                          }}
                        />
                        {isContactSearchFocused && (
                          <AddressDropdown>
                            {contactsFetching ? (
                              <LoadingAddressOption>
                                <LoadingText>Searching contacts...</LoadingText>
                              </LoadingAddressOption>
                            ) : filteredContacts.length > 0 ? (
                              filteredContacts.map((contact: ContactPerson, index: number) => (
                                <AddressOption 
                                  key={contact.id}
                                  onClick={() => {
                                    handleContactSelect(contact);
                                  }}
                                  isHighlighted={index === contactHighlightedIndex}
                                >
                                  <div>{contact.name}</div>
                                  <div>{contact.phone_extension} {contact.phone_number}</div>
                                </AddressOption>
                              ))
                            ) : (
                              <LoadingAddressOption>
                                <LoadingText>
                                  {debouncedContactSearch ? 'No contacts found' : 'Start typing to search contacts'}
                                </LoadingText>
                              </LoadingAddressOption>
                            )}
                          </AddressDropdown>
                        )}
                      </SearchBar>
                    </FormGroup>
                    <FormGroup>
                      <Input 
                        type="text" 
                        placeholder="Company"
                        value={formData.company}
                        onChange={(e) => updateFormData({ company: e.target.value })}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input type="text" placeholder="Tax ID/EORI Number" />
                    </FormGroup>
                    <FormGroup>
                      <Input 
                        type="tel" 
                        placeholder="Phone Number"
                        value={formData.phoneNumber}
                        onChange={(e) => updateFormData({ phoneNumber: e.target.value })}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input 
                        type="text" 
                        placeholder="Phone Extension" 
                        value={formData.phoneExtension}
                        onChange={(e) => updateFormData({ phoneExtension: e.target.value })}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input 
                        type="email" 
                        placeholder="Email"
                        value={formData.email}
                        onChange={(e) => updateFormData({ email: e.target.value })}
                      />
                    </FormGroup>
                  </ContactColumn> */}

                  <AddressColumn>
                    <SectionHeader>Address</SectionHeader>
                    <FormGroup>
                      <Input 
                        type="text" 
                        placeholder="Country/Territory" 
                        value={formData.country}
                        onChange={(e) => updateFormData({ country: e.target.value })}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input 
                        type="text" 
                        placeholder="Address Line 1" 
                        value={formData.addressLine1}
                        onChange={(e) => updateFormData({ addressLine1: e.target.value })}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input type="text" placeholder="Address Line 2" />
                    </FormGroup>
                    <ZipCityRow>
                      <FormGroup>
                        <Input 
                          type="text" 
                          placeholder="Zip" 
                          value={formData.zip}
                          onChange={(e) => updateFormData({ zip: e.target.value })}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input 
                          type="text" 
                          placeholder="City" 
                          value={formData.city}
                          onChange={(e) => updateFormData({ city: e.target.value })}
                        />
                      </FormGroup>
                    </ZipCityRow>
                    <FormGroup>
                      <Input 
                        type="text" 
                        placeholder="State/District" 
                        value={formData.state}
                        onChange={(e) => updateFormData({ state: e.target.value })}
                      />
                    </FormGroup>
                    <AddressTypeGroup>
                      <CheckboxContainer>
                        <CheckboxGroup>
                          <StyledCheckbox type="checkbox" id="residential" />
                          <CheckboxLabel htmlFor="residential">Residential</CheckboxLabel>
                        </CheckboxGroup>
                        
                        <CheckboxGroup>
                          <StyledCheckbox type="checkbox" id="clinic" />
                          <CheckboxLabel htmlFor="clinic">Clinic/Hospital</CheckboxLabel>
                        </CheckboxGroup>
                      </CheckboxContainer>

                      <CheckboxGroup>
                        <StyledCheckbox type="checkbox" id="other" />
                        <CheckboxLabel htmlFor="other">Other</CheckboxLabel>
                        <DescriptionInput type="text" placeholder="Description" />
                      </CheckboxGroup>
                    </AddressTypeGroup>
                  </AddressColumn>
                </FormColumns>

                <OptionsRow>
                  <OptionGroup>
                    <CheckboxContainer>
                      <StyledCheckbox 
                        type="checkbox" 
                        id="saveAddress"
                        checked={saveNewAddress}
                        onChange={(e) => {
                          setSaveNewAddress(e.target.checked);
                          if (e.target.checked) {
                            // Check for missing fields when checkbox is checked
                            const missingFields = [];
                            if (!formData.addressLine1) missingFields.push('Address Line 1');
                            if (!formData.city) missingFields.push('City');
                            if (!formData.state) missingFields.push('State');
                            if (!formData.zip) missingFields.push('ZIP Code');
                            if (!formData.country) missingFields.push('Country');
                            
                            if (missingFields.length > 0) {
                              setStatusMessage({
                                text: `Please add: ${missingFields.join(', ')}`,
                                type: 'warning'
                              });
                            }
                          }
                        }}
                        disabled={isAddressFromBook}
                      />
                      <Label htmlFor="saveAddress">
                        Save New Address in Recipient Books
                        {/* {isAddressFromBook && (
                          <small style={{ color: 'gray' }}> (Not available for addresses from address book)</small>
                        )} */}
                      </Label>
                    </CheckboxContainer>
                  </OptionGroup>

                  <OptionGroup>
                    <CheckboxContainer>
                      <StyledCheckbox 
                        type="checkbox" 
                        id="emailNotif"
                        checked={emailNotifications}
                        onChange={(e) => handleEmailNotificationChange(e.target.checked)}
                      />
                      <Label htmlFor="emailNotif">Email Notifications</Label>
                    </CheckboxContainer>
                    {emailNotifications && emailAddresses.map((email, index) => (
                      <InputContainer key={index}>
                        <StyledInput 
                          type="email" 
                          placeholder="Email Address"
                          value={email}
                          onChange={(e) => handleEmailAddressChange(index, e.target.value)}
                        />
                        <EmailButtonContainer>
                          {emailAddresses.length > 1 && (
                            <SimpleButton 
                              onClick={() => {
                                const newEmails = emailAddresses.filter((_, i) => i !== index);
                                setEmailAddresses(newEmails);
                                updateFormData({ notificationEmails: newEmails });
                              }}
                            >
                              ×
                            </SimpleButton>
                          )}
                          {index === emailAddresses.length - 1 && (
                            <SimpleButton 
                              onClick={() => {
                                const newEmails = [...emailAddresses, ''];
                                setEmailAddresses(newEmails);
                                updateFormData({ notificationEmails: newEmails });
                              }}
                            >
                              +
                            </SimpleButton>
                          )}
                        </EmailButtonContainer>
                      </InputContainer>
                    ))}
                  </OptionGroup>
                </OptionsRow>
              </DeliveryForm>
            </Section>

            <ReturnDivider />

            <ReturnProfileSection>
              <SectionTitle>Return Shipment(s) Profile</SectionTitle>

              <ProfileDetailsGrid>
                <div>
                  <FormGroup>
                    <FormLabel>Saved Kit Template</FormLabel>
                    <SearchBar>
                      <SearchInput
                        type="text"
                        placeholder="Search Devices"
                        value={selectedKit}
                        onChange={(e) => {
                          setDeviceSearchTerm(e.target.value);
                          setSelectedKit(e.target.value);
                        }}
                        onFocus={() => setIsDeviceSearchFocused(true)}
                        onBlur={() => {
                          setTimeout(() => setIsDeviceSearchFocused(false), 200);
                        }}
                        disabled={sameAsOutbound}
                      />
                      {isDeviceSearchFocused && !sameAsOutbound && (
                        <AddressDropdown className="address-dropdown">
                          {devicesFetching ? (
                            <LoadingAddressOption>
                              <LoadingText>Searching devices...</LoadingText>
                            </LoadingAddressOption>
                          ) : filteredDevices.length > 0 ? (
                            filteredDevices.map((device: Device) => (
                              <AddressOption
                                key={device.id}
                                onClick={() => {
                                  setSelectedKit(device.device_name);
                                  setIsDeviceSearchFocused(false);
                                  setDeviceSearchTerm('');
                                }}
                              >
                                <div>{device.device_name}</div>
                                <div>{device.sample_type}</div>
                              </AddressOption>
                            ))
                          ) : (
                            <LoadingAddressOption>
                              <LoadingText>
                                {debouncedDeviceSearch ? 'No matching devices found' : 'Start typing to search devices'}
                              </LoadingText>
                            </LoadingAddressOption>
                          )}
                        </AddressDropdown>
                      )}
                    </SearchBar>
                    {devicesLoading && <small>Loading devices...</small>}
                  </FormGroup>

                  <FormGroup style={{ marginTop: '2rem' }}>
                    <FormLabel>Package Dimensions</FormLabel>
                    <DimensionsInputGroup>
                      <DimensionInput 
                        placeholder="L" 
                        value={dimensions.length}
                        onChange={(e) => handleDimensionChange('length', e.target.value)}
                        disabled={sameAsOutbound}
                      />
                      <DimensionSeparator>×</DimensionSeparator>
                      <DimensionInput 
                        placeholder="W"
                        value={dimensions.width}
                        onChange={(e) => handleDimensionChange('width', e.target.value)}
                        disabled={sameAsOutbound}
                      />
                      <DimensionSeparator>×</DimensionSeparator>
                      <DimensionInput 
                        placeholder="H"
                        value={dimensions.height}
                        onChange={(e) => handleDimensionChange('height', e.target.value)}
                        disabled={sameAsOutbound}
                      />
                    </DimensionsInputGroup>
                  </FormGroup>
                </div>

                <div>
                  <FormGroup>
                    <FormLabel>Quantity</FormLabel>
                    <QuantityInput 
                      type="text" 
                      placeholder="1"
                      value={returnQuantity}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, '');
                        if (value === '' || parseInt(value) > 0) {
                          setReturnQuantity(value);
                        }
                      }}
                      onBlur={() => {
                        if (!returnQuantity) {
                          setReturnQuantity('1');
                        }
                      }}
                      disabled={sameAsOutbound}
                    />
                  </FormGroup>

                  <FormGroup style={{ marginTop: '2rem' }}>
                    <FormLabel>Weight</FormLabel>
                    <WeightInputGroup>
                      <WeightInput 
                        type="text" 
                        placeholder="0.0"
                        value={weight}
                        onChange={handleWeightChange}
                        disabled={sameAsOutbound}
                      />
                      <WeightUnit>lbs.</WeightUnit>
                    </WeightInputGroup>
                  </FormGroup>
                </div>
              </ProfileDetailsGrid>

            </ReturnProfileSection>

            <ReturnDivider />

            <ReturnServiceSection>
              <SubsectionTitle>Return Shipment Service</SubsectionTitle>
              <ServiceOptions>
                {availableServices.map((service) => (
                  <ServiceCard
                    key={service.name}
                    isSelected={returnService === service.name}
                    onClick={() => handleReturnServiceSelect(service.name)}
                  >
                    <ServiceName>{service.name}</ServiceName>
                    <ServiceDetails>
                      <DeliveryTime>{service.deliveryTime}</DeliveryTime>
                      <Price>{service.price}</Price>
                    </ServiceDetails>
                  </ServiceCard>
                ))}
              </ServiceOptions>
              <ReturnShippingOptions>
                <CheckboxRow>
                  <CheckboxOption>
                    <Checkbox 
                      type="checkbox" 
                      id="returnSignature" 
                      checked={returnShipment?.options.requireSignature || false}
                      onChange={handleReturnSignatureChange}
                    />
                    <CheckboxLabel htmlFor="returnSignature">
                      Require Signature
                    </CheckboxLabel>
                  </CheckboxOption>
                  <CheckboxOption>
                    <Checkbox 
                      type="checkbox" 
                      id="returnHoldLocation"
                      onChange={(e) => setReturnHoldAtLocation(e.target.checked)}
                      checked={returnHoldAtLocation}
                    />
                    <CheckboxLabel htmlFor="returnHoldLocation">
                      Hold at Local FedEx Location
                    </CheckboxLabel>
                    {returnHoldAtLocation && (
                      <BaseInputAbsolute 
                        placeholder="Enter ZIP to Select Location" 
                        style={{ width: '14rem'}}
                      />
                    )}
                  </CheckboxOption>
                  <CheckboxOption>
                    <Checkbox 
                      type="checkbox" 
                      id="returnEmailLabel"
                      onChange={(e) => setReturnEmailLabel(e.target.checked)}
                      checked={returnEmailLabel}
                    />
                    <CheckboxLabel htmlFor="returnEmailLabel">
                      Email Return Shipping Label
                    </CheckboxLabel>
                    {returnEmailLabel && (
                      <BaseInputAbsolute 
                        type="email"
                        placeholder="Email" 
                        style={{ width: '13rem' }}
                      />
                    )}
                  </CheckboxOption>
                  <CheckboxOption>
                    <Checkbox 
                      type="checkbox"
                      id="returnReferences"
                      onChange={(e) => setReturnShowReferences(e.target.checked)}
                      checked={returnShowReferences}
                    />
                    <CheckboxLabel htmlFor="returnReferences">
                      Add Shipment References
                    </CheckboxLabel>
                    {returnShowReferences && (
                      <div style={{ position: 'absolute', top: '100%', left: '2rem', marginTop: '0.5rem', display: 'flex', flexDirection: 'column', gap: '0.5rem', zIndex: 1 }}>
                        <BaseInput placeholder="Shipment ID" style={{ width: '15rem' }} />
                        <BaseInput placeholder="P.O. No." style={{ width: '15rem' }} />
                        <BaseInput placeholder="Invoice No." style={{ width: '15rem' }} />
                        <BaseInput placeholder="Department No." style={{ width: '15rem' }} />
                      </div>
                    )}
                  </CheckboxOption>
                </CheckboxRow>
              </ReturnShippingOptions>
            </ReturnServiceSection>
          </ReturnSection>
        )}

        {showNewAddressModal && (
          <Modal onClick={() => {
            setShowNewAddressModal(false);
            // Don't uncheck the checkbox when just closing the modal
          }}>
            <ModalContent onClick={e => e.stopPropagation()}>
              <ModalHeader>
                <ModalTitle>Add New Address</ModalTitle>
                <CloseButton onClick={() => {
                  setShowNewAddressModal(false);
                  // Don't uncheck the checkbox when just closing the modal
                }}>&times;</CloseButton>
              </ModalHeader>
              <FormColumns>
                <AddressColumn>
                  <FormGroup>
                    <Input 
                      type="text" 
                      placeholder="Country/Territory" 
                      value={localFormData.country}
                      onChange={(e) => updateFormData({ country: e.target.value })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input 
                      type="text" 
                      placeholder="Address Line 1" 
                      value={localFormData.addressLine1}
                      onChange={(e) => updateFormData({ addressLine1: e.target.value })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input 
                      type="text" 
                      placeholder="Address Line 2"
                      value={localFormData.addressLine2}
                      onChange={(e) => updateFormData({ addressLine2: e.target.value })}
                    />
                  </FormGroup>
                  <ZipCityRow>
                    <FormGroup>
                      <Input 
                        type="text" 
                        placeholder="Zip" 
                        value={localFormData.zip}
                        onChange={(e) => updateFormData({ zip: e.target.value })}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input 
                        type="text" 
                        placeholder="City" 
                        value={localFormData.city}
                        onChange={(e) => updateFormData({ city: e.target.value })}
                      />
                    </FormGroup>
                  </ZipCityRow>
                  <FormGroup>
                    <Input 
                      type="text" 
                      placeholder="State/District" 
                      value={localFormData.state}
                      onChange={(e) => updateFormData({ state: e.target.value })}
                    />
                  </FormGroup>
                  <ModalActions>
                    <SaveButton onClick={handleAddressSubmit}>Save Address</SaveButton>
                  </ModalActions>
                </AddressColumn>
              </FormColumns>
            </ModalContent>
          </Modal>
        )}

        {showNewContactModal && (
          <Modal onClick={() => setShowNewContactModal(false)}>
            <ModalContent onClick={e => e.stopPropagation()}>
              <ModalHeader>
                <ModalTitle>Add New Contact Person</ModalTitle>
                <CloseButton onClick={() => setShowNewContactModal(false)}>&times;</CloseButton>
              </ModalHeader>
              <FormColumns>
                <ContactColumn>
                  <FormGroup>
                    <Input 
                      type="text" 
                      placeholder="Contact Name"
                      value={localFormData.contactName}
                      onChange={(e) => updateFormData({ contactName: e.target.value })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input 
                      type="text" 
                      placeholder="Company"
                      value={localFormData.company}
                      onChange={(e) => updateFormData({ company: e.target.value })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input 
                      type="tel" 
                      placeholder="Phone Number"
                      value={localFormData.phoneNumber}
                      onChange={(e) => updateFormData({ phoneNumber: e.target.value })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input 
                      type="text" 
                      placeholder="Phone Extension" 
                      value={localFormData.phoneExtension}
                      onChange={(e) => updateFormData({ phoneExtension: e.target.value })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input 
                      type="email" 
                      placeholder="Email"
                      value={localFormData.email}
                      onChange={(e) => updateFormData({ email: e.target.value })}
                    />
                  </FormGroup>
                  <ModalActions>
                    <SaveButton onClick={handleContactSubmit}>Save Contact</SaveButton>
                  </ModalActions>
                </ContactColumn>
              </FormColumns>
            </ModalContent>
          </Modal>
        )}

        {errors.length > 0 && (
          <ErrorContainer>
            {errors.map((error, index) => (
              <ErrorMessage key={index}>{error}</ErrorMessage>
            ))}
          </ErrorContainer>
        )}

        <ButtonContainer>
          <StyledNextButton onClick={handleNext}>
            Next
          </StyledNextButton>
        </ButtonContainer>

        {/* Inside the return statement, before the final closing tags */}
        {createReturn && (
          <>
            

            {/* <Section>
              <SectionTitle>Test Contact Search</SectionTitle>
              
              {/* Contact Search Dropdown */}
              {/* <FormGroup style={{ position: 'relative', zIndex: 100 }}>
                <SearchBar style={{ position: 'relative' }}>
                  <SearchInput 
                    type="text" 
                    placeholder={contactsLoading ? "Loading contacts..." : "Search contacts by name, email, or phone"}
                    value={contactSearchTerm}
                    onChange={(e) => setContactSearchTerm(e.target.value)}
                    onFocus={() => setIsContactDropdownVisible(true)}
                    onBlur={() => {
                      setTimeout(() => {
                        setIsContactDropdownVisible(false);
                      }, 200);
                    }}
                  />
                  {isContactDropdownVisible && (
                    <AddressDropdown 
                      onMouseDown={(e) => e.preventDefault()}
                      style={{ 
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        border: '1px solid #E0E0E0',
                        zIndex: 1000
                      }}
                    >
                      {contactsFetching ? (
                        <LoadingAddressOption>
                          <LoadingText>Searching contacts...</LoadingText>
                        </LoadingAddressOption>
                      ) : filteredContacts.length > 0 ? (
                        filteredContacts.map((contact: ContactPerson, index: number) => (
                          <AddressOption 
                            key={contact.id}
                            onMouseDown={(e) => {
                              e.preventDefault();
                              handleContactSelect(contact);
                              setContactSearchTerm(contact.name || '');
                              setIsContactDropdownVisible(false);
                            }}
                            isHighlighted={index === contactHighlightedIndex}
                          >
                            <div style={{ fontWeight: 500 }}>{contact.name}</div>
                            {contact.company && (
                              <div style={{ fontSize: '14px', color: '#666' }}>{contact.company}</div>
                            )}
                            <div style={{ fontSize: '14px', color: '#666' }}>
                              {contact.phone_extension} {contact.phone_number}
                            </div>
                            {contact.email && (
                              <div style={{ fontSize: '14px', color: '#666' }}>{contact.email}</div>
                            )}
                          </AddressOption>
                        ))
                      ) : (
                        <LoadingAddressOption>
                          <LoadingText>
                            {debouncedContactSearch ? 'No contacts found' : 'Start typing to search contacts'}
                          </LoadingText>
                        </LoadingAddressOption>
                      )}
                    </AddressDropdown>
                  )}
                </SearchBar>
              </FormGroup> */}

              {/* Contact Form Fields */}
              

              
        
          </>
        )}
      </Container>
    </PageLayout>
  );
};

const Container = styled.div`
  max-width: 64rem;
  margin: 0 auto;
  padding: 2rem;
`;

const BaseSelectStyles = `
  padding: 12px;
  border: 2px solid #E0E0E0;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
  background-color: white;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  cursor: pointer;

  &:disabled {
    background-color: #F5F5F5;
    cursor: not-allowed;
  }
`;

const ShipmentTypeSelect = styled.select`
  ${BaseSelectStyles}
  width: 100%;
  margin-bottom: 32px;
`;

const SelectionRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-bottom: 32px;
`;

const SelectGroup = styled.div`
  position: relative;
`;

const SelectLabel = styled.label`
  display: block;
  font-size: 0.75rem;
  color: #666;
  margin-bottom: 0.5rem;
`;

const ServiceSection = styled.div`
  margin-bottom: 32px;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  color: #333;
  margin-bottom: 24px;
`;

const SubsectionTitle = styled.h3`
  font-size: 1.125rem;
  margin-bottom: 1rem;
`;

const ServiceHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 32px;
`;

const DropoffSelect = styled.select`
  ${BaseSelectStyles}
  width: 100%;
  border-radius: 0;
  border: 0.0625rem solid ${colors.border};
`;

const BaseInput = styled.input`
  padding: 0.75rem 1rem;
  border: 0.0625rem solid ${colors.border};
  border-radius: 0;
  font-size: 1rem;
  background-color: white;
  
  &::placeholder {
    color: ${colors.text.placeholder};
  }

  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }
`;

const LocationInput = styled(BaseInput)`
  border-radius: 0;
  border: 0.0625rem solid ${colors.border};
  padding-left: 2.5rem;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z'%3e%3c/path%3e%3ccircle cx='12' cy='10' r='3'%3e%3c/circle%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: 0.75rem center;
  background-size: 1.25rem;
`;

const TimeInput = styled(BaseInput)`
  border-radius: 20px;
  border: 2px solid #000000;
`;

const ServiceOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 24px;
`;

const ServiceCard = styled.div<{ isSelected?: boolean }>`
  padding: 16px;
  border: 2px solid ${props => props.isSelected ? '#007AFF' : '#E0E0E0'};
  border-radius: 8px;
  cursor: pointer;
  background-color: ${props => props.isSelected ? '#F5F9FF' : 'white'};
  transition: all 0.2s ease;

  &:hover {
    border-color: #007AFF;
    background-color: #F5F9FF;
  }
`;

const ServiceName = styled.div`
  font-weight: 500;
  margin-bottom: 8px;
`;

const ServiceDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666;
  font-size: 14px;
`;

const DeliveryTime = styled.span``;

const Price = styled.span`
  font-weight: 500;
`;

const ShippingOptions = styled.div`
  margin-top: 24px;
`;

const CheckboxRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0.75rem;  // Reduced from 1rem
  width: 100%;
  padding: 0;
  font-size: 0.875rem;
`;

const CheckboxOption = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;  // Reduced from 8px
  position: relative;
  margin-right: 16px;  // Reduced from 24px
  min-width: fit-content;
  max-width: 280px;  // Added max-width to ensure consistent wrapping
`;

const BaseInputAbsolute = styled(BaseInput)`
  position: absolute;
  top: 100%;
  left: 2rem;
  margin-top: 0.5rem;
  z-index: 1;
  width: 15rem;
`;

const InputRow = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
  margin-left: 2rem;
`;

const ReferenceInputsRow = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 2rem;
  left: 2rem;
  z-index: 1;
`;

const SingleReferenceInput = styled.div`
  position: absolute;
  margin-top: 2rem;
  left: 2rem;
  z-index: 1;
`;

const StyledCheckbox = styled.input`
  appearance: none;
  width: 20px;  // Reduced from 24px
  height: 20px;  // Reduced from 24px
  border: 2px solid #E0E0E0;
  border-radius: 6px;  // Reduced from 8px
  cursor: pointer;
  position: relative;
  margin: 0;

  &:checked {
    background-color: #007AFF;
    border-color: #007AFF;
  }

  &:checked::after {
    content: '';
    position: absolute;
    left: 6px;  // Adjusted for new size
    top: 3px;   // Adjusted for new size
    width: 5px;  // Adjusted for new size
    height: 10px; // Adjusted for new size
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  &:disabled {
    background-color: #F5F5F5;
    cursor: not-allowed;
  }
`;

const Checkbox = styled(StyledCheckbox)``;  // Now inherits all styles from StyledCheckbox

const CheckboxLabel = styled.label`
  color: #666;
  font-size: 14px;  // Reduced from 16px
  cursor: pointer;
  white-space: nowrap;  // Prevent label from wrapping

  input:disabled + & {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;


const ZipInput = styled(BaseInput)`
  width: 12.5rem; // 200px equivalent
`;

const EmailInput = styled(BaseInput)`
  width: 18.75rem; // 300px equivalent
`;

const ReferenceInput = styled(BaseInput)`
  width: 12.5rem; // 200px equivalent
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #E0E0E0;
  border-radius: 12px;
  font-size: 1rem;
  background-color: white;
  box-sizing: border-box;  // Added to ensure padding is included in width

  &:focus {
    outline: none;
    border-color: #000;
  }
`;

const ReturnSection = styled.div<ReturnSectionProps>`
  margin-top: 6rem;
  padding-top: 4rem;
  border-top: 0.0625rem solid ${colors.border};
  padding-bottom: ${props => props.showReferences ? '12rem' : '2rem'};
  position: relative;
`;

const ReturnAddressCheckboxWrapper = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  gap: ${spacing.xs};
`;

const ReturnCheckboxRow = styled(CheckboxRow)`
  gap: ${spacing.xl};
  justify-content: flex-start;
  padding: 0;
  width: 100%;
  margin-bottom: 1.5rem;
`;

const ReturnProfileSection = styled.div`
  margin-top: 3rem;
`;

const ProfileDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 3rem;
  margin-top: 2rem;
`;

const DimensionsInputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const DimensionInput = styled.input`
  width: 3.5rem;
  padding: 0.75rem;
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  text-align: center;

  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }
`;

const DimensionSeparator = styled.span`
  color: ${colors.text.secondary};
  font-size: 1rem;
`;

const DimensionsUnit = styled.span`
  color: ${colors.text.secondary};
  font-size: 0.875rem;
  margin-left: 0.25rem;
`;

const QuantityInput = styled.input`
  width: 6rem;
  padding: 0.75rem;
  border: 0.0625rem solid #E0E0E0;
  border-radius: 0.5rem;

  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const WeightInputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const WeightInput = styled.input`
  width: 6rem;
  padding: 0.75rem;
  border: 0.0625rem solid #E0E0E0;
  border-radius: 0.5rem;

  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const WeightUnit = styled.span`
  color: #666;
  font-size: 0.875rem;
`;

const ReturnDivider = styled.hr`
  border: none;
  border-top: 0.0625rem solid ${colors.border};
  margin: 3rem auto;
  width: 100%;
`;

const ReturnServiceSection = styled(ServiceSection)`
  // Ensure consistent spacing with outbound service section
  margin-top: 32px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.md};
  margin-bottom: ${spacing.lg};
`;

const FormLabel = styled.label`
  font-size: 1rem;
  color: ${colors.text.primary};
  font-weight: 500;
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  background-color: white;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }
`;

const ReferenceInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};
`;

const ReturnShippingOptions = styled(ShippingOptions)`
  // Ensure consistent spacing with outbound shipping options
  margin-top: 24px;
`;

const ReturnCheckboxOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};
`;

const ReturnSearchBar = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
`;

const AddressDropdown = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background: white;
  border: 1px solid ${colors.border};
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 9999;  // Always keep dropdown at highest level
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-top: 0;
`;

const AddressOption = styled.div<{ isHighlighted?: boolean }>`
  padding: ${spacing.sm};
  cursor: pointer;
  background: ${props => props.isHighlighted ? '#f5f5f5' : 'transparent'};
  
  &:hover {
    background: #f5f5f5;
  }
  
  div:first-of-type {
    font-weight: 500;
  }
  
  div:last-of-type {
    font-size: 0.875rem;
    color: ${colors.text.secondary};
  }
`;

const LoadingAddressOption = styled.div`
  padding: ${spacing.sm};
`;

const LoadingText = styled.div`
  color: ${colors.text.secondary};
  text-align: center;
  padding: ${spacing.sm};
`;

const SelectedAddress = styled.div`
  padding: ${spacing.sm};
  border: 1px solid ${colors.border};
  border-radius: 0.5rem;
  margin-top: ${spacing.sm};
  
  p {
    margin: 0;
    line-height: 1.5;
  }
`;

const ErrorContainer = styled.div`
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #fff5f5;
  border: 1px solid #fc8181;
`;

const ErrorMessage = styled.p`
  color: #e53e3e;
  margin: 0.25rem 0;
  font-size: 0.875rem;
`;

const SelectedAddressSection = styled.div`
  margin: ${spacing.md} 0;
  padding: ${spacing.md};
  border: 1px solid ${colors.border};
  border-radius: 8px;
`;

const DevicesSection = styled.div`
  margin: ${spacing.md} 0;
  padding: ${spacing.md};
  border: 1px solid ${colors.border};
  border-radius: 8px;
`;

const DeviceItem = styled.div`
  padding: ${spacing.sm};
  margin: ${spacing.xs} 0;
  background: #f5f5f5;
  border-radius: 4px;
  font-size: 0.875rem;
`;

const ReferenceInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 0.75rem;
`;

const SearchBar = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  max-width: 100%;
  z-index: 1;  // Lower default z-index

  // When the dropdown is active, raise the z-index to the highest level
  &:focus-within {
    z-index: 9999;  // Very high z-index when focused/dropdown is shown
  }
`;

const ReturnProfileCheckboxWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  gap: ${spacing.xs};
`;

const ReturnProfileCheckboxRow = styled(CheckboxRow)`
  gap: ${spacing.xs};
  justify-content: flex-start;
  padding: 0;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 40rem;
  max-height: 90vh;
  overflow-y: auto;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const ModalTitle = styled.h2`
  font-size: 1.5rem;
  color: ${colors.text.primary};
  margin: 0;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  color: ${colors.text.secondary};
  cursor: pointer;
  padding: 0.5rem;
  line-height: 1;
  
  &:hover {
    color: ${colors.text.primary};
  }
`;


const ContactColumn = styled.div`
  width: 100%;  // Added to ensure full width
  position: relative;  // Added to create stacking context
  z-index: 1;  // Added to manage stacking
`;

const AddressColumn = styled.div`
  width: 100%;  // Added to ensure full width
  position: relative;  // Added to create stacking context
  z-index: 1;  // Added to manage stacking
`;

const ZipCityRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: ${spacing.md};
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  font-size: 14px;
  color: #333333;

  &::placeholder {
    color: #999999;
  }

  &:focus {
    outline: none;
    border-color: #2196F3;
  }
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

const SaveButton = styled.button`
  background: ${colors.primary};
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  
  &:hover {
    opacity: 0.9;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  padding-bottom: 2rem;
`;

const StyledNextButton = styled(NextButton)`
  background-color: black;
  color: white;
  padding: 0.75rem 2.5rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #333;
  }
`;

const Section = styled.div`
  margin-bottom: ${spacing.xl};
  width: 100%;

  &:not(:first-of-type) {
    margin-top: ${spacing.xl};
  }
`;

const StyledSubtitle = styled.h3`
  font-size: 1.25rem;
  margin-bottom: ${spacing.xs};
`;

const AddressTypeGroup = styled.div`
  margin-top: 24px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.sm};
  margin-bottom: ${spacing.sm};
`;

const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.xs};
  margin-bottom: ${spacing.xs};
`;

const DescriptionInput = styled(Input)`
  width: 200px;
  margin-left: 8px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #2196F3;
  }

  &::placeholder {
    color: #999999;
  }
`;

const OptionsRow = styled.div`
  margin-top: 24px;
  border-top: 1px solid #E0E0E0;
  padding-top: 24px;
  display: flex;
  gap: 48px;
`;

const OptionGroup = styled.div`
  flex: 1;
`;

const InputContainer = styled.div`
  position: relative;
  margin-left: 36px;
  margin-bottom: 12px;
  width: 100%;
`;

const EmailInputRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 65%;
  margin-left: 20rem
`;

const EmailButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: absolute;
  right: -40px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 2px solid #E0E0E0;
  border-radius: 8px;
  font-size: 16px;
  color: #333;

  &::placeholder {
    color: #999;
  }

  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const SimpleButton = styled.button`
  background: none;
  border: none;
  color: #4285f4;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  line-height: 1;

  &:hover {
    opacity: 0.8;
  }
`;

const Label = styled.label`
  font-size: 1rem;
  color: ${colors.text.primary};
  margin-bottom: ${spacing.xs};
`;

const DeliverToText = styled.div`
  font-size: 1rem;
  color: ${colors.text.primary};
  margin-bottom: 1rem;
`;

const FormColumns = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
gap: ${spacing.xl};
margin-top: ${spacing.sm};
`;

const DeliveryForm = styled.div`
margin-top: ${spacing.sm};
`;

export default CreateLabels;