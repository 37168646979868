import React, { createContext, useContext, useState } from 'react';
import { ShippingService, SHIPPING_SERVICES } from '../types/models/shipping';

interface ShippingSelectionContextType {
  selectedService: string;
  setSelectedService: (service: string) => void;
  availableServices: ShippingService[];
  getServiceDetails: (serviceName: string) => ShippingService | undefined;
}

const ShippingSelectionContext = createContext<ShippingSelectionContextType | undefined>(undefined);

export const ShippingSelectionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedService, setSelectedService] = useState<string>('');

  const getServiceDetails = (serviceName: string) => {
    return SHIPPING_SERVICES.find(service => service.name === serviceName);
  };

  return (
    <ShippingSelectionContext.Provider value={{
      selectedService,
      setSelectedService,
      availableServices: SHIPPING_SERVICES,
      getServiceDetails
    }}>
      {children}
    </ShippingSelectionContext.Provider>
  );
};

export const useShippingSelection = () => {
  const context = useContext(ShippingSelectionContext);
  if (context === undefined) {
    throw new Error('useShippingSelection must be used within a ShippingSelectionProvider');
  }
  return context;
}; 