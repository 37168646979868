import { useQuery } from '@tanstack/react-query';
import { addressApi } from '../api/endpoints/addresses';
import type { Address } from '../types/models/address';

export const useAddresses = (searchTerm?: string) => {
  return useQuery<Address[]>(
    ['addresses', searchTerm],
    async () => {
      const response = await addressApi.searchAddresses(searchTerm);
      // The response is already the array of addresses
      return response;
    },
    {
      enabled: true,
      staleTime: 300000,
      cacheTime: 3600000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: 1,
    }
  );
};