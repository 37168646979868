import React, { useState, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import PageLayout from '../../components/common/Layout/PageLayout';
import { Title, Label, Subtitle } from '../../components/common/Typography';
import NextButton from '../../components/common/Button/NextButton';
import { useNavigate } from 'react-router-dom';
import ShipmentHeader from '../../components/Shipment/ShipmentHeader';
import { useCreateShipment, useShipmentProfiles, useShipments } from '../../api/endpoints/shipments';
import { useTrials } from '../../hooks/useTrials';
import { ShipmentStatus } from '../../types/models/shipment';
import type { BasicShipment } from '../../types/models/shipment';
import { useDebounce } from '../../hooks/useDebounce';
import type { Trial } from '../../types/models/trial';
import type { Address } from '../../types/models/address';
import { useAddresses } from '../../hooks/useAddresses';
import { useContactPerson, useContactPersons } from '../../api/endpoints/contact-persons';
import type { ContactPerson } from '../../types/models/contact-person';
import { useShipmentTemplates } from 'hooks/useShipmentTemplates';
import { useCollectionDevices } from 'hooks/useCollectionDevices';
import type { Device } from '../../types/models/device';
import type { Shipment } from '../../types/models/shipment';
import type { ShipmentResponse } from '../../api/endpoints/shipments';
import { useMutation } from 'react-query';
// import { toast } from 'react-hot-toast';
// import { queryClient } from '../../api/queryClient';
import { addressApi } from 'api/endpoints/addresses';
import { useSendOutKits } from '../../contexts/SendOutKitsContext';
import type { OutboundShipment } from '../../contexts/SendOutKitsContext';
import { useKits } from '../../hooks/useKits';
import type { Kit } from '../../types/models/kit';


interface CollectionDevice {
  id: string;
  device_id?: string;
  device_name?: string;
  quantity: number;
  name: string;
  searchTerm?: string;
  isSearchFocused?: boolean;
  sample_type?: string;
}

interface DiagnosticTest {
  id: string;
  testId: string;
  batchNo: string;
  expiryDate: string;
  quantity: string;
  patientId?: string;
  tempLogger?: string;
}


const ShipmentSetup: React.FC = () => {
  const navigate = useNavigate();
  const { 
    selectedTrialId, 
    setSelectedTrialId,
    selectedShipment,
    setSelectedShipment,
    outboundShipment,
    setOutboundShipment,
    returnShipment,
    setReturnShipment,
    formData,
    setFormData
  } = useSendOutKits();

  // Email notification states
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [emailAddresses, setEmailAddresses] = useState<string[]>(['']);

  // Initialize form data from context if it exists
  const [localFormData, setLocalFormData] = useState<any>(formData || {
    // Contact & Address Info
    country: '',
    addressLine1: '',
    addressLine2: '',
    zip: '',
    city: '',
    state: '',
    contactName: '',
    company: '',
    phoneNumber: '',
    phoneExtension: '',
    email: '',
    
    // Address Type
    isResidential: false,
    isClinic: false,
    isOther: false,
    addressDescription: '',
    
    // Email Notifications
    emailNotifications: false,
    notificationEmails: [''],
    
    // Shipment Information
    templateId: '',
    trialId: '',
    
    // Physical Product Information
    dimensions: {
      length: '',
      width: '',
      height: ''
    },
    weight: '',
    
    // Kit Information
    diagnosticTests: [{
      id: '1',
      testId: '',
      batchNo: '',
      expiryDate: '',
      quantity: '1'
    }],
    
    // Collection Devices
    collectionDevices: [{
      id: '',
      quantity: 0,
      name: '',
      searchTerm: '',
      isSearchFocused: false
    }],
    
    // Selected Kit
    selectedKit: null,
    
    // Contents Device
    selectedDevice: null,
    
    // Save Template
    saveAsTemplate: false,
    newTemplateId: ''
  });

  // Initialize selected address from outbound shipment if it exists
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(
    outboundShipment?.address || null
  );

  // Initialize selected trial from context
  const [selectedTrial, setSelectedTrial] = useState(selectedTrialId || '');

  // Address and Search States
  const [searchTerm, setSearchTerm] = useState('');
  const [isAddressFromBook, setIsAddressFromBook] = useState(false);
  const [saveNewAddress, setSaveNewAddress] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const debouncedSearch = useDebounce(searchTerm, 300);

  // API hooks
  const { 
    data: addressesResponse, 
    isLoading: addressesLoading,
    isFetching: addressesFetching 
  } = useAddresses(debouncedSearch);

  // Add missing state declarations after other state declarations
  const [dimensions, setDimensions] = useState({ length: '', width: '', height: '' });
  const [weight, setWeight] = useState('');
  const [diagnosticTests, setDiagnosticTests] = useState<DiagnosticTest[]>([{
    id: '1',
    testId: '',
    batchNo: '',
    expiryDate: '',
    quantity: '1'
  }]);

  // Add synchronization effect for dimensions and weight
  useEffect(() => {
    updateFormData({
      dimensions,
      weight
    });
  }, [dimensions, weight]);

  // Effect to restore saved state when component mounts
  useEffect(() => {
    // Restore form data from localStorage
    const savedFormData = localStorage.getItem('sendOutKitsFormData');
    if (savedFormData) {
      const parsedFormData = JSON.parse(savedFormData);
      setLocalFormData(parsedFormData);
      setFormData(parsedFormData);
      
      // Restore all UI state from saved form data
      if (parsedFormData.emailNotifications) {
        setEmailNotifications(parsedFormData.emailNotifications);
        setEmailAddresses(parsedFormData.notificationEmails || ['']);
      }
      
      if (parsedFormData.diagnosticTests) {
        setDiagnosticTests(parsedFormData.diagnosticTests);
      }
      
      if (parsedFormData.dimensions) {
        setDimensions(parsedFormData.dimensions);
      }
      
      if (parsedFormData.weight) {
        setWeight(parsedFormData.weight);
      }

      // Restore collection devices
      if (parsedFormData.collectionDevices) {
        setCollectionDevices(parsedFormData.collectionDevices);
      }

      // Restore selected kit
      if (parsedFormData.selectedKit) {
        setSelectedKit(parsedFormData.selectedKit);
      }

      // Restore selected device
      if (parsedFormData.selectedDevice) {
        setSelectedDevice(parsedFormData.selectedDevice);
        setContentsDeviceSearchTerm(parsedFormData.selectedDevice.device_name || '');
      }

      // Restore save template state
      if (parsedFormData.saveAsTemplate !== undefined) {
        setSaveAsTemplate(parsedFormData.saveAsTemplate);
      }
      if (parsedFormData.newTemplateId) {
        setNewTemplateId(parsedFormData.newTemplateId);
      }
    }

    // Restore outbound shipment from localStorage
    const savedOutboundShipment = localStorage.getItem('outboundShipment');
    if (savedOutboundShipment) {
      const parsedOutboundShipment = JSON.parse(savedOutboundShipment);
      setOutboundShipment(parsedOutboundShipment);
      if (parsedOutboundShipment.address) {
        setSelectedAddress(parsedOutboundShipment.address);
        setIsAddressFromBook(true);
      }
    }

    // Restore return shipment from localStorage
    const savedReturnShipment = localStorage.getItem('returnShipment');
    if (savedReturnShipment) {
      const parsedReturnShipment = JSON.parse(savedReturnShipment);
      setReturnShipment(parsedReturnShipment);
    }
  }, []);

  // Save to context whenever local form data changes
  useEffect(() => {
    setFormData(localFormData);
  }, [localFormData, setFormData]);

  // Update local form data
  const updateFormData = (updates: Partial<typeof localFormData>) => {
    setLocalFormData((prev: typeof localFormData) => ({
      ...prev,
      ...updates
    }));
  };

  // Contact States
  const [contactSearchTerm, setContactSearchTerm] = useState('');
  const [selectedContact, setSelectedContact] = useState<ContactPerson | null>(null);
  const [contactHighlightedIndex, setContactHighlightedIndex] = useState(-1);
  const [isContactSearchFocused, setIsContactSearchFocused] = useState(false);
  const debouncedContactSearch = useDebounce(contactSearchTerm, 300);
  const { 
    data: contactPersonsResponse, 
    isLoading: contactsLoading,
    isFetching: contactsFetching 
  } = useContactPersons({ 
    search: debouncedContactSearch,
  });

  // Memoized Filtered Contacts
  const filteredContacts = useMemo(() => {
    const contacts = Array.isArray(contactPersonsResponse) ? contactPersonsResponse : [];
    if (!debouncedContactSearch) {
      return contacts.slice(0, 5);
    }
    const searchTermLower = debouncedContactSearch.toLowerCase();
    return contacts.filter((contact: ContactPerson) => {
      const nameMatch = contact.name?.toLowerCase()?.includes(searchTermLower) || false;
      const emailMatch = contact.email?.toLowerCase()?.includes(searchTermLower) || false;
      const phoneMatch = contact.phone_number?.toLowerCase()?.includes(searchTermLower) || false;
      return nameMatch || emailMatch || phoneMatch;
    });
  }, [contactPersonsResponse, debouncedContactSearch]);

  // Other States
  const [selectedShipmentType, setSelectedShipmentType] = useState('');
  const [selectedProfile, setSelectedProfile] = useState('');
  const [statusMessage, setStatusMessage] = useState<{
    text: string;
    type: 'error' | 'success' | 'warning'
  } | null>(null);

  // API Hooks
  const { data: profiles, isLoading: profilesLoading } = useShipmentProfiles();
  const { trials, isLoading: trialsLoading } = useTrials();
  const { data: shipments, isLoading: isLoadingShipments } = useShipments();
  const createShipmentMutation = useCreateShipment();

  const [collectionDevices, setCollectionDevices] = useState<CollectionDevice[]>([{
    id: '',
    quantity: 0,
    name: '',
    searchTerm: '',
    isSearchFocused: false
  }]);

  const addCollectionDevice = () => {
    setCollectionDevices([...collectionDevices, {
      id: '',
      quantity: 0,
      name: '',
      searchTerm: '',
      isSearchFocused: false
    }]);
    
    // Update form data when adding new device
    updateFormData({
      collectionDevices: [...collectionDevices, {
        id: '',
        quantity: 0,
        name: '',
        searchTerm: '',
        isSearchFocused: false
      }]
    });
  };

  const removeCollectionDevice = (index: number) => {
    setCollectionDevices(collectionDevices.filter((_: CollectionDevice, i: number) => i !== index));
  };

  const handleTrialSelect = (trialId: string) => {
    setSelectedTrialId(trialId);
    console.log('📋 Trial selected:', trialId);
    setSelectedTrial(trialId);
    
    if (trialId && trials) {
      const selectedTrialData = trials.find(t => t.trial_id === trialId);
      if (selectedTrialData) {
        if (selectedTrialData.default_address) {
          updateFormData({
            addressLine1: selectedTrialData.default_address?.street || '',
            city: selectedTrialData.default_address?.city || '',
            state: selectedTrialData.default_address?.state || '',
            zip: selectedTrialData.default_address?.postal_code || '',
            country: selectedTrialData.default_address?.country || ''
          });
        }
      }
    }
    
    setSelectedAddress(null);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!filteredAddresses.length) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setHighlightedIndex(prev => 
          prev < filteredAddresses.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex(prev => prev > 0 ? prev - 1 : 0);
        break;
      case 'Enter':
        e.preventDefault();
        if (highlightedIndex >= 0) {
          handleAddressSelect(filteredAddresses[highlightedIndex]);
        }
        break;
      case 'Escape':
        setSearchTerm('');
        setHighlightedIndex(-1);
        break;
    }
  };

  const handleAddressSelect = (address: Address) => {
    setSelectedAddress(address);
    setSearchTerm('');
    setHighlightedIndex(-1);
    
    updateFormData({
      addressLine1: address.street || '',
      city: address.city || '',
      state: address.state || '',
      zip: address.postal_code || '',
      country: address.country || ''
    });

    setOutboundShipment({
      address: address
    });
  };

  const handleNext = async () => {
    console.log('Next button clicked - Current State:', {
      selectedAddress,
      formData: localFormData,
      isAddressFromBook,
      saveNewAddress,
      emailNotifications,
      emailAddresses,
      diagnosticTests,
      collectionDevices,
      selectedKit,
      selectedDevice
    });

    // Check if we have a complete address either from selection or manual entry
    const hasCompleteAddress = selectedAddress || (
      localFormData.addressLine1 &&
      localFormData.city &&
      localFormData.state &&
      localFormData.zip &&
      localFormData.country
    );

    if (hasCompleteAddress) {
      // If we have a selected device, ensure it has a quantity
      if (selectedDevice) {
        const deviceWithQuantity = {
          ...selectedDevice,
          quantity: selectedDevice.quantity || 1  // Default to 1 if quantity is undefined or 0
        };
        setSelectedDevice(deviceWithQuantity);
        updateFormData({
          selectedDevice: deviceWithQuantity
        });
      }

      // Update collection devices with default quantity of 1 if empty
      const updatedCollectionDevices = collectionDevices.map(device => ({
        ...device,
        quantity: device.quantity || 1  // Default to 1 if quantity is undefined or 0
      }));
      setCollectionDevices(updatedCollectionDevices);

      // Update diagnostic tests with default quantity of 1 if empty
      const updatedDiagnosticTests = diagnosticTests.map(test => ({
        ...test,
        quantity: test.quantity || '1'  // Default to '1' if quantity is empty
      }));
      setDiagnosticTests(updatedDiagnosticTests);

      // Save all form data to context and localStorage
      const formDataToSave = {
        ...localFormData,
        emailNotifications,
        notificationEmails: emailAddresses,
        diagnosticTests: updatedDiagnosticTests,
        dimensions: {
          length: dimensions.length,
          width: dimensions.width,
          height: dimensions.height
        },
        weight: weight,
        collectionDevices: updatedCollectionDevices,
        selectedKit,
        selectedDevice: selectedDevice ? {
          ...selectedDevice,
          quantity: selectedDevice.quantity || 1  // Ensure quantity is set here too
        } : null,
        // Add template ID if save template is checked
        templateId: localFormData.saveAsTemplate ? localFormData.newTemplateId : undefined
      };
      
      setFormData(formDataToSave);
      localStorage.setItem('sendOutKitsFormData', JSON.stringify(formDataToSave));

      // Update outbound shipment with all details
      const outboundUpdate: Partial<OutboundShipment> = {
        ...outboundShipment,  // Keep existing data
        devices: [
          // Include selected device if it exists and has a name and quantity
          ...(selectedDevice?.device_name ? [{
            id: selectedDevice.device_id || String(Math.random()),
            name: selectedDevice.device_name as string,  // Assert it's a string since we checked it exists
            quantity: typeof selectedDevice.quantity === 'string' ? parseInt(selectedDevice.quantity) || 1 : selectedDevice.quantity || 1
          }] : []),
          // Include collection devices that have a name
          ...updatedCollectionDevices
            .filter((device): device is (CollectionDevice & { device_name: string }) => Boolean(device.device_name)) // Type guard to ensure device_name exists
            .map(device => ({
              id: device.device_id || String(Math.random()),
              name: device.device_name,  // Now TypeScript knows this is a string
              quantity: typeof device.quantity === 'string' ? parseInt(device.quantity) || 1 : device.quantity || 1
            })),
          // Include diagnostic tests that have been filled out
          ...updatedDiagnosticTests
            .filter((test): test is DiagnosticTest & { testId: string; batchNo: string } => Boolean(test.testId && test.batchNo)) // Type guard
            .map(test => ({
              id: test.testId,  // Now TypeScript knows this exists
              name: `Test ${test.testId} (Batch: ${test.batchNo})`,
              quantity: parseInt(test.quantity) || 1
            }))
        ],
        packageDetails: {
          ...outboundShipment.packageDetails,  // Keep existing package details
          dimensions: formDataToSave.dimensions,
          weight: formDataToSave.weight,
          kitName: selectedKit?.kit_name || formDataToSave.diagnosticTests
            .filter((test: DiagnosticTest) => test.testId)  // Only include tests that have been filled out
            .map((test: DiagnosticTest) => test.testId)
            .join(',')
        },
        options: {
          ...outboundShipment.options,  // Keep existing options
          requireSignature: outboundShipment.options.requireSignature,
          holdAtLocation: outboundShipment.options.holdAtLocation,
          locationZip: outboundShipment.options.locationZip
        },
        metadata: {
          ...outboundShipment.metadata,  // Keep existing metadata
          emailNotifications: formDataToSave.emailNotifications,
          notificationEmails: emailNotifications ? emailAddresses : [],
          selectedDevice: selectedDevice && selectedDevice.device_name ? {
            ...selectedDevice,
            quantity: selectedDevice.quantity || 1
          } : null,
          selectedKit: selectedKit,
          templateId: localFormData.saveAsTemplate ? localFormData.newTemplateId : undefined
        }
      };

      // Save to context and localStorage
      setOutboundShipment(outboundUpdate);
      localStorage.setItem('outboundShipment', JSON.stringify(outboundUpdate));
      
      navigate('/send-kits/create-labels');
    } else {
      setStatusMessage({
        text: 'Please provide a complete address before proceeding',
        type: 'warning'
      });
    }
  };

  // Add effect to show warning when address fields are modified
  useEffect(() => {
    if (formData.addressLine1 && !selectedAddress && !saveNewAddress) {
      setStatusMessage({
        text: 'This address must be saved to the address book before proceeding',
        type: 'warning'
      });
    } else if (formData.addressLine1 && selectedAddress) {
      // Clear the warning message if address is selected or saved
      setStatusMessage(null);
    }
  }, [formData.addressLine1, selectedAddress, saveNewAddress]);

  const filteredAddresses = useMemo(() => {
    console.log('🏠 Raw addresses response:', addressesResponse);
    
    const addresses = addressesResponse || [];
    console.log('📍 Available addresses:', addresses);

    if (addresses.length === 0) {
      console.log('⚠️ No addresses available');
      return [];
    }
    
    if (!debouncedSearch) {
      console.log(' No search term - showing recent addresses');
      const recentAddresses = addresses.slice(0, 5);
      console.log('📍 Recent addresses:', recentAddresses);
      return recentAddresses;
    }

    console.log('🔎 Filtering addresses with search term:', debouncedSearch);
    const searchTermLower = debouncedSearch.toLowerCase();
    
    const filtered = addresses.filter((address: Address) => {
      const streetMatch = address.street?.toLowerCase().includes(searchTermLower);
      const cityMatch = address.city?.toLowerCase().includes(searchTermLower);
      const stateMatch = address.state?.toLowerCase().includes(searchTermLower);
      const postalMatch = address.postal_code?.toLowerCase().includes(searchTermLower);
      
      return streetMatch || cityMatch || stateMatch || postalMatch;
    });

    console.log('✨ Filtered addresses:', filtered);
    return filtered;
  }, [addressesResponse, debouncedSearch]);

  const handleContactKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!filteredContacts.length) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setContactHighlightedIndex(prev => 
          prev < filteredContacts.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setContactHighlightedIndex(prev => prev > 0 ? prev - 1 : 0);
        break;
      case 'Enter':
        e.preventDefault();
        if (contactHighlightedIndex >= 0) {
          handleContactSelect(filteredContacts[contactHighlightedIndex]);
        }
        break;
      case 'Escape':
        setContactSearchTerm('');
        setContactHighlightedIndex(-1);
        break;
    }
  };

  const handleContactSelect = (contact: ContactPerson) => {
    console.log('🎯 Contact selected:', contact);
    
    // Update form data with all contact information
    updateFormData({
      contactName: contact.name || '',
      phoneNumber: contact.phone_number || '',
      phoneExtension: contact.phone_extension || '+1',
      email: contact.email || '',
      company: contact.company || ''
    });

    // Update outbound shipment context with all contact information
    setOutboundShipment({
      contactInfo: {
        name: contact.name || '',
        phoneNumber: contact.phone_number || '',
        phoneExtension: contact.phone_extension || '+1',
        email: contact.email || '',
        company: contact.company || ''
      }
    });

    // Update UI state
    setSelectedContact(contact);
    setContactSearchTerm(contact.name || '');
    setContactHighlightedIndex(-1);
  };

  const [templateSearchTerm, setTemplateSearchTerm] = useState('');
  const [deviceSearchTerm, setDeviceSearchTerm] = useState('');
  const [isTemplateSearchFocused, setIsTemplateSearchFocused] = useState(false);
  const [isDeviceSearchFocused, setIsDeviceSearchFocused] = useState(false);
  const debouncedTemplateSearch = useDebounce(templateSearchTerm, 300);
  const debouncedDeviceSearch = useDebounce(deviceSearchTerm, 300);

  const { 
    data: templatesResponse,
    isLoading: templatesLoading,
    isFetching: templatesFetching,
    error: templatesError 
  } = useShipmentTemplates(debouncedTemplateSearch);

  const {
    data: devicesList,
    isLoading: devicesLoading,
    isFetching: devicesFetching
  } = useCollectionDevices(debouncedDeviceSearch);

  console.log('Devices Response:', {
    data: devicesList,
    loading: devicesLoading,
    fetching: devicesFetching,
    searchTerm: debouncedDeviceSearch,
    rawDevices: devicesList
  });

  const filteredTemplates = useMemo(() => {
    // Add debugging to understand the response structure
    console.log('🔍 Templates Response:', {
      raw: templatesResponse,
      type: typeof templatesResponse,
      hasInbound: templatesResponse?.inboundData,
      hasOutbound: templatesResponse?.outboundData
    });

    // Early return if no response
    if (!templatesResponse) return [];
    
    // Safely handle the data structure
    const inboundTemplates = Array.isArray(templatesResponse.inboundData) 
      ? templatesResponse.inboundData 
      : [];
      
    const outboundTemplates = Array.isArray(templatesResponse.outboundData) 
      ? templatesResponse.outboundData 
      : [];

    // Combine the templates
    const templates = [...inboundTemplates, ...outboundTemplates];
    
    console.log('📦 Processed templates:', {
      inbound: inboundTemplates.length,
      outbound: outboundTemplates.length,
      total: templates.length
    });

    if (!debouncedTemplateSearch) {
      return templates.slice(0, 5);
    }
    
    const searchTerm = debouncedTemplateSearch.toLowerCase();
    return templates.filter((template) => 
      template.id?.toLowerCase().includes(searchTerm) ||
      template.status?.toLowerCase().includes(searchTerm)
    );
  }, [templatesResponse, debouncedTemplateSearch]);

  const filteredDevices = useMemo(() => {
    const devices = (devicesList || []) as Device[];
    if (!debouncedDeviceSearch) {
      return devices.slice(0, 5); // Show first 5 when no search
    }
    
    const searchTerm = debouncedDeviceSearch.toLowerCase();
    return devices.filter((device: Device) => 
      device.device_name.toLowerCase().includes(searchTerm)
    );
  }, [devicesList, debouncedDeviceSearch]);

  const handleShipmentSelect = (shipmentId: string) => {
    setSelectedShipment(shipmentId);
    console.log('📦 Shipment selected:', shipmentId);
    // Add any additional logic needed when a shipment is selected
  };

  const handleDeviceChange = (index: number, field: string, value: string | number) => {
    const updatedDevices = [...collectionDevices];
    updatedDevices[index] = {
      ...updatedDevices[index],
      [field]: value
    };
    setCollectionDevices(updatedDevices);
    
    // Update form data with new collection devices
    updateFormData({
      collectionDevices: updatedDevices
    });
  };

  const [returnToOrigin, setReturnToOrigin] = useState(false);

  const handleReturnToOriginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setReturnToOrigin(isChecked);
    
    if (isChecked && outboundShipment?.address) {
      // Set return address to the outbound shipment address instead of origin address
      setReturnShipment({
        ...returnShipment,
        address: outboundShipment.address
      });
      setSelectedAddress(outboundShipment.address);
    } else {
      // Clear only the address when unchecking
      setReturnShipment({
        ...returnShipment,
        address: null
      });
      setSelectedAddress(null);
    }
  };

  // Update the dimension change handler
  const handleDimensionsChange = (dimension: keyof typeof dimensions, value: string) => {
    setDimensions(prev => ({
      ...prev,
      [dimension]: value
    }));
  };

  // Update the weight change handler
  const handleWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setWeight(value);
    }
  };

  const [kitSearchTerm, setKitSearchTerm] = useState('');
  const [isKitSearchFocused, setIsKitSearchFocused] = useState(false);
  const [selectedKit, setSelectedKit] = useState<Kit | null>(null);
  const debouncedKitSearch = useDebounce(kitSearchTerm, 300);

  const { data: kitsData, isLoading: kitsLoading } = useKits();

  const filteredKits = useMemo(() => {
    if (!kitsData?.data?.results) return [];
    
    const kits = kitsData.data.results;
    return kits.slice(0, 5);
  }, [kitsData]);

  const [selectedDevice, setSelectedDevice] = useState<(Device & { quantity?: number | string }) | null>(null);

  const [contentsDeviceSearchTerm, setContentsDeviceSearchTerm] = useState('');
  const [isContentsDeviceSearchFocused, setIsContentsDeviceSearchFocused] = useState(false);
  const debouncedContentsDeviceSearch = useDebounce(contentsDeviceSearchTerm, 300);

  const handleAddDiagnosticTest = () => {
    setDiagnosticTests(prev => [
      ...prev,
      {
        id: String(prev.length + 1),
        testId: '',
        batchNo: '',
        expiryDate: '',
        quantity: '1',
        patientId: '',
        tempLogger: ''
      }
    ]);
  };

  const handleRemoveDiagnosticTest = (testId: string) => {
    setDiagnosticTests(prev => prev.filter(test => test.id !== testId));
  };

  const filteredContentsDevices = useMemo(() => {
    const devices = (devicesList || []) as Device[];
    if (!debouncedContentsDeviceSearch) {
      return devices.slice(0, 5); // Show first 5 when no search
    }
    
    const searchTerm = debouncedContentsDeviceSearch.toLowerCase();
    return devices.filter((device: Device) => {
      const nameMatch = device.device_name.toLowerCase().includes(searchTerm);
      const sampleTypeMatch = device.sample_type?.toLowerCase().includes(searchTerm);
      const deviceIdMatch = device.device_id?.toLowerCase().includes(searchTerm);
      return nameMatch || sampleTypeMatch || deviceIdMatch;
    });
  }, [devicesList, debouncedContentsDeviceSearch]);

  // Update ButtonContainer for email addresses (rename to avoid conflict with the Next button container)
  const EmailButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: absolute;
    right: -40px;
    top: 50%;
    transform: translateY(-50%);
  `;

  // Update CircleButton styling
  const CircleButton = styled.button`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #4285f4;  // Add border
    background: white;
    color: #4285f4;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    line-height: 1;

    &:hover {
      background: #f5f5f5;
    }
  `;

  const SimpleButton = styled.button`
    background: none;
    border: none;
    color: #4285f4;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    line-height: 1;

    &:hover {
      opacity: 0.8;
    }
  `;

  // Update handlers for new fields
  const handleAddressTypeChange = (type: 'residential' | 'clinic' | 'other') => {
    updateFormData({
      isResidential: type === 'residential' ? !localFormData.isResidential : false,
      isClinic: type === 'clinic' ? !localFormData.isClinic : false,
      isOther: type === 'other' ? !localFormData.isOther : false
    });
  };

  const handleEmailNotificationChange = (checked: boolean) => {
    updateFormData({
      emailNotifications: checked,
      notificationEmails: checked ? localFormData.notificationEmails : ['']
    });
    setEmailNotifications(checked);
  };

  const handleEmailAddressChange = (index: number, value: string) => {
    const newEmails = [...localFormData.notificationEmails];
    newEmails[index] = value;
    updateFormData({
      notificationEmails: newEmails
    });
    setEmailAddresses(newEmails);
  };

  const handleDiagnosticTestChange = (index: number, field: string, value: string) => {
    const updatedTests = [...localFormData.diagnosticTests];
    updatedTests[index] = {
      ...updatedTests[index],
      [field]: value
    };
    updateFormData({
      diagnosticTests: updatedTests
    });
    setDiagnosticTests(updatedTests);
  };

  // Update kit selection handler
  const handleKitSelect = (kit: Kit) => {
    setSelectedKit(kit);
    setIsKitSearchFocused(false);
    
    // Update form data with selected kit
    updateFormData({
      selectedKit: kit
    });
  };

  // Update device selection handler
  const handleDeviceSelect = (device: Device) => {
    setContentsDeviceSearchTerm(device.device_name);
    setSelectedDevice(device);
    setIsContentsDeviceSearchFocused(false);
    
    // Update form data with selected device
    updateFormData({
      selectedDevice: device
    });
  };

  // Add handlers for template saving
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [newTemplateId, setNewTemplateId] = useState('');

  const handleTemplateChange = (checked: boolean) => {
    setSaveAsTemplate(checked);
    updateFormData({
      saveAsTemplate: checked,
      newTemplateId: checked ? newTemplateId : ''
    });

    // If template is being saved, update the selected shipment
    if (checked) {
      setSelectedShipment(newTemplateId);
      // Update the outbound shipment with the template ID in metadata
      setOutboundShipment({
        ...outboundShipment,
        metadata: {
          ...outboundShipment.metadata,
          templateId: newTemplateId
        }
      });
    }
  };

  const handleTemplateIdChange = (value: string) => {
    setNewTemplateId(value);
    updateFormData({
      saveAsTemplate: true,
      newTemplateId: value
    });

    // Update the selected shipment as the template ID changes
    setSelectedShipment(value);
    // Update the outbound shipment with the template ID in metadata
    setOutboundShipment({
      ...outboundShipment,
      metadata: {
        ...outboundShipment.metadata,
        templateId: value
      }
    });
  };

  const QuantityLabel = styled(Label)`
    margin-bottom: -7px;  // Increase bottom margin to push label lower
    padding-top: 30px;    // Add top padding to move label down
  `;

  const CheckboxLabel = styled(Label)`
    margin-bottom: 0;
    line-height: 24px;  // Match the height of the checkbox
  `;

  // Add effect to sync diagnostic tests with collection device quantities
  useEffect(() => {
    // Calculate total quantity from all collection devices
    const totalQuantity = collectionDevices.reduce((sum, device) => {
      return sum + (device.quantity || 0);
    }, 0);

    // Update diagnostic tests array to match total quantity
    const updatedTests = Array.from({ length: totalQuantity }, (_, index) => {
      // Preserve existing test data if available
      return diagnosticTests[index] || {
        id: String(index + 1),
        testId: '',
        batchNo: '',
        expiryDate: '',
        quantity: '1',
        patientId: '',
        tempLogger: ''
      };
    });

    setDiagnosticTests(updatedTests);
    
    // Update form data
    updateFormData({
      diagnosticTests: updatedTests
    });
  }, [collectionDevices]);

  // Modify the quantity change handler for collection devices
  const handleDeviceQuantityChange = (index: number, value: string) => {
    // Only allow numbers
    if (/^\d*$/.test(value)) {
      const newDevices = [...collectionDevices];
      newDevices[index] = {
        ...newDevices[index],
        quantity: value === '' ? 0 : parseInt(value)
      };
      setCollectionDevices(newDevices);
      
      // Update form data with new collection devices
      updateFormData({
        collectionDevices: newDevices
      });
    }
  };

  return (
    <PageLayout title="Send Out Kits">
      <Container>
        <ShipmentHeader 
          selectedTrialId={selectedTrialId}
          selectedShipmentId={selectedShipment}
          onTrialSelect={handleTrialSelect}
          onShipmentSelect={handleShipmentSelect}
          trials={trials || []}
          shipments={selectedTrial && shipments 
            ? shipments.outboundData?.filter((s: Shipment) => s.trial_id === selectedTrial) 
            : []
          }
          isLoading={trialsLoading}
          isLoadingShipments={isLoadingShipments}
        />

        {selectedTrial ? (
          <>
            <Section>
              <StyledSubtitle>Deliver To</StyledSubtitle>
              <SearchBar>
                <SearchInput 
                  type="text" 
                  placeholder={addressesLoading ? "Loading addresses..." : "Search Address Book"}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={handleKeyDown}
                  onFocus={() => setIsSearchFocused(true)}
                  onBlur={(e) => {
                    setTimeout(() => setIsSearchFocused(false), 200);
                  }}
                  disabled={addressesLoading}
                />
                {isSearchFocused && (debouncedSearch || !addressesLoading) && (
                  <AddressDropdown>
                    {addressesFetching ? (
                      <LoadingAddressOption>
                        <LoadingText>Searching addresses...</LoadingText>
                      </LoadingAddressOption>
                    ) : filteredAddresses.length > 0 ? (
                      filteredAddresses.map((address: Address, index: number) => (
                        <AddressOption 
                          key={address.id}
                          onClick={() => handleAddressSelect(address)}
                          isHighlighted={index === highlightedIndex}
                        >
                          <div>{address.street}</div>
                          <div>{address.city}, {address.state}</div>
                        </AddressOption>
                      ))
                    ) : (
                      <LoadingAddressOption>
                        <LoadingText>No addresses found</LoadingText>
                      </LoadingAddressOption>
                    )}
                  </AddressDropdown>
                )}
              </SearchBar>

              {selectedAddress && (
                <SelectedAddress>
                  <p>{selectedAddress.street}</p>
                  <p>{`${selectedAddress.city}, ${selectedAddress.state} ${selectedAddress.postal_code}`}</p>
                  <p>{selectedAddress.country}</p>
                </SelectedAddress>
              )}

              <DeliveryForm>
                <FormColumns>
                  <ContactColumn>
                    <SectionHeader>Contact Details</SectionHeader>
                    <FormGroup>
                      <SearchBar>
                        <SearchInput 
                          type="text" 
                          placeholder="Contact Name"
                          value={contactSearchTerm}
                          onChange={(e) => setContactSearchTerm(e.target.value)}
                          onKeyDown={handleContactKeyDown}
                          onFocus={() => {
                            //console.log('🔍 Contact search focused');
                            setIsContactSearchFocused(true);
                          }}
                          onBlur={() => {
                            setTimeout(() => {
                              setIsContactSearchFocused(false);
                            }, 200);
                          }}
                        />
                        {isContactSearchFocused && (
                          <AddressDropdown>
                            {contactsFetching ? (
                              <LoadingAddressOption>
                                <LoadingText>Searching contacts...</LoadingText>
                              </LoadingAddressOption>
                            ) : filteredContacts.length > 0 ? (
                              filteredContacts.map((contact: ContactPerson, index: number) => (
                                <AddressOption 
                                  key={contact.id}
                                  onClick={() => {
                                    handleContactSelect(contact);
                                  }}
                                  isHighlighted={index === contactHighlightedIndex}
                                >
                                  <div>{contact.name}</div>
                                  <div>{contact.phone_extension} {contact.phone_number}</div>
                                </AddressOption>
                              ))
                            ) : (
                              <LoadingAddressOption>
                                <LoadingText>
                                  {debouncedContactSearch ? 'No contacts found' : 'Start typing to search contacts'}
                                </LoadingText>
                              </LoadingAddressOption>
                            )}
                          </AddressDropdown>
                        )}
                      </SearchBar>
                    </FormGroup>
                    <FormGroup>
                      <Input 
                        type="text" 
                        placeholder="Company"
                        value={formData.company}
                        onChange={(e) => updateFormData({ company: e.target.value })}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input type="text" placeholder="Tax ID/EORI Number" />
                    </FormGroup>
                    <FormGroup>
                      <Input 
                        type="tel" 
                        placeholder="Phone Number"
                        value={formData.phoneNumber}
                        onChange={(e) => updateFormData({ phoneNumber: e.target.value })}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input 
                        type="text" 
                        placeholder="Phone Extension" 
                        value={formData.phoneExtension}
                        onChange={(e) => updateFormData({ phoneExtension: e.target.value })}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input 
                        type="email" 
                        placeholder="Email"
                        value={formData.email}
                        onChange={(e) => updateFormData({ email: e.target.value })}
                      />
                    </FormGroup>
                  </ContactColumn>

                  <AddressColumn>
                    <SectionHeader>Address</SectionHeader>
                    <FormGroup>
                      <Input 
                        type="text" 
                        placeholder="Country/Territory" 
                        value={formData.country}
                        onChange={(e) => updateFormData({ country: e.target.value })}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input 
                        type="text" 
                        placeholder="Address Line 1" 
                        value={formData.addressLine1}
                        onChange={(e) => updateFormData({ addressLine1: e.target.value })}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input type="text" placeholder="Address Line 2" />
                    </FormGroup>
                    <ZipCityRow>
                      <FormGroup>
                        <Input 
                          type="text" 
                          placeholder="Zip" 
                          value={formData.zip}
                          onChange={(e) => updateFormData({ zip: e.target.value })}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input 
                          type="text" 
                          placeholder="City" 
                          value={formData.city}
                          onChange={(e) => updateFormData({ city: e.target.value })}
                        />
                      </FormGroup>
                    </ZipCityRow>
                    <FormGroup>
                      <Input 
                        type="text" 
                        placeholder="State/District" 
                        value={formData.state}
                        onChange={(e) => updateFormData({ state: e.target.value })}
                      />
                    </FormGroup>
                    <AddressTypeGroup>
                      <CheckboxContainer>
                        <CheckboxGroup>
                          <StyledCheckbox type="checkbox" id="residential" />
                          <CheckboxLabel htmlFor="residential">Residential</CheckboxLabel>
                        </CheckboxGroup>
                        
                        <CheckboxGroup>
                          <StyledCheckbox type="checkbox" id="clinic" />
                          <CheckboxLabel htmlFor="clinic">Clinic/Hospital</CheckboxLabel>
                        </CheckboxGroup>
                      </CheckboxContainer>

                      <CheckboxGroup>
                        <StyledCheckbox type="checkbox" id="other" />
                        <CheckboxLabel htmlFor="other">Other</CheckboxLabel>
                        <DescriptionInput type="text" placeholder="Description" />
                      </CheckboxGroup>
                    </AddressTypeGroup>
                  </AddressColumn>
                </FormColumns>

                <OptionsRow>
                  <OptionGroup>
                    <CheckboxContainer>
                      <StyledCheckbox 
                        type="checkbox" 
                        id="saveAddress"
                        checked={saveNewAddress}
                        onChange={(e) => {
                          setSaveNewAddress(e.target.checked);
                          if (e.target.checked) {
                            // Check for missing fields when checkbox is checked
                            const missingFields = [];
                            if (!formData.addressLine1) missingFields.push('Address Line 1');
                            if (!formData.city) missingFields.push('City');
                            if (!formData.state) missingFields.push('State');
                            if (!formData.zip) missingFields.push('ZIP Code');
                            if (!formData.country) missingFields.push('Country');
                            
                            if (missingFields.length > 0) {
                              setStatusMessage({
                                text: `Please add: ${missingFields.join(', ')}`,
                                type: 'warning'
                              });
                            }
                          }
                        }}
                        disabled={isAddressFromBook}
                      />
                      <CheckboxLabel htmlFor="saveAddress">
                        Save New Address in Recipient Books
                        {/* {isAddressFromBook && (
                          <small style={{ color: 'gray' }}> (Not available for addresses from address book)</small>
                        )} */}
                      </CheckboxLabel>
                    </CheckboxContainer>
                  </OptionGroup>

                  <OptionGroup>
                    <CheckboxContainer>
                      <StyledCheckbox 
                        type="checkbox" 
                        id="emailNotif"
                        checked={emailNotifications}
                        onChange={(e) => handleEmailNotificationChange(e.target.checked)}
                      />
                      <CheckboxLabel htmlFor="emailNotif">Email Notifications</CheckboxLabel>
                    </CheckboxContainer>
                    {emailNotifications && emailAddresses.map((email, index) => (
                      <InputContainer key={index}>
                        <StyledInput 
                          type="email" 
                          placeholder="Email Address"
                          value={email}
                          onChange={(e) => handleEmailAddressChange(index, e.target.value)}
                        />
                        <EmailButtonContainer>
                          {emailAddresses.length > 1 && (
                            <SimpleButton 
                              onClick={() => {
                                const newEmails = emailAddresses.filter((_, i) => i !== index);
                                setEmailAddresses(newEmails);
                                updateFormData({ notificationEmails: newEmails });
                              }}
                            >
                              ×
                            </SimpleButton>
                          )}
                          {index === emailAddresses.length - 1 && (
                            <SimpleButton 
                              onClick={() => {
                                const newEmails = [...emailAddresses, ''];
                                setEmailAddresses(newEmails);
                                updateFormData({ notificationEmails: newEmails });
                              }}
                            >
                              +
                            </SimpleButton>
                          )}
                        </EmailButtonContainer>
                      </InputContainer>
                    ))}
                  </OptionGroup>
                </OptionsRow>
              </DeliveryForm>
            </Section>

            <Section>
              <Title>Shipment Information</Title>
              
              <FormSection>
                <Label>Template Shipment</Label>
                <SearchBar className="kit-template">
                  <SearchInput
                    type="text"
                    placeholder="Selected Shipment Template"
                    value={selectedShipment || 'No shipment selected'}
                    disabled={true} // Make it read-only since it should reflect the selected shipment
                    style={{ 
                      backgroundColor: '#f5f5f5', // Visual indication that it's not editable
                      cursor: 'default'
                    }}
                  />
                </SearchBar>
                {!selectedShipment && (
                  <InfoText>
                    Please select a shipment from the header above to use as template
                  </InfoText>
                )}
              </FormSection>

              <FormSection>
                <Label>Outbound Template</Label>
                <SearchBar className="kit-template">
                  <SearchInput
                    type="text"
                    placeholder="Search Kit Templates"
                    value={selectedKit?.kit_name || ''}
                    onFocus={() => {
                      setIsKitSearchFocused(true);
                    }}
                    onBlur={() => setTimeout(() => {
                      setIsKitSearchFocused(false);
                    }, 200)}
                    readOnly
                  />
                  {isKitSearchFocused && (
                    <AddressDropdown>
                      {kitsLoading ? (
                        <LoadingAddressOption>
                          <LoadingText>Loading kits...</LoadingText>
                        </LoadingAddressOption>
                      ) : filteredKits.length > 0 ? (
                        filteredKits.map((kit: Kit) => (
                          <AddressOption
                            key={kit.kit_id}
                            onClick={() => handleKitSelect(kit)}
                          >
                            <div>{kit.kit_name}</div>
                            <div>{kit.kit_id}</div>
                          </AddressOption>
                        ))
                      ) : (
                        <LoadingAddressOption>
                          <LoadingText>No kits found</LoadingText>
                        </LoadingAddressOption>
                      )}
                    </AddressDropdown>
                  )}
                </SearchBar>
              </FormSection>

              <DimensionsRow>
                <DimensionsGroup>
                  <Label style={{ width: '200px', marginBottom: '4px' }}>Package Dimensions</Label>
                  <DimensionsInputGroup>
                    <DimensionInput 
                      placeholder="L" 
                      value={dimensions.length}
                      onChange={(e) => handleDimensionsChange('length', e.target.value)}
                    />
                    <DimensionSeparator>×</DimensionSeparator>
                    <DimensionInput 
                      placeholder="W" 
                      value={dimensions.width}
                      onChange={(e) => handleDimensionsChange('width', e.target.value)}
                    />
                    <DimensionSeparator>×</DimensionSeparator>
                    <DimensionInput 
                      placeholder="H" 
                      value={dimensions.height}
                      onChange={(e) => handleDimensionsChange('height', e.target.value)}
                    />
                    <DimensionsUnit>(in inches)</DimensionsUnit>
                  </DimensionsInputGroup>
                </DimensionsGroup>
                <WeightGroup>
                  <Label style={{ width: '200px', marginBottom: '4px' }}>Weight</Label>
                  <WeightInputGroup>
                    <WeightInput 
                      type="text" 
                      placeholder="0.0"
                      value={weight}
                      onChange={handleWeightChange}
                    />
                    <WeightUnit>lbs.</WeightUnit>
                  </WeightInputGroup>
                </WeightGroup>
              </DimensionsRow>
              
              <HorizontalLine />
              
              <div style={{ marginBottom: '24px', fontSize: '16px' }}>Contents</div>

              <FormSection style={{ marginLeft: '36px' }}>
                <Label>Collection Devices</Label>
                {collectionDevices.map((device: CollectionDevice, index: number) => (
                  <DeviceRow key={index}>
                    <DeviceSearchBar isActive={device.isSearchFocused}>
                      <SearchInput
                        type="text"
                        placeholder="Search Devices"
                        value={device.device_name || device.searchTerm || ''} 
                        onChange={(e) => {
                          const newDevices = [...collectionDevices];
                          newDevices[index] = {
                            ...newDevices[index],
                            searchTerm: e.target.value,
                            device_name: ''  // Clear device_name when searching
                          };
                          setCollectionDevices(newDevices);
                        }}
                        onFocus={() => {
                          const newDevices = [...collectionDevices];
                          newDevices[index] = {
                            ...newDevices[index],
                            isSearchFocused: true
                          };
                          setCollectionDevices(newDevices);
                        }}
                        onBlur={() => {
                          // Add delay to allow click event to complete
                          setTimeout(() => {
                            const newDevices = [...collectionDevices];
                            newDevices[index] = {
                              ...newDevices[index],
                              isSearchFocused: false,
                              // Keep the device_name if it exists
                              searchTerm: newDevices[index].device_name || newDevices[index].searchTerm || ''
                            };
                            setCollectionDevices(newDevices);
                          }, 200);
                        }}
                      />
                      {device.isSearchFocused && (
                        <DeviceDropdown>
                          {devicesFetching ? (
                            <LoadingDeviceOption>
                              <LoadingText>Searching devices...</LoadingText>
                            </LoadingDeviceOption>
                          ) : filteredDevices.length > 0 ? (
                            filteredDevices.map((deviceOption: Device) => (
                              <DeviceOption
                                key={deviceOption.id}
                                onMouseDown={(e) => {
                                  // Prevent blur event from firing before click
                                  e.preventDefault();
                                }}
                                onClick={() => {
                                  const newDevices = [...collectionDevices];
                                  newDevices[index] = {
                                    ...newDevices[index],
                                    id: deviceOption.device_id,
                                    device_id: deviceOption.device_id,
                                    device_name: deviceOption.device_name,
                                    searchTerm: deviceOption.device_name,
                                    isSearchFocused: false,
                                    quantity: newDevices[index].quantity || 0,
                                    sample_type: deviceOption.sample_type
                                  };
                                  setCollectionDevices(newDevices);
                                  
                                  // Update form data immediately
                                  updateFormData({ 
                                    collectionDevices: newDevices 
                                  });
                                }}
                              >
                                <div>{deviceOption.device_name}</div>
                                <div>{deviceOption.sample_type}</div>
                              </DeviceOption>
                            ))
                          ) : (
                            <LoadingDeviceOption>
                              <LoadingText>
                                {debouncedDeviceSearch ? 'No matching devices found' : 'Start typing to search devices'}
                              </LoadingText>
                            </LoadingDeviceOption>
                          )}
                        </DeviceDropdown>
                      )}
                    </DeviceSearchBar>
                    <QuantityInput
                      type="text"
                      placeholder=""
                      value={device.quantity === undefined ? '' : device.quantity}
                      onChange={(e) => handleDeviceQuantityChange(index, e.target.value)}
                      style={{ textAlign: 'left', paddingLeft: '12px' }}
                    />
                    <RemoveButton onClick={() => removeCollectionDevice(index)}>
                      ×
                    </RemoveButton>
                  </DeviceRow>
                ))}
                <AddDeviceButton onClick={addCollectionDevice}>
                  + Add Collection Device
                </AddDeviceButton>
              </FormSection>

              <HorizontalLine />
              
              <div style={{ fontWeight: 'bold', marginBottom: '24px' }}>Build a Kit</div>
              
              <div style={{ marginLeft: '36px' }}>
                <Label style={{ marginBottom: '16px' }}>Diagnostic Tests</Label>
                
                <TestGrid>
                  <TestGridHeader>
                    <div style={{ width: '40px' }}></div>
                    <div>Test ID (scan/enter manually)</div>
                    <div>Batch No.</div>
                    <div>Patient ID</div>
                    <div>Temp Logger</div>
                  </TestGridHeader>

                  {diagnosticTests.map((test) => (
                    <TestGridRow key={test.id}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        [{test.id}]
                      </div>
                      <SearchBar style={{ maxWidth: '100%' }}>
                        <SearchInput
                          type="text"
                          placeholder="Value"
                          value={test.testId}
                          onChange={(e) => {
                            const updatedTests = diagnosticTests.map(t => 
                              t.id === test.id ? { ...t, testId: e.target.value } : t
                            );
                            setDiagnosticTests(updatedTests);
                          }}
                        />
                      </SearchBar>
                      <SearchBar style={{ maxWidth: '100%' }}>
                        <SearchInput
                          type="text"
                          placeholder="Value"
                          value={test.batchNo}
                          onChange={(e) => {
                            const updatedTests = diagnosticTests.map(t => 
                              t.id === test.id ? { ...t, batchNo: e.target.value } : t
                            );
                            setDiagnosticTests(updatedTests);
                          }}
                        />
                      </SearchBar>
                      <SearchBar style={{ maxWidth: '100%' }}>
                        <SearchInput
                          type="text"
                          placeholder="Value"
                          value={test.patientId}
                          onChange={(e) => {
                            const updatedTests = diagnosticTests.map(t => 
                              t.id === test.id ? { ...t, patientId: e.target.value } : t
                            );
                            setDiagnosticTests(updatedTests);
                          }}
                        />
                      </SearchBar>
                      <SearchBar style={{ maxWidth: '100%' }}>
                        <SearchInput
                          type="text"
                          placeholder="Fixed/Disposable"
                          value={test.tempLogger}
                          onChange={(e) => {
                            const updatedTests = diagnosticTests.map(t => 
                              t.id === test.id ? { ...t, tempLogger: e.target.value } : t
                            );
                            setDiagnosticTests(updatedTests);
                          }}
                        />
                      </SearchBar>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {diagnosticTests.length > 1 && (
                          <SimpleButton 
                            onClick={() => handleRemoveDiagnosticTest(test.id)}
                          >
                            ×
                          </SimpleButton>
                        )}
                      </div>
                    </TestGridRow>
                  ))}
                </TestGrid>

                <AddKitButton 
                  style={{ marginTop: '16px' }}
                  onClick={handleAddDiagnosticTest}
                >
                  <AddIcon>+</AddIcon>
                  Include Another Kit
                </AddKitButton>
              </div>

              <HorizontalLine />


              <CheckboxContainer>
                <StyledCheckbox 
                  type="checkbox" 
                  id="saveTemplate"
                  checked={saveAsTemplate}
                  onChange={(e) => handleTemplateChange(e.target.checked)}
                />
                <Label htmlFor="saveTemplate">Save New Kit Template</Label>
              </CheckboxContainer>
              {saveAsTemplate && (
                <TemplateDetailsRow>
                  <TemplateInput 
                    type="text" 
                    placeholder="Template ID"
                    value={newTemplateId}
                    onChange={(e) => handleTemplateIdChange(e.target.value)}
                  />
                </TemplateDetailsRow>
              )}
            </Section>

            <ButtonContainer>
              <NextButton 
                onClick={handleNext}
              >
                Next
              </NextButton>
            </ButtonContainer>
          </>
        ) : (
          <EmptyState>
            {trialsLoading ? 'Loading trials...' : 'Please select a trial to continue'}
          </EmptyState>
        )}
      </Container>
    </PageLayout>
  );
};

const colors = {
  primary: '#007AFF',
  border: '#E0E0E0',
  text: {
    primary: '#333333',
    secondary: '#666666',
    placeholder: '#999999'
  }
} as const;

const spacing = {
xs: '0.5rem',    // 8px
sm: '1rem',      // 16px
md: '1.5rem',    // 24px
lg: '2rem',      // 32px
xl: '2.5rem',    // 40px
} as const;
const EmptyState = styled.div`
  text-align: center;
  padding: ${spacing.lg};
  color: ${colors.text.secondary};
`;

const Container = styled.div`
  max-width: 50rem;  // Increased from 50rem to 64rem (1024px)
  margin: 0 auto;
  padding: ${spacing.md};
  padding-bottom: 80px;  // Add padding to account for fixed Next button
`;

const Section = styled.div`
  margin-bottom: ${spacing.xl};
  width: 100%;  // Added to ensure full width

  &:not(:first-of-type) {
    margin-top: ${spacing.xl};
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spacing.md};
`;

const SelectGroup = styled.div`
  margin-bottom: ${spacing.md};
`;

const FormGroup = styled.div`
  margin-bottom: ${spacing.sm};
  width: 100%;  // Added to ensure full width
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  background-color: white;
  cursor: pointer;
  padding-right: 32px;
  background-position: right 12px center;

  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: ${spacing.sm};
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  font-size: 1rem;
  box-sizing: border-box;  // Added to ensure padding is included in width

  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }
`;

const DeliveryForm = styled.div`
  margin-top: ${spacing.sm};
`;

const ProductForm = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: ${spacing.md};
`;

const SearchBar = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  max-width: 100%;
  z-index: 1;  // Lower default z-index

  // When the dropdown is active, raise the z-index to the highest level
  &:focus-within {
    z-index: 9999;  // Very high z-index when focused/dropdown is shown
  }
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #E0E0E0;
  border-radius: 12px;
  font-size: 1rem;
  background-color: white;
  box-sizing: border-box;  // Added to ensure padding is included in width

  &:focus {
    outline: none;
    border-color: #000;
  }
`;

const FormColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spacing.xl};  // Increased from md to xl
  margin-top: ${spacing.sm};
`;

const ContactColumn = styled.div`
  width: 100%;  // Added to ensure full width
`;

const AddressColumn = styled.div`
  width: 100%;  // Added to ensure full width
`;

const ZipCityRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: ${spacing.sm};
`;

const AddressTypeGroup = styled.div`
  margin-top: ${spacing.sm};
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.sm};
  margin-bottom: ${spacing.sm};
`;

const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.xs};
  margin-bottom: ${spacing.xs};
`;

const CheckboxLabel = styled(Label)`
  margin-bottom: 0;
  padding-top: 4px;  // Add slight padding to move label down and center with checkbox
  line-height: 24px;  // Match the height of the checkbox
`;

const StyledCheckbox = styled.input`
  appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid #E0E0E0;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  margin: 0;

  &:checked {
    background-color: #007AFF;
    border-color: #007AFF;
  }

  &:checked::after {
    content: '';
    position: absolute;
    left: 8px;
    top: 4px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const DescriptionInput = styled(Input)`
  width: 200px;
  margin-left: 8px;
`;

const AddButton = styled.button`
  position: absolute;
  right: -40px;
  top: 50%;
  transform: translateY(-50%);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  background: #007AFF;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  line-height: 1;

  &:hover {
    background: #0056b3;
  }
`;

const OptionsRow = styled.div`
  margin-top: 24px;
  border-top: 1px solid #E0E0E0;
  padding-top: 24px;
  display: flex;
  gap: 48px;
`;

const OptionGroup = styled.div`
  flex: 1;
`;

const InputContainer = styled.div`
  position: relative;
  margin-left: 36px;
  margin-bottom: 12px;
  width: 100%;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 2px solid #E0E0E0;
  border-radius: 8px;
  font-size: 16px;
  color: #333;

  &::placeholder {
    color: #999;
  }

  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: fixed;  // Change from sticky to fixed
  bottom: 24px;
  right: 24px;
  background: white;
  padding: ${spacing.sm};
  z-index: 100;
`;

const CircleButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #4285f4;  // Add border
  background: white;
  color: #4285f4;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  line-height: 1;

  &:hover {
    background: #f5f5f5;
  }
`;

const DimensionsContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 48px;
  align-items: flex-start;
`;

const DimensionsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: -10px;  // Reduced from default spacing
`;

const DimensionInput = styled.input`
  width: 60px;
  height: 40px;
  padding: 8px;
  border: 2px solid #E0E0E0;
  border-radius: 8px;
  text-align: center;
`;

const MultiplierText = styled.span`
  color: #666;
  font-size: 16px;
`;

const UnitText = styled.span`
  color: #666;
  margin-left: 8px;
`;

const WeightGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: -10px;  // Reduced from default spacing
`;

const WeightInput = styled.input`
  width: 100px;
  height: 40px;
  padding: 8px;
  border: 2px solid #E0E0E0;
  border-radius: 8px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.md};
  margin-left: ${spacing.md};
  margin-top: ${spacing.sm};
`;

const ContentRow = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
  gap: 12px;
  align-items: flex-end;
  margin-bottom: 24px;
`;

const QuantityInput = styled.input`
  width: 120px;
  padding: 0.75rem;
  border: 1px solid #E0E0E0;
  border-radius: 12px;
  font-size: 1rem;
  background-color: white;

  &:focus {
    outline: none;
    border-color: #000;
  }
`;

const FormSection = styled.div`
  margin-bottom: 24px;
  width: 100%;
  max-width: 800px;  // Make both sections the same width
`;

const TestsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.md};
  margin-top: ${spacing.sm};
`;

const TestRow = styled.div`
  display: grid;
  grid-template-columns: 40px 2fr 1fr 2fr 2fr 40px;
  gap: 16px;
  align-items: center;

  > div:first-of-type {
    color: ${colors.text.secondary};
    font-size: 14px;
    padding-left: 8px;
  }
`;

const RowNumber = styled.div`
  color: ${colors.text.secondary};
  font-size: 1rem;
  padding-bottom: ${spacing.sm};
`;

const BatchInput = styled(StyledInput)`
  width: 100%;
`;

const AddKitButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${spacing.xs};
  color: ${colors.primary};
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  padding: ${spacing.xs} 0;
  margin-top: ${spacing.xs};

  &:hover {
    opacity: 0.8;
  }
`;

const AddIcon = styled.span`
  font-size: 20px;
  line-height: 1;
`;

const TestIdLabel = styled(Label)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TempLoggerSelect = styled(StyledSelect)`
  min-width: 200px;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: #4285f4;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  height: 42px;  // Match the height of inputs
  margin-top: 0;  // Remove any top margin
`;

const TemplateInput = styled(StyledInput)`
  width: 18.75rem;  // 300px
  margin-left: ${spacing.xl};
  margin-top: ${spacing.sm};
`;

const BatchLabel = styled(Label)`
  white-space: nowrap;
`;

const BaseSelectStyles = `
  padding: ${spacing.sm};
  border: 0.125rem solid ${colors.border};
  border-radius: 0.5rem;
  font-size: 1rem;
  color: ${colors.text.primary};
  background-color: white;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right ${spacing.sm} center;
  background-size: 1rem;
  cursor: pointer;
`;

const ShipmentTypeSelect = styled.select`
  ${BaseSelectStyles}
  width: 100%;
  margin-bottom: 32px;
`;

const SelectionRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spacing.sm};
  margin-bottom: ${spacing.lg};
`;

const SelectLabel = styled.label`
  font-size: 1rem;
  color: ${colors.text.primary};
  font-weight: 500;
`;

const DeviceSearchBar = styled.div<{ isActive?: boolean }>`
  position: relative;
  flex: 1;
  width: 100%;
  max-width: 100%;
  z-index: ${props => props.isActive ? 100 : 1};
`;

const DeviceDropdown = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background: white;
  border: 1px solid ${colors.border};
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const DeviceOption = styled.div<{ isHighlighted?: boolean }>`
  padding: ${spacing.sm};
  cursor: pointer;
  background: ${props => props.isHighlighted ? '#f5f5f5' : 'transparent'};
  
  &:hover {
    background: #f5f5f5;
  }
  
  div:first-of-type {
    font-weight: 500;
  }
  
  div:last-of-type {
    font-size: 0.875rem;
    color: ${colors.text.secondary};
  }
`;

const LoadingDeviceOption = styled.div`
  padding: ${spacing.sm};
  color: ${colors.text.secondary};
  text-align: center;
`;

const DeviceRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  width: 100%;
`;

const DeviceSelect = styled(StyledSelect)`
  // Existing select styles
`;

const AddDeviceButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${colors.primary};
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 8px 0;

  &:hover {
    opacity: 0.8;
  }
`;

const RemoveDeviceButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  background: ${colors.primary};
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  line-height: 1;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledSubtitle = styled(Subtitle)`
  margin-bottom: ${spacing.xs};
`;

const SectionHeader = styled(Subtitle)`
  font-size: 1rem;
  margin-bottom: ${spacing.xs};
`;

const ErrorMessage = styled.p`
  color: #ff0000;
  font-size: 0.875rem;
  margin-top: ${spacing.xs};
`;

const SelectedAddress = styled.div`
  padding: ${spacing.sm};
  border: 1px solid ${colors.border};
  border-radius: 0.5rem;
  margin-top: ${spacing.sm};
  
  p {
    margin: 0;
    line-height: 1.5;
  }
`;

const AddressDropdown = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background: white;
  border: 1px solid ${colors.border};
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 9999;  // Always keep dropdown at highest level
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-top: 0;
`;

const AddressOption = styled.div<{ isHighlighted?: boolean }>`
  padding: ${spacing.sm};
  cursor: pointer;
  background: ${props => props.isHighlighted ? '#f5f5f5' : 'transparent'};
  
  &:hover {
    background: #f5f5f5;
  }
  
  div:first-of-type {
    font-weight: 500;
  }
  
  div:last-of-type {
    font-size: 0.875rem;
    color: ${colors.text.secondary};
  }
`;

const LoadingAddressOption = styled.div`
  padding: ${spacing.sm};
`;

const StyledNextButton = styled(NextButton)`
  // ... existing styles ...
  onClick: () => {
    console.log('Next button clicked');
    handleSubmit();
  }
`;

const LoadingText = styled.div`
  color: ${colors.text.secondary};
  text-align: center;
  padding: ${spacing.sm};
`;

const MessageContainer = styled.div<{ type: 'error' | 'success' | 'warning' }>`
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  background-color: ${props => 
    props.type === 'error' ? '#ffebee' :
    props.type === 'success' ? '#e8f5e9' :
    '#fff3e0'
  };
  color: ${props => 
    props.type === 'error' ? '#c62828' :
    props.type === 'success' ? '#2e7d32' :
    '#ef6c00'
  };
  border: 1px solid ${props => 
    props.type === 'error' ? '#ef9a9a' :
    props.type === 'success' ? '#a5d6a7' :
    '#ffcc80'
  };
`;

const InfoText = styled.div`
  color: ${colors.text.secondary};
  font-size: 0.875rem;
  margin-top: 0.5rem;
  font-style: italic;
`;

const PhoneInputGroup = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ExtensionInput = styled.input`
  width: 60px;
  padding: 8px;
  border: 1px solid ${colors.border};
  border-radius: 4px;
  font-size: 16px;
`;

const PhoneInput = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid ${colors.border};
  border-radius: 4px;
  font-size: 16px;
`;

const TemplateDetailsRow = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 32px;
  margin-left: 36px;
  margin-top: 12px;
`;

const DimensionSeparator = styled.span`
  color: #666;
`;

const DimensionsUnit = styled.span`
  color: #666;
  margin-left: 8px;
`;

const DimensionsInputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 0;
`;

const WeightInputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 0;
`;

const WeightUnit = styled.span`
  color: #666;
`;

const HorizontalLine = styled.hr`
  border: none;
  border-top: 1px solid #E0E0E0;
  margin: ${spacing.md} 0;
`;

const DimensionsRow = styled.div`
display: flex;
justify-content: flex-start;
align-items: flex-start;
gap: 48px;
margin-bottom: 24px;
`;


const TestGrid = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  `;

  const TestGridHeader = styled.div`
    display: grid;
    grid-template-columns: 40px 1fr 1fr 1fr 1fr 40px;
    gap: 16px;
    margin-bottom: 16px;
    color: ${colors.text.secondary};
    font-size: 14px;

    > div {
      padding: 0 8px;
    }
  `;

  const TestGridRow = styled.div`
    display: grid;
    grid-template-columns: 40px 1fr 1fr 1fr 1fr 40px;
    gap: 16px;
    align-items: center;

    > div:first-of-type {
      color: ${colors.text.secondary};
      font-size: 14px;
      padding-left: 8px;
    }
  `;

export default ShipmentSetup;