import React from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../components/common/Layout/PageLayout';

const WorkflowSelection: React.FC = () => {
  const navigate = useNavigate();

  const workflows = [
    {
      id: 'create-trial',
      title: 'Create a Trial',
      description: 'Set up a new clinical trial',
      icon: '🔬',
      path: '/create-trial'
    },
    {
      id: 'study-overview',
      title: 'Trials Overview',
      description: 'View comprehensive study details, inventory levels, and site information',
      icon: '📋',
      path: '/trials-overview'
    },
    {
      id: 'watchlist',
      title: 'Watchlist',
      description: 'Monitor your starred shipments and sites',
      icon: '⭐',
      path: '/watchlist'
    },
    {
      id: 'send-kits',
      title: 'Send Out Kits',
      description: 'Prepare and send kits to sites',
      icon: '🛩️',
      path: '/send-kits'
    },
    {
      id: 'receive-samples',
      title: 'Receive Samples',
      description: 'Process and track incoming sample shipments',
      icon: '📥',
      path: '/receive-samples'
    },
    {
      id: 'inventory-levels',
      title: 'Inventory Levels',
      description: 'Monitor and manage inventory levels across all sites and studies',
      icon: '📊',
      path: '/inventory-levels'
    },
    {
      id: 'manage-inventory',
      title: 'Shipment Manager',
      description: 'Track and manage your kit and device shipments',
      icon: '🗂️',
      path: '/shipment-manager'
    },
    {
      id: 'schedule-pickup',
      title: 'Schedule Pickup',
      description: 'Schedule pickups for your shipments',
      icon: '📅',
      path: '/schedule-pickup'
    },
    {
      id: 'sites',
      title: 'View Sites',
      description: 'View and manage individual site details, inventory, and shipment history',
      icon: '🏥',
      path: '/sites'
    },
    {
      id: 'kit-overview',
      title: 'Kit Overview',
      description: 'View and manage kit details, inventory levels, and shipment history',
      icon: '📦',
      path: '/kit-overview'
    }
  ];

  return (
    <PageLayout title="Welcome to Odyssey" isLandingPage>
      <SubtitleDescription>
        Select a workflow to begin
      </SubtitleDescription>

      <WorkflowGrid>
        {workflows.map((workflow) => (
          <WorkflowCard
            key={workflow.id}
            onClick={() => navigate(workflow.path)}
          >
            <WorkflowIcon>{workflow.icon}</WorkflowIcon>
            <WorkflowTitle>{workflow.title}</WorkflowTitle>
            <WorkflowDescription>
              {workflow.description}
            </WorkflowDescription>
          </WorkflowCard>
        ))}
      </WorkflowGrid>
    </PageLayout>
  );
};

const SubtitleDescription = styled.p`
  color: #666;
  margin-top: 8px;
  margin-bottom: 32px;
  font-size: 16px;
`;

const WorkflowGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  margin: 0 auto;
  max-width: 1200px;
`;

const WorkflowCard = styled.div`
  background: white;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding: 24px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-color: #000;
  }
`;

const WorkflowIcon = styled.div`
  font-size: 32px;
  margin-bottom: 16px;
`;

const WorkflowTitle = styled.h3`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const WorkflowDescription = styled.p`
  color: #666;
  font-size: 14px;
  line-height: 1.4;
`;

export default WorkflowSelection; 