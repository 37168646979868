import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import PageLayout from '../../components/common/Layout/PageLayout';

interface Shipment {
  trackingNumber: string;
  origin: string;
  destination: string;
  site: string;
  study: string;
  status: 'Cancelled' | 'Delayed' | 'On Time';
  expectedArrival: string;
  expectedArrivalDate?: Date;
  sampleIds: string[];
  type: 'inbound' | 'outbound';
}

const getStatusColor = (status: string): string => {
  switch (status) {
    case 'Cancelled':
      return '#FF0000';
    case 'On Time':
      return '#00A651';
    case 'Delayed':
      return '#FFA500';
    default:
      return '#666666';
  }
};

const StudyShipmentDetails: React.FC = () => {
  const { trackingNumber } = useParams();
  const navigate = useNavigate();

  // Mock data - in a real app, this would come from context or props
  const mockShipments: Shipment[] = [
    {
      trackingNumber: '788208439645',
      origin: 'SiteID-000',
      destination: '',
      site: 'EVGR-A-001',
      study: 'EVGR-A-001',
      status: 'Cancelled',
      expectedArrival: 'Unknown',
      expectedArrivalDate: undefined,
      sampleIds: ['9946', '9947'],
      type: 'inbound'
    },
    {
      trackingNumber: '788208439645',
      origin: 'SiteID-001',
      destination: '',
      site: 'EVGR-A-001',
      study: 'EVGR-A-001',
      status: 'Delayed',
      expectedArrival: '10/2/24 10:30am',
      expectedArrivalDate: new Date(2024, 9, 2, 10, 30),
      sampleIds: [],
      type: 'inbound'
    }
  ];

  const shipment = mockShipments.find(s => s.trackingNumber === trackingNumber);

  if (!shipment) {
    return (
      <PageLayout>
        <Container>
          <HeaderContainer>
            <Title>Shipment Not Found</Title>
          </HeaderContainer>
          <EmptyState>The requested shipment could not be found.</EmptyState>
        </Container>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <Container>
        <HeaderContainer>
          <Title>Shipment Details</Title>
          <ActionButtonsContainer>
            <ActionButton onClick={() => navigate('/trials-overview')}>
              Back to Study Overview
            </ActionButton>
          </ActionButtonsContainer>
        </HeaderContainer>

        <ShipmentCard>
          <ShipmentHeader>
            <div>
              <ShipmentType>{shipment.type === 'inbound' ? 'Inbound' : 'Outbound'} Shipment</ShipmentType>
              <TrackingNumber>Tracking #: {shipment.trackingNumber}</TrackingNumber>
            </div>
            <StatusBadge color={getStatusColor(shipment.status)}>
              {shipment.status}
            </StatusBadge>
          </ShipmentHeader>

          <DetailGrid>
            <DetailSection>
              <SectionTitle>Shipment Information</SectionTitle>
              <DetailItem>
                <Label>Study:</Label>
                <Value>{shipment.study}</Value>
              </DetailItem>
              <DetailItem>
                <Label>Site:</Label>
                <Value>{shipment.site}</Value>
              </DetailItem>
              <DetailItem>
                <Label>Expected Arrival:</Label>
                <Value>{shipment.expectedArrival}</Value>
              </DetailItem>
            </DetailSection>

            <DetailSection>
              <SectionTitle>Route Information</SectionTitle>
              <DetailItem>
                <Label>Origin:</Label>
                <Value>{shipment.origin || '-'}</Value>
              </DetailItem>
              <DetailItem>
                <Label>Destination:</Label>
                <Value>{shipment.destination || '-'}</Value>
              </DetailItem>
            </DetailSection>

            {shipment.sampleIds && shipment.sampleIds.length > 0 && (
              <DetailSection>
                <SectionTitle>Sample Information</SectionTitle>
                <DetailItem>
                  <Label>Sample IDs:</Label>
                  <Value>{shipment.sampleIds.join(', ')}</Value>
                </DetailItem>
              </DetailSection>
            )}
          </DetailGrid>
        </ShipmentCard>
      </Container>
    </PageLayout>
  );
};

const Container = styled.div`
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin: 0;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 48px;
  color: #666;
`;

const ShipmentCard = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid #E0E0E0;
`;

const ShipmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #E0E0E0;
`;

const ShipmentType = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const TrackingNumber = styled.div`
  color: #666;
`;

const StatusBadge = styled.div<{ color: string }>`
  color: ${props => props.color};
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid ${props => props.color};
`;

const DetailGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
`;

const DetailSection = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 16px 0;
  color: #333;
`;

const DetailItem = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: baseline;
`;

const Label = styled.span`
  font-weight: 500;
  width: 120px;
  flex-shrink: 0;
`;

const Value = styled.span`
  color: #666;
`;

export default StudyShipmentDetails;
