export interface ShippingService {
  name: string;
  deliveryTime: string;
  price: string;
}

export interface ShippingOptions {
  requireSignature: boolean;
  holdAtLocation: boolean;
  locationZip?: string;
  emailLabel?: boolean;
  emailAddress?: string;
}

export const SHIPPING_SERVICES: ShippingService[] = [
  {
    name: 'FedEx First Overnight',
    deliveryTime: 'Delivers by 9:30am NBD',
    price: '$89.99'
  },
  {
    name: 'FedEx Priority Overnight',
    deliveryTime: 'Delivers by 10:30am NBD',
    price: '$54.43'
  },
  {
    name: 'FedEx Standard Overnight',
    deliveryTime: 'Delivers by 3:00pm NBD',
    price: '$44.99'
  },
  {
    name: 'FedEx 2Day AM',
    deliveryTime: 'Delivers by 10:30am N(2)BD',
    price: '$34.99'
  },
  {
    name: 'FedEx 2Day',
    deliveryTime: 'Delivers by 4:30pm N(2)BD',
    price: '$24.99'
  },
  {
    name: 'FedEx Express Saver',
    deliveryTime: 'Delivers by 4:30pm N(3)BD',
    price: '$19.99'
  }
]; 