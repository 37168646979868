import React from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import Select from '../common/Select/Select';
import { Shipment, ShipmentStatus } from '../../types/models/shipment';

interface HistoricalShipmentsTableProps {
  inboundShipments: Shipment[];
  outboundShipments: Shipment[];
  starredShipments: string[];
  onToggleStar: (id: string) => void;
  studyFilter: string;
  statusFilter: string;
  timeFilter: string;
  onStudyFilterChange: (value: string) => void;
  onStatusFilterChange: (value: string) => void;
  onTimeFilterChange: (value: string) => void;
  onSeeAllClick: () => void;
  currentSiteId: string;
}

const getStatusColor = (status: string): string => {
  switch (status) {
    case 'Cancelled':
      return '#FF0000';
    case 'On Time':
      return '#00A651';
    case 'Delayed':
      return '#FFA500';
    default:
      return '#666666';
  }
};

const getStatusText = (status: ShipmentStatus): string => {
  switch (status) {
    case ShipmentStatus.CANCELLED:
      return 'Cancelled';
    case ShipmentStatus.IN_TRANSIT:
      return 'On Time';
    case ShipmentStatus.DELIVERED:
      return 'Delivered';
    case ShipmentStatus.PENDING:
      return 'Delayed';
    case ShipmentStatus.FAILED_PICKUP:
      return 'Failed Pickup';
    default:
      return status;
  }
};

const HistoricalShipmentsTable: React.FC<HistoricalShipmentsTableProps> = ({
  inboundShipments,
  outboundShipments,
  starredShipments,
  onToggleStar,
  studyFilter,
  statusFilter,
  timeFilter,
  onStudyFilterChange,
  onStatusFilterChange,
  onTimeFilterChange,
  onSeeAllClick,
  currentSiteId
}) => {
  const navigate = useNavigate();

  const handleTrackingClick = (trackingNumber: string) => {
    navigate(`/sites/${currentSiteId}/shipments/${trackingNumber}`);
  };

  // Helper function to generate unique row ID
  const getRowId = (shipment: Shipment, type: string) => {
    return `${type}-${shipment.tracking_number}`;
  };

  // Filter shipments based on selected filters
  const filterShipments = (shipments: Shipment[], type: 'inbound' | 'outbound') => {
    // Sort shipments to put starred items first
    const sortedShipments = [...shipments].sort((a, b) => {
      const aIsStarred = starredShipments.includes(getRowId(a, type));
      const bIsStarred = starredShipments.includes(getRowId(b, type));
      if (aIsStarred && !bIsStarred) return -1;
      if (!aIsStarred && bIsStarred) return 1;
      return 0;
    });

    return sortedShipments.filter(shipment => {
      // Always show starred items
      if (starredShipments.includes(getRowId(shipment, type))) {
        return true;
      }

      const matchesStudy = studyFilter === 'all' || shipment.trial_id === studyFilter;
      const statusText = getStatusText(shipment.status).toLowerCase();
      const matchesStatus = statusFilter === 'all' || statusText === statusFilter;
      // Time filter can be implemented here if needed
      return matchesStudy && matchesStatus;
    });
  };

  const filteredInboundShipments = filterShipments(inboundShipments, 'inbound');
  const filteredOutboundShipments = filterShipments(outboundShipments, 'outbound');

  // Helper function to reset all filters
  const handleSeeAllClick = () => {
    onStudyFilterChange('all');
    onStatusFilterChange('all');
    onTimeFilterChange('all');
  };

  const renderShipmentRow = (shipment: Shipment) => (
    <TableRow key={shipment.id}>
      <TableCell>
        <TrackingLink onClick={() => handleTrackingClick(shipment.tracking_number)}>
          {shipment.tracking_number}
        </TrackingLink>
      </TableCell>
      <TableCell>{shipment.origin}</TableCell>
      <TableCell>{shipment.destination}</TableCell>
      <TableCell>{shipment.trial_id}</TableCell>
      <TableCell>
        <StatusBadge status={getStatusText(shipment.status)}>
          {getStatusText(shipment.status)}
        </StatusBadge>
      </TableCell>
      <TableCell>{shipment.expected_delivery}</TableCell>
      <TableCell>
        <StarButton
          isStarred={starredShipments.includes(getRowId(shipment, shipment.type))}
          onClick={() => onToggleStar(getRowId(shipment, shipment.type))}
        >
          ★
        </StarButton>
      </TableCell>
    </TableRow>
  );

  return (
    <Container>
      <Header>
        <Title>Historical Shipments</Title>
        <FilterContainer>
          <FilterGroup>
            <FilterLabel>Study</FilterLabel>
            <Select
              placeholder="Study"
              value={studyFilter}
              onChange={onStudyFilterChange}
              options={[
                { value: 'all', label: 'All Studies' },
                { value: 'EVGR-A-001', label: 'EVGR-A-001' }
              ]}
            />
          </FilterGroup>
          <FilterGroup>
            <FilterLabel>Status</FilterLabel>
            <Select
              placeholder="Status"
              value={statusFilter}
              onChange={onStatusFilterChange}
              options={[
                { value: 'all', label: 'All Statuses' },
                { value: 'on time', label: 'On Time' },
                { value: 'delayed', label: 'Delayed' },
                { value: 'cancelled', label: 'Cancelled' }
              ]}
            />
          </FilterGroup>
          <FilterGroup>
            <FilterLabel>Sample Creation Time</FilterLabel>
            <Select
              placeholder="Sample Creation Time"
              value={timeFilter}
              onChange={onTimeFilterChange}
              options={[
                { value: 'all', label: 'All Times' },
                { value: 'today', label: 'Today' },
                { value: 'this_week', label: 'This Week' },
                { value: 'this_month', label: 'This Month' }
              ]}
            />
          </FilterGroup>
          <SeeAllButton onClick={handleSeeAllClick}>
            See All
          </SeeAllButton>
        </FilterContainer>
      </Header>

      <Section>
        <SectionTitle>Inbound</SectionTitle>
        <Table>
          <thead>
            <tr>
              <Th>Tracking #</Th>
              <Th>Origin</Th>
              <Th>Study</Th>
              <Th>Status</Th>
              <Th>Expected Arrival</Th>
              <Th>Sample Id[s]</Th>
              <Th></Th>
            </tr>
          </thead>
          <tbody>
            {filteredInboundShipments.map(shipment => renderShipmentRow(shipment))}
          </tbody>
        </Table>
      </Section>

      <Section>
        <SectionTitle>Outbound</SectionTitle>
        <Table>
          <thead>
            <tr>
              <Th>Tracking #</Th>
              <Th>Destination</Th>
              <Th>Study</Th>
              <Th>Status</Th>
              <Th>Expected Arrival</Th>
              <Th>Sample Id[s]</Th>
              <Th></Th>
            </tr>
          </thead>
          <tbody>
            {filteredOutboundShipments.map(shipment => renderShipmentRow(shipment))}
          </tbody>
        </Table>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  margin-top: 24px;
  border: 1px solid #E0E0E0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin: 0;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const SeeAllButton = styled.button`
  padding: 8px 16px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;

  &:hover {
    background: #000;
    color: white;
  }
`;

const Section = styled.div`
  margin-bottom: 32px;
`;

const SectionTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
  color: #666;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  text-align: left;
  padding: 12px 16px;
  border-bottom: 1px solid #E0E0E0;
  color: #666;
  font-weight: 500;
  font-size: 14px;
`;

const TableCell = styled.td`
  padding: 12px 16px;
  border-bottom: 1px solid #E0E0E0;
  color: #666;
  font-size: 14px;
`;

const TableRow = styled.tr`
  &:hover {
    background-color: #f5f5f5;
  }
`;

const StatusText = styled.span<{ color: string }>`
  color: ${props => props.color};
  font-weight: 500;
`;

const SampleIdContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const SampleIdPill = styled.span<{ success: boolean }>`
  padding: 4px 8px;
  border-radius: 16px;
  background: ${props => props.success ? '#E8F5E9' : '#FFEBEE'};
  color: ${props => props.success ? '#00A651' : '#FF0000'};
  font-size: 12px;
  font-weight: 500;
`;

const StarButton = styled.button<{ isStarred: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.isStarred ? '#FFD700' : '#E0E0E0'};
  font-size: 20px;
  padding: 4px;
  transition: color 0.2s ease;
  
  &:hover {
    color: ${props => props.isStarred ? '#FFC000' : '#FFD700'};
  }
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const FilterLabel = styled.label`
  font-size: 12px;
  color: #666;
  font-weight: 500;
`;

const TrackingLink = styled.span`
  color: #0066cc;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #004499;
  }
`;

const StatusBadge = styled.div<{ status: string }>`
  padding: 4px 8px;
  border-radius: 16px;
  background-color: ${props => getStatusColor(props.status)}20;
  color: ${props => getStatusColor(props.status)};
  font-weight: 500;
  display: inline-block;
`;

export default HistoricalShipmentsTable;
