import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '../client';
import { ENDPOINTS } from '../config';
import type { ApiResponse, PaginatedApiResponse } from '../../types/api';
import type { 
  Shipment, 
  CreateShipmentDTO, 
  UpdateShipmentDTO, 
  ShipmentStatus,
  ShipmentProfile,
  BasicShipment 
} from '../../types/models/shipment';

export interface ShipmentFilters {
  trial_id?: string;
  status?: ShipmentStatus;
  carrier?: string;
  date_from?: string;
  date_to?: string;
  is_template?: boolean;
}

export interface ShipmentResponse {
  inboundData: Shipment[];
  outboundData: Shipment[];
}

export const shipmentsApi = {
  getShipments: async (params?: ShipmentFilters | string) => {
    const queryParams = typeof params === 'string' 
      ? { trial_id: params }
      : params;
    
    const response = await api.get('/shipments/', { params: queryParams });
    console.log('Raw shipments response:', response.data);
    return response.data;
  },

  getShipment: async (id: string): Promise<ApiResponse<Shipment>> => {
    const response = await api.get(ENDPOINTS.shipments.get(id));
    console.log('Raw single shipment response:', response.data);
    return response;
  },

  createShipment: async (data: CreateShipmentDTO | BasicShipment): Promise<ApiResponse<Shipment>> => {
    return api.post(ENDPOINTS.shipments.create, data);
  },

  updateShipment: async (id: string, data: UpdateShipmentDTO): Promise<ApiResponse<Shipment>> => {
    return api.patch(ENDPOINTS.shipments.get(id), data);
  },

  deleteShipment: async (id: string): Promise<ApiResponse<void>> => {
    return api.delete(ENDPOINTS.shipments.get(id));
  },

  // Profile operations
  getProfiles: async (): Promise<ApiResponse<ShipmentProfile[]>> => {
    return api.get(ENDPOINTS.shipments.profiles);
  },

  // Label operations
  getLabel: async (id: string): Promise<Blob> => {
    const response = await api.get(ENDPOINTS.shipments.label(id), {
      responseType: 'blob'
    });
    return response.data;
  },

  // Additional operations
  toggleStar: async (id: string): Promise<ApiResponse<void>> => {
    return api.post(ENDPOINTS.shipments.toggleStar(id));
  }
};

// React Query hooks
export const useShipments = (params?: ShipmentFilters | string) => {
  return useQuery(['shipments', params], () => shipmentsApi.getShipments(params), {
    select: (data) => ({
      inboundData: data.inboundData || [],
      outboundData: data.outboundData || []
    })
  });
};

export const useShipment = (id: string) => {
  return useQuery(
    ['shipment', id], 
    () => shipmentsApi.getShipment(id),
    { enabled: !!id }
  );
};

export const useCreateShipment = () => {
  return useMutation((data: any) => 
    api.post(ENDPOINTS.shipments.create, data)
  );
};

export const useUpdateShipment = () => {
  const queryClient = useQueryClient();
  
  return useMutation(
    ({ id, data }: { id: string; data: UpdateShipmentDTO }) => 
      shipmentsApi.updateShipment(id, data),
    {
      // When mutate is called:
      onMutate: async ({ id, data }) => {
        // Cancel any outgoing refetches
        await queryClient.cancelQueries(['shipment', id]);

        // Snapshot the previous value
        const previousShipment = queryClient.getQueryData(['shipment', id]);

        // Return a context object with the snapshotted value
        return { previousShipment };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, variables, context) => {
        if (context?.previousShipment) {
          queryClient.setQueryData(['shipment', variables.id], context.previousShipment);
        }
      },
      // Always refetch after error or success:
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries(['shipment', variables.id]);
        queryClient.invalidateQueries(['shipments']); // Invalidate the list query too
      }
    }
  );
};

export const useDeleteShipment = () => {
  return useMutation(shipmentsApi.deleteShipment);
};

export const useShipmentProfiles = () => {
  return useQuery(['shipmentProfiles'], shipmentsApi.getProfiles);
};

export const useShipmentLabel = (id: string) => {
  return useQuery(
    ['shipment', id, 'label'], 
    () => shipmentsApi.getLabel(id),
    { enabled: !!id }
  );
};

export const useToggleShipmentStar = () => {
  return useMutation(shipmentsApi.toggleStar);
};

export const useGenerateLabel = () => {
  return useMutation((shipmentId: string) => 
    api.post(ENDPOINTS.shipments.generateLabel(shipmentId))
  );
};

// For backward compatibility
export const ShipmentService = {
  getShipmentById: async (id: string): Promise<Shipment> => {
    const response = await shipmentsApi.getShipment(id);
    return response.data;
  },

  getShipments: async (params?: ShipmentFilters) => {
    const response = await shipmentsApi.getShipments(params);
    return response.data;
  },

  getLabel: async (id: string): Promise<Blob> => {
    return shipmentsApi.getLabel(id);
  },

  toggleStar: async (id: string): Promise<void> => {
    const response = await shipmentsApi.toggleStar(id);
    return response.data;
  },

  createOutbound: async (data: Partial<Shipment>) => {
    const response = await api.post('/shipments/create_outbound/', data);
    return response.data;
  },

  createReturnLabel: async (shipmentId: string) => {
    const response = await api.post(`/shipments/${shipmentId}/create_return_label/`);
    return response.data;
  },

  getProfiles: async () => {
    const response = await api.get('/shipment-profiles/');
    return response.data;
  }
}; 