import React from 'react';
import { Outlet } from 'react-router-dom';
import { SendOutKitsProvider } from '../../contexts/SendOutKitsContext';

const SendOutKitsLayout: React.FC = () => {
  return (
    <SendOutKitsProvider>
      <Outlet />
    </SendOutKitsProvider>
  );
};

export default SendOutKitsLayout; 