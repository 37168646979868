import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../components/common/Layout/PageLayout';
import ShipmentHeader from '../../components/Shipment/ShipmentHeader';
import ShipmentSummaryCard from '../../components/Shipment/ShipmentSummaryCard';
import NextButton from '../../components/common/Button/NextButton';
import { colors } from '../../components/constants/breakpoints';
import BillingSection from '../../components/Billing/BillingSection';
import { useTrials } from '../../hooks/useTrials';
import { useSendOutKits } from '../../contexts/SendOutKitsContext';
import { useShipments } from '../../api/endpoints/shipments';
import { formatAddressForDisplay } from '../../utils/address';
import { useShippingSelection } from 'contexts/ShippingSelectionContext';
import { ReturnShipment } from '../../contexts/SendOutKitsContext';
import EditableField from '../../components/Shipment/EditableField';
import ServiceSelectionModal from '../../components/Shipment/ServiceSelectionModal';
import DeviceSelectionModal from '../../components/Shipment/DeviceSelectionModal';
import AddressSelectionModal from '../../components/Address/AddressSelectionModal';
import { Address } from '../../types/models/address';
import { Dimensions } from '../../contexts/SendOutKitsContext';

const ConfirmationAndBilling: React.FC = () => {
  const navigate = useNavigate();
  const { trials } = useTrials();
  const { 
    selectedTrialId,
    selectedProfile,
    outboundShipment,
    returnShipment,
    collectionDevices,
    selectedService,
    selectedShipment,
    updateOutboundShipment,
    updateReturnShipment
  } = useSendOutKits();
  const { data: shipments } = useShipments();

  // Modal states
  const [showOutboundServiceModal, setShowOutboundServiceModal] = useState(false);
  const [showReturnServiceModal, setShowReturnServiceModal] = useState(false);
  const [showOutboundDeviceModal, setShowOutboundDeviceModal] = useState(false);
  const [showReturnDeviceModal, setShowReturnDeviceModal] = useState(false);
  const [showOutboundAddressModal, setShowOutboundAddressModal] = useState(false);
  const [showReturnAddressModal, setShowReturnAddressModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [hasNewContact, setHasNewContact] = useState(false);

  // Get service details for display
  const { getServiceDetails } = useShippingSelection();
  
  const outboundServiceDetails = getServiceDetails(outboundShipment.service);
  const returnServiceDetails = returnShipment?.service 
    ? getServiceDetails(returnShipment.service)
    : undefined;

  // Effect to update hasNewContact when returnShipment.contactInfo changes
  useEffect(() => {
    console.log('🔄 Contact Info Changed:', {
      contactInfo: returnShipment?.contactInfo,
      hasNewContactBefore: hasNewContact,
      willSetHasNewContact: !!returnShipment?.contactInfo
    });
    setHasNewContact(!!returnShipment?.contactInfo);
  }, [returnShipment?.contactInfo]);

  // Add logging to debug
  console.log('📦 Shipment State:', {
    hasNewContact,
    returnShipmentContactInfo: returnShipment?.contactInfo,
    willShowContact: hasNewContact ? returnShipment?.contactInfo : undefined
  });

  // Handlers for editable fields
  const handleOutboundContactUpdate = (field: string, value: string) => {
    if (outboundShipment.contactInfo) {
      updateOutboundShipment({
        ...outboundShipment,
        contactInfo: {
          ...outboundShipment.contactInfo,
          [field]: value
        }
      });
    }
  };

  const handleOutboundServiceSelect = (serviceName: string) => {
    updateOutboundShipment({
      ...outboundShipment,
      service: serviceName
    });
  };

  const handleReturnServiceSelect = (serviceName: string) => {
    if (returnShipment) {
      updateReturnShipment({
        ...returnShipment,
        service: serviceName
      });
    }
  };

  const handleOutboundDeviceSelect = (device: { name: string; quantity: number }) => {
    const updatedDevices = [...outboundShipment.devices, {
      id: `${device.name}-${Date.now()}`,
      name: device.name,
      quantity: device.quantity
    }];
    updateOutboundShipment({
      ...outboundShipment,
      devices: updatedDevices
    });
  };

  const handleReturnDeviceSelect = (device: { name: string; quantity: number }) => {
    if (returnShipment) {
      const updatedDevices = [...returnShipment.devices, {
        id: `${device.name}-${Date.now()}`,
        name: device.name,
        quantity: device.quantity
      }];
      updateReturnShipment({
        ...returnShipment,
        devices: updatedDevices
      });
    }
  };

  const handleSubmit = () => {
    // Handle submission logic here
    console.log('Submitting shipment...');
    // Navigate to confirmed page after submission
    navigate('/send-kits/confirmed');
  };

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleBillingChange = (billingData: {
    billTo: string;
    accountId: string;
    invoiceEmails: string[];
  }) => {
    // Handle billing data changes
    console.log('Billing data updated:', billingData);
  };

  // Add safe rendering for package details
  const renderPackageDetails = (shipment: ReturnShipment | null) => {
    if (!shipment) return undefined;

    return {
      weight: shipment.packageDetails?.weight || '0.0',
      dimensions: shipment.packageDetails?.dimensions ? {
        length: shipment.packageDetails.dimensions.length || '',
        width: shipment.packageDetails.dimensions.width || '',
        height: shipment.packageDetails.dimensions.height || ''
      } : undefined
    };
  };

  const handleOutboundDeviceUpdate = (index: number, field: 'name' | 'quantity', value: string | number) => {
    const updatedDevices = [...outboundShipment.devices];
    if (field === 'name') {
      updatedDevices[index] = { ...updatedDevices[index], name: value as string };
    } else {
      updatedDevices[index] = { ...updatedDevices[index], quantity: value as number };
    }
    updateOutboundShipment({
      ...outboundShipment,
      devices: updatedDevices
    });
  };

  const handleOutboundDeviceRemove = (index: number) => {
    const updatedDevices = outboundShipment.devices.filter((_, i) => i !== index);
    updateOutboundShipment({
      ...outboundShipment,
      devices: updatedDevices
    });
  };

  const handleOutboundPackageUpdate = (field: string, value: string) => {
    const currentDimensions = outboundShipment.packageDetails?.dimensions || { length: '0', width: '0', height: '0' };
    updateOutboundShipment({
      ...outboundShipment,
      packageDetails: {
        ...outboundShipment.packageDetails,
        [field]: value,
        dimensions: field.includes('length') || field.includes('width') || field.includes('height')
          ? {
              ...currentDimensions,
              [field]: value
            }
          : currentDimensions
      }
    });
  };

  const handleOutboundOptionsUpdate = (field: string, value: boolean) => {
    updateOutboundShipment({
      ...outboundShipment,
      options: {
        ...outboundShipment.options,
        [field]: value
      }
    });
  };

  // Similar handlers for return shipment
  const handleReturnDeviceUpdate = (index: number, field: 'name' | 'quantity', value: string | number) => {
    if (!returnShipment) return;
    const updatedDevices = [...returnShipment.devices];
    if (field === 'name') {
      updatedDevices[index] = { ...updatedDevices[index], name: value as string };
    } else {
      updatedDevices[index] = { ...updatedDevices[index], quantity: value as number };
    }
    updateReturnShipment({
      ...returnShipment,
      devices: updatedDevices
    });
  };

  const handleReturnDeviceRemove = (index: number) => {
    if (!returnShipment) return;
    const updatedDevices = returnShipment.devices.filter((_, i) => i !== index);
    updateReturnShipment({
      ...returnShipment,
      devices: updatedDevices
    });
  };

  const handleReturnPackageUpdate = (field: string, value: string) => {
    if (!returnShipment) return;
    const currentDimensions = returnShipment.packageDetails?.dimensions || { length: '0', width: '0', height: '0' };
    updateReturnShipment({
      ...returnShipment,
      packageDetails: {
        ...returnShipment.packageDetails,
        [field]: value,
        dimensions: field.includes('length') || field.includes('width') || field.includes('height')
          ? {
              ...currentDimensions,
              [field]: value
            }
          : currentDimensions
      }
    });
  };

  const handleReturnOptionsUpdate = (field: string, value: boolean) => {
    if (!returnShipment) return;
    updateReturnShipment({
      ...returnShipment,
      options: {
        ...returnShipment.options,
        [field]: value
      }
    });
  };

  // Add new handler for adding devices
  const handleOutboundDeviceAdd = (device: { name: string; quantity: number }) => {
    const newDevice = {
      id: `${device.name}-${Date.now()}`, // Generate a unique ID
      name: device.name,
      quantity: device.quantity
    };
    updateOutboundShipment({
      ...outboundShipment,
      devices: [...outboundShipment.devices, newDevice]
    });
  };

  // Add new handler for return devices
  const handleReturnDeviceAdd = (device: { name: string; quantity: number }) => {
    if (!returnShipment) return;
    const newDevice = {
      id: `${device.name}-${Date.now()}`, // Generate a unique ID
      name: device.name,
      quantity: device.quantity
    };
    updateReturnShipment({
      ...returnShipment,
      devices: [...returnShipment.devices, newDevice]
    });
  };

  const handleReturnContactUpdate = (field: string, value: string) => {
    if (returnShipment && returnShipment.contactInfo) {
      console.log('🔄 Updating return contact in confirmation:', {
        field,
        value,
        currentContactInfo: returnShipment.contactInfo
      });
      
      updateReturnShipment({
        ...returnShipment,
        contactInfo: {
          ...returnShipment.contactInfo,
          [field]: value
        }
      });
    }
  };

  // Add useEffect to track contact info changes
  useEffect(() => {
    console.log('📦 Return Shipment State:', {
      hasContact: !!returnShipment?.contactInfo,
      contactInfo: returnShipment?.contactInfo,
      fullReturnShipment: returnShipment
    });
  }, [returnShipment?.contactInfo, returnShipment]);

  return (
    <PageLayout title="Send Out Kits">
      <Container>
        <ShipmentHeader 
          selectedTrialId={selectedTrialId}
          selectedShipmentId={selectedShipment}
          selectedProfile={selectedProfile}
          onTrialSelect={() => {}}
          onShipmentSelect={() => {}}
          trials={trials || []}
          shipments={shipments?.outboundData || []}
          readOnly={!isEditing}
        />
        
        <Section>
          <SectionTitle>
            Outbound Shipment
            {isEditing && <EditingIndicator>Editing Mode</EditingIndicator>}
          </SectionTitle>
          <ShipmentSummaryCard
            type="outbound"
            date={outboundShipment.pickupDate instanceof Date ? outboundShipment.pickupDate.toLocaleDateString() : 'Not set'}
            trialId={selectedTrialId}
            address={outboundShipment.address ? formatAddressForDisplay(outboundShipment.address) : undefined}
            service={{
              name: outboundShipment.service,
              time: outboundServiceDetails?.deliveryTime || ''
            }}
            contents={outboundShipment.devices.map(device => ({
              name: device.name || 'Unknown Device',
              quantity: device.quantity
            }))}
            estimatedCost={outboundShipment.estimatedCost || outboundServiceDetails?.price || '$0.00'}
            onEdit={handleEdit}
            contactInfo={outboundShipment.contactInfo}
            options={outboundShipment.options}
            onServiceClick={() => isEditing && setShowOutboundServiceModal(true)}
            onDeviceClick={() => isEditing && setShowOutboundDeviceModal(true)}
            onAddressClick={() => isEditing && setShowOutboundAddressModal(true)}
            onContactUpdate={handleOutboundContactUpdate}
            onDeviceUpdate={handleOutboundDeviceUpdate}
            onDeviceRemove={handleOutboundDeviceRemove}
            onOptionsUpdate={handleOutboundOptionsUpdate}
            isEditing={isEditing}
          />
        </Section>

        {returnShipment && (
          <Section>
            <SectionTitle>
              Return Shipment
              {isEditing && <EditingIndicator>Editing Mode</EditingIndicator>}
            </SectionTitle>
            <ShipmentSummaryCard
              type="inbound"
              date={returnShipment.deliveryDate instanceof Date ? returnShipment.deliveryDate.toLocaleDateString() : 'Not set'}
              trialId={selectedTrialId}
              address={returnShipment.address ? formatAddressForDisplay(returnShipment.address) : undefined}
              service={{
                name: returnShipment.service,
                time: returnServiceDetails?.deliveryTime || ''
              }}
              contents={returnShipment.devices.map(device => ({
                name: device.name || 'Unknown Device',
                quantity: device.quantity
              }))}
              estimatedCost={returnShipment.estimatedCost || returnServiceDetails?.price || '$0.00'}
              onEdit={handleEdit}
              packageDetails={returnShipment.packageDetails ? {
                weight: returnShipment.packageDetails.sameAsOutbound ? '4.0' : returnShipment.packageDetails.weight || '0.0',
                dimensions: returnShipment.packageDetails.sameAsOutbound ? {
                  length: '1',
                  width: '2',
                  height: '3'
                } : returnShipment.packageDetails.dimensions ? {
                  length: String(returnShipment.packageDetails.dimensions.length || '0'),
                  width: String(returnShipment.packageDetails.dimensions.width || '0'),
                  height: String(returnShipment.packageDetails.dimensions.height || '0')
                } : undefined,
                kitName: returnShipment.packageDetails.kitName
              } : undefined}
              options={returnShipment.options}
              onServiceClick={() => isEditing && setShowReturnServiceModal(true)}
              onAddressClick={() => isEditing && setShowReturnAddressModal(true)}
              onDeviceClick={() => isEditing && setShowReturnDeviceModal(true)}
              contactInfo={returnShipment.contactInfo}
              onContactUpdate={handleReturnContactUpdate}
              onDeviceUpdate={handleReturnDeviceUpdate}
              onDeviceRemove={handleReturnDeviceRemove}
              onPackageUpdate={handleReturnPackageUpdate}
              onOptionsUpdate={handleReturnOptionsUpdate}
              isEditing={isEditing}
            />
          </Section>
        )}

        <ButtonContainer>
          {isEditing ? (
            <>
              <CancelButton onClick={handleEdit}>Cancel</CancelButton>
              <SaveButton onClick={() => {
                setIsEditing(false);
                // Add any save logic here
              }}>Save Changes</SaveButton>
            </>
          ) : (
            <NextButton onClick={handleSubmit}>Submit</NextButton>
          )}
        </ButtonContainer>

        {/* Modals */}
        {showOutboundServiceModal && (
          <ServiceSelectionModal
            onClose={() => setShowOutboundServiceModal(false)}
            onSelect={handleOutboundServiceSelect}
          />
        )}

        {showReturnServiceModal && (
          <ServiceSelectionModal
            onClose={() => setShowReturnServiceModal(false)}
            onSelect={handleReturnServiceSelect}
          />
        )}

        {showOutboundAddressModal && (
          <AddressSelectionModal
            onClose={() => setShowOutboundAddressModal(false)}
            onSelect={(address: Address) => {
              updateOutboundShipment({
                ...outboundShipment,
                address
              });
              setShowOutboundAddressModal(false);
            }}
          />
        )}

        {showReturnAddressModal && (
          <AddressSelectionModal
            onClose={() => setShowReturnAddressModal(false)}
            onSelect={(address: Address) => {
              if (returnShipment) {
                updateReturnShipment({
                  ...returnShipment,
                  address
                });
              }
              setShowReturnAddressModal(false);
            }}
          />
        )}

        {/* Add DeviceSelectionModal */}
        {showOutboundDeviceModal && (
          <DeviceSelectionModal
            onClose={() => setShowOutboundDeviceModal(false)}
            onSelect={handleOutboundDeviceAdd}
          />
        )}
        {showReturnDeviceModal && (
          <DeviceSelectionModal
            onClose={() => setShowReturnDeviceModal(false)}
            onSelect={handleReturnDeviceAdd}
          />
        )}
      </Container>
    </PageLayout>
  );
};

const Container = styled.div`
  max-width: 64rem;
  margin: 0 auto;
  padding: 2rem;
`;

const Section = styled.div`
  margin-bottom: 2rem;
  
  &:not(:first-of-type) {
    margin-top: 4rem;
    padding-top: 2rem;
    border-top: 0.0625rem solid ${colors.border};
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  color: ${colors.text.primary};
  margin-bottom: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 0.0625rem solid ${colors.border};
`;

// Add helper function for total cost calculation
const calculateTotalCost = (outboundCost?: string, returnCost?: string): string => {
  const outbound = parseFloat(outboundCost?.replace('$', '') || '0');
  const returnCostValue = parseFloat(returnCost?.replace('$', '') || '0');
  return `$${(outbound + returnCostValue).toFixed(2)}`;
};

const ContactSection = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid ${colors.border};
  border-radius: 0.5rem;
  background: #f8f9fa;
`;

const ContactTitle = styled.h3`
  font-size: 1rem;
  color: ${colors.text.primary};
  margin-bottom: 0.75rem;
`;

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ContactRow = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  font-weight: 500;
  width: 120px;
  color: ${colors.text.secondary};
`;

const Value = styled.span`
  color: ${colors.text.primary};
`;

const PackageDetailsSection = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid ${colors.border};
  border-radius: 0.5rem;
  background: #f8f9fa;
`;

const DetailTitle = styled.h3`
  font-size: 1rem;
  color: ${colors.text.primary};
  margin-bottom: 1rem;
`;

const DetailGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
`;

const DetailItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const DetailLabel = styled.span`
  font-weight: 500;
  color: ${colors.text.secondary};
  font-size: 0.875rem;
`;

const DetailValue = styled.span`
  color: ${colors.text.primary};
`;

const InfoNote = styled.div`
  margin-top: 1rem;
  padding: 0.75rem;
  background-color: #f5f5f5;
  border-radius: 0.5rem;
  color: ${colors.text.secondary};
  font-style: italic;
`;

const EditingIndicator = styled.span`
  margin-left: 1rem;
  font-size: 0.875rem;
  color: ${colors.primary};
  font-weight: normal;
`;

const CancelButton = styled.button`
  padding: 0.75rem 1.5rem;
  border: 1px solid ${colors.border};
  border-radius: 0.25rem;
  background: white;
  color: ${colors.text.primary};
  cursor: pointer;
  margin-right: 1rem;

  &:hover {
    background: #f5f5f5;
  }
`;

const SaveButton = styled(NextButton)`
  background: ${colors.primary};
`;

const ContactCard = styled.div`
  background-color: #f8f9fa;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 2rem;
`;

const ContactCardTitle = styled.h3`
  font-size: 1rem;
  color: ${colors.text.primary};
  margin-bottom: 0.75rem;
`;

const ContactField = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

const ContactLabel = styled.span`
  color: ${colors.text.secondary};
  font-size: 0.875rem;
  min-width: 100px;
`;

export default ConfirmationAndBilling; 