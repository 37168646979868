import { useQuery } from '@tanstack/react-query';
import { shipmentsApi } from '../api/endpoints/shipments';
import type { Shipment } from '../types/models/shipment';
import type { ShipmentFilters, ShipmentResponse } from '../api/endpoints/shipments';
import { ApiResponse } from 'types/api';

export const useShipmentTemplates = (searchTerm?: string) => {
  return useQuery(
    ['shipmentTemplates', searchTerm],
    async () => {
      const response = await shipmentsApi.getShipments({ 
        is_template: true,
        search: searchTerm 
      } as ShipmentFilters);
      return response;
    },
    {
      select: (data) => ({
        inboundData: data.inboundData || [],
        outboundData: data.outboundData || []
      })
    }
  );
}; 