import React from 'react';
import styled from '@emotion/styled';
import Select from '../common/Select/Select';

interface Kit {
  name: string;
  inventory: number;
  threshold: number;
  restockStatus: 'Scheduled' | 'Pending' | 'None';
  rowId?: string;
}

interface StudyGroup {
  studyId: string;
  kits: Kit[];
}

interface TableRow {
  id: string;
  studyId: string;
  kitName: string;
  inventory: number;
  threshold: number;
  restockStatus: 'Scheduled' | 'Pending' | 'None';
}

interface InventoryLevelsTableProps {
  data: StudyGroup[];
  onRestock: (studyId: string, kitName: string) => void;
  studyFilter: string;
  statusFilter: string;
  timeFilter: string;
  onStudyFilterChange: (value: string) => void;
  onStatusFilterChange: (value: string) => void;
  onTimeFilterChange: (value: string) => void;
  starredRows?: string[];
  onToggleStar?: (rowId: string) => void;
}

const getRestockStatusColor = (status: string): string => {
  switch (status) {
    case 'Scheduled':
      return '#00A651';
    case 'Pending':
      return '#FFA500';
    default:
      return '#666666';
  }
};

const InventoryLevelsTable: React.FC<InventoryLevelsTableProps> = ({
  data,
  onRestock,
  studyFilter,
  statusFilter,
  timeFilter,
  onStudyFilterChange,
  onStatusFilterChange,
  onTimeFilterChange,
  starredRows = [],
  onToggleStar = () => {}
}) => {
  // Convert nested data into flat rows
  const allRows = data.flatMap((study) =>
    study.kits.map((kit, kitIndex) => ({
      // Each row gets a unique ID based on study and kit index
      id: `row-${study.studyId}-${kitIndex}`,
      studyId: study.studyId,
      kitName: kit.name,
      inventory: kit.inventory,
      threshold: kit.threshold,
      restockStatus: kit.restockStatus
    }))
  );

  // Apply filters
  const filteredRows = allRows.filter(row => {
    if (studyFilter !== 'all' && row.studyId !== studyFilter) {
      return false;
    }
    if (statusFilter !== 'all' && row.restockStatus !== statusFilter) {
      return false;
    }
    return true;
  });

  // Separate starred and non-starred rows
  const starredRows_ = filteredRows.filter(row => starredRows.includes(row.id));
  const nonStarredRows = filteredRows.filter(row => !starredRows.includes(row.id));

  // Group non-starred rows by study for display
  const groupedNonStarredRows = nonStarredRows.reduce((acc, row) => {
    const existingGroup = acc.find(group => group.studyId === row.studyId);
    if (existingGroup) {
      existingGroup.rows.push(row);
    } else {
      acc.push({ 
        studyId: row.studyId, 
        rows: [row]
      });
    }
    return acc;
  }, [] as Array<{ studyId: string; rows: TableRow[] }>)
  .filter(group => group.rows.length > 0);

  return (
    <Container>
      <Header>
        <Title>Inventory Levels</Title>
        <FilterContainer>
          <FilterGroup>
            <FilterLabel>Study</FilterLabel>
            <Select
              placeholder="All Studies"
              value={studyFilter}
              onChange={onStudyFilterChange}
              options={[
                { value: 'all', label: 'All Studies' },
                { value: 'EVGR-A-001', label: 'EVGR-A-001' },
                { value: 'EVGR-A-002', label: 'EVGR-A-002' },
                { value: 'EVGR-A-003', label: 'EVGR-A-003' }
              ]}
            />
          </FilterGroup>
          <FilterGroup>
            <FilterLabel>Status</FilterLabel>
            <Select
              placeholder="All Statuses"
              value={statusFilter}
              onChange={onStatusFilterChange}
              options={[
                { value: 'all', label: 'All Statuses' },
                { value: 'Scheduled', label: 'Scheduled' },
                { value: 'Pending', label: 'Pending' },
                { value: 'None', label: 'None' }
              ]}
            />
          </FilterGroup>
          <FilterGroup>
            <FilterLabel>Sample Creation Time</FilterLabel>
            <Select
              placeholder="All Times"
              value={timeFilter}
              onChange={onTimeFilterChange}
              options={[
                { value: 'all', label: 'All Times' },
                { value: 'today', label: 'Today' },
                { value: 'this_week', label: 'This Week' },
                { value: 'this_month', label: 'This Month' }
              ]}
            />
          </FilterGroup>
          <SeeAllButton onClick={() => {
            onStatusFilterChange('all');
            onTimeFilterChange('all');
          }}>
            See All
          </SeeAllButton>
        </FilterContainer>
      </Header>

      <Table>
        <thead>
          <tr>
            <Th>Active Studies</Th>
            <Th>Kits</Th>
            <Th>Inventory Level</Th>
            <Th>Threshold</Th>
            <Th>Restock Status</Th>
            <Th></Th>
            <Th></Th>
          </tr>
        </thead>
        <tbody>
          {/* Render starred rows */}
          {starredRows_.map(row => (
            <TableRow 
              key={row.id}
              isFirstInGroup={true}
              isLastInGroup={true}
              isEvenGroup={false}
            >
              <Td>{row.studyId}</Td>
              <Td>{row.kitName}</Td>
              <Td>{row.inventory}</Td>
              <Td>{row.threshold}</Td>
              <Td>
                <StatusText color={getRestockStatusColor(row.restockStatus)}>
                  {row.restockStatus}
                </StatusText>
              </Td>
              <Td>
                <RestockButton onClick={() => onRestock(row.studyId, row.kitName)}>
                  Restock
                </RestockButton>
              </Td>
              <Td>
                <StarButton 
                  onClick={() => onToggleStar(row.id)}
                  isStarred={true}
                >
                  ★
                </StarButton>
              </Td>
            </TableRow>
          ))}

          {/* Render grouped non-starred rows */}
          {groupedNonStarredRows.map((group, groupIndex) => (
            <React.Fragment key={group.studyId}>
              {group.rows.map((row, rowIndex) => (
                <TableRow 
                  key={row.id}
                  isFirstInGroup={rowIndex === 0}
                  isLastInGroup={rowIndex === group.rows.length - 1}
                  isEvenGroup={groupIndex % 2 === 0}
                >
                  {rowIndex === 0 && (
                    <Td rowSpan={group.rows.length} isGroupCell>
                      {row.studyId}
                    </Td>
                  )}
                  <Td>{row.kitName}</Td>
                  <Td>{row.inventory}</Td>
                  <Td>{row.threshold}</Td>
                  <Td>
                    <StatusText color={getRestockStatusColor(row.restockStatus)}>
                      {row.restockStatus}
                    </StatusText>
                  </Td>
                  <Td>
                    <RestockButton onClick={() => onRestock(row.studyId, row.kitName)}>
                      Restock
                    </RestockButton>
                  </Td>
                  <Td>
                    <StarButton 
                      onClick={() => onToggleStar(row.id)}
                      isStarred={false}
                    >
                      ★
                    </StarButton>
                  </Td>
                </TableRow>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid #E0E0E0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin: 0;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  text-align: left;
  padding: 12px 16px;
  border-bottom: 1px solid #E0E0E0;
  color: #666;
  font-weight: 500;
  font-size: 14px;
`;

interface TableRowProps {
  isFirstInGroup: boolean;
  isLastInGroup: boolean;
  isEvenGroup: boolean;
}

const TableRow = styled.tr<TableRowProps>`
  background-color: ${props => props.isEvenGroup ? '#F8F8F8' : 'white'};
  
  ${props => props.isFirstInGroup && `
    border-top: 1px solid #E0E0E0;
  `}
  
  ${props => props.isLastInGroup && `
    border-bottom: 1px solid #E0E0E0;
  `}
`;

interface TdProps {
  isGroupCell?: boolean;
}

const Td = styled.td<TdProps>`
  padding: 12px 16px;
  color: #666;
  font-size: 14px;
  background: inherit;
`;

const StatusText = styled.span<{ color: string }>`
  color: ${props => props.color};
  font-weight: 500;
`;

const RestockButton = styled.button`
  padding: 6px 12px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  color: #000;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const FilterLabel = styled.label`
  font-size: 12px;
  color: #666;
  font-weight: 500;
`;

const SeeAllButton = styled.button`
  padding: 8px 16px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;
  align-self: flex-end;
  margin-bottom: 2px;

  &:hover {
    background: #000;
    color: white;
  }
`;

const StarButton = styled.button<{ isStarred: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.isStarred ? '#FFD700' : '#E0E0E0'};
  font-size: 20px;
  padding: 4px;
  transition: color 0.2s ease;
  
  &:hover {
    color: ${props => props.isStarred ? '#FFC000' : '#FFD700'};
  }
`;

export default InventoryLevelsTable;
