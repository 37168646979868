import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../components/common/Layout/PageLayout';
import globeImage from '../../assets/shipmentmanagerglobe.png';
import ShipmentTable from '../../components/Shipments/ShipmentTable';
import { ShipmentService, useShipments } from '../../api/endpoints/shipments';
import type { Shipment } from '../../types/models/shipment';

const INITIAL_DISPLAY_COUNT = 10;
const STARRED_SHIPMENTS_KEY = 'starredShipments';

const ShipmentManager: React.FC = () => {
  const navigate = useNavigate();
  const [starredShipments, setStarredShipments] = useState<string[]>(() => {
    const saved = localStorage.getItem(STARRED_SHIPMENTS_KEY);
    return saved ? JSON.parse(saved) : [];
  });
  const [showAllInbound, setShowAllInbound] = useState(false);
  const [showAllOutbound, setShowAllOutbound] = useState(false);
  
  // Separate filter states for inbound and outbound
  const [inboundFilters, setInboundFilters] = useState({
    status: 'all',
    startDate: null as Date | null,
    endDate: null as Date | null,
    study: 'all'
  });
  
  const [outboundFilters, setOutboundFilters] = useState({
    status: 'all',
    startDate: null as Date | null,
    endDate: null as Date | null,
    study: 'all'
  });

  const { data: shipmentData, isLoading, error } = useShipments();

  useEffect(() => {
    localStorage.setItem(STARRED_SHIPMENTS_KEY, JSON.stringify(starredShipments));
  }, [starredShipments]);

  const handleStatusFilterChange = (value: string, type: 'inbound' | 'outbound') => {
    if (type === 'inbound') {
      setInboundFilters(prev => ({ ...prev, status: value }));
    } else {
      setOutboundFilters(prev => ({ ...prev, status: value }));
    }
  };

  const handleDateChange = (dates: [Date | null, Date | null], type: 'inbound' | 'outbound') => {
    const [start, end] = dates;
    if (type === 'inbound') {
      setInboundFilters(prev => ({ ...prev, startDate: start, endDate: end }));
    } else {
      setOutboundFilters(prev => ({ ...prev, startDate: start, endDate: end }));
    }
  };

  const handleStudyFilterChange = (value: string, type: 'inbound' | 'outbound') => {
    if (type === 'inbound') {
      setInboundFilters(prev => ({ ...prev, study: value }));
    } else {
      setOutboundFilters(prev => ({ ...prev, study: value }));
    }
  };

  const filterAndSortShipments = (shipments: Shipment[], type: 'inbound' | 'outbound') => {
    const filters = type === 'inbound' ? inboundFilters : outboundFilters;
    
    // First, separate starred and non-starred shipments
    const starredShipmentsList = shipments.filter(s => 
      starredShipments.includes(s.tracking_number)
    );
    const nonStarredShipmentsList = shipments.filter(s => 
      !starredShipments.includes(s.tracking_number)
    );

    // Apply status and date filters to both lists
    const filterShipments = (list: Shipment[]) => {
      return list.filter(s => {
        // Always show starred items
        if (starredShipments.includes(s.tracking_number)) {
          return true;
        }

        // Status filter
        const matchesStatus = filters.status === 'all' || 
          (filters.status === 'Delayed' && s.status === 'PENDING') ||
          (filters.status === 'Delivered' && s.status === 'DELIVERED');

        // Date filter
        let matchesDate = true;
        // Get the shipment date in UTC
        const shipmentDate = new Date(s.expected_delivery);
        
        // If we have date filters and the shipment date is valid, apply them
        if (!isNaN(shipmentDate.getTime())) {
          // Convert dates to UTC midnight for comparison
          const getUTCDateOnly = (date: Date) => {
            return Date.UTC(
              date.getUTCFullYear(),
              date.getUTCMonth(),
              date.getUTCDate()
            );
          };

          const shipmentTime = getUTCDateOnly(shipmentDate);

          // Handle start date filter
          if (filters.startDate) {
            const startTime = getUTCDateOnly(filters.startDate);
            if (shipmentTime < startTime) {
              matchesDate = false;
            }
          }

          // Handle end date filter
          if (filters.endDate) {
            const endTime = getUTCDateOnly(filters.endDate);
            if (shipmentTime > endTime) {
              matchesDate = false;
            }
          }
        } else {
          // For unknown dates, only filter them out if we have date filters active
          if (filters.startDate || filters.endDate) {
            matchesDate = false;
          }
        }

        return matchesStatus && matchesDate;
      });
    };

    const filteredStarred = filterShipments(starredShipmentsList);
    const filteredNonStarred = filterShipments(nonStarredShipmentsList);

    // Sort each list by date
    const sortByDate = (list: Shipment[]) => {
      return [...list].sort((a, b) => {
        const aDate = new Date(a.expected_delivery).getTime();
        const bDate = new Date(b.expected_delivery).getTime();
        return bDate - aDate; // Most recent first
      });
    };

    // Sort each list separately and combine
    const sortedStarred = sortByDate(filteredStarred);
    const sortedNonStarred = sortByDate(filteredNonStarred);

    // Combine the lists with starred items always on top
    return [...sortedStarred, ...sortedNonStarred];
  };

  const handleToggleStar = (trackingNumber: string) => {
    setStarredShipments(prev => 
      prev.includes(trackingNumber)
        ? prev.filter(t => t !== trackingNumber)
        : [...prev, trackingNumber]
    );
  };

  if (isLoading) {
    return (
      <PageLayout>
        <Container>
          <div>Loading shipments...</div>
        </Container>
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <Container>
          <div>Error loading shipments: {error.toString()}</div>
        </Container>
      </PageLayout>
    );
  }

  const sortedInboundData = filterAndSortShipments(shipmentData?.inboundData || [], 'inbound');
  const sortedOutboundData = filterAndSortShipments(shipmentData?.outboundData || [], 'outbound');

  const displayedInbound = showAllInbound 
    ? sortedInboundData 
    : sortedInboundData.slice(0, INITIAL_DISPLAY_COUNT);

  const displayedOutbound = showAllOutbound 
    ? sortedOutboundData 
    : sortedOutboundData.slice(0, INITIAL_DISPLAY_COUNT);

  return (
    <PageLayout>
      <Container>
        <HeaderContainer>
          <Title>Shipment Manager</Title>
          
          <ActionButtonsContainer>
            <ActionButton onClick={() => navigate('/send-kits')}>
              Send Out Kits
            </ActionButton>
            <ActionButton onClick={() => navigate('/track-shipments')}>
              Track My Shipments
            </ActionButton>
            <ActionButton onClick={() => navigate('/schedule-pickup')}>
              Schedule Pickups
            </ActionButton>
          </ActionButtonsContainer>
        </HeaderContainer>

        <GlobeImage src={globeImage} alt="Global shipment map" />
        
        <Section>
          <ShipmentTable 
            type="inbound" 
            data={displayedInbound} 
            starredShipments={starredShipments}
            onToggleStar={handleToggleStar}
            statusFilter={inboundFilters.status}
            startDate={inboundFilters.startDate}
            endDate={inboundFilters.endDate}
            studyFilter={inboundFilters.study}
            onStatusFilterChange={handleStatusFilterChange}
            onDateChange={handleDateChange}
            onStudyFilterChange={handleStudyFilterChange}
            showMoreButton={
              sortedInboundData.length > INITIAL_DISPLAY_COUNT ? (
                <ShowMoreButton 
                  onClick={() => setShowAllInbound(!showAllInbound)}
                >
                  {showAllInbound ? 'Show Less' : `Show ${sortedInboundData.length - INITIAL_DISPLAY_COUNT} More`}
                </ShowMoreButton>
              ) : null
            }
          />
        </Section>

        <Section>
          <ShipmentTable 
            type="outbound" 
            data={displayedOutbound} 
            starredShipments={starredShipments}
            onToggleStar={handleToggleStar}
            statusFilter={outboundFilters.status}
            startDate={outboundFilters.startDate}
            endDate={outboundFilters.endDate}
            studyFilter={outboundFilters.study}
            onStatusFilterChange={handleStatusFilterChange}
            onDateChange={handleDateChange}
            onStudyFilterChange={handleStudyFilterChange}
            showMoreButton={
              sortedOutboundData.length > INITIAL_DISPLAY_COUNT ? (
                <ShowMoreButton 
                  onClick={() => setShowAllOutbound(!showAllOutbound)}
                >
                  {showAllOutbound ? 'Show Less' : `Show ${sortedOutboundData.length - INITIAL_DISPLAY_COUNT} More`}
                </ShowMoreButton>
              ) : null
            }
          />
        </Section>
      </Container>
    </PageLayout>
  );
};

const Container = styled.div`
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 500;
  margin: 0;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const ActionButton = styled.button`
  padding: 12px 24px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

const Section = styled.div`
  margin-bottom: 32px;
`;

const ShowMoreButton = styled.button`
  display: block;
  margin: 16px auto 0;
  padding: 8px 16px;
  border: none;
  background: none;
  color: #0066cc;
  font-size: 14px;
  cursor: pointer;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
    text-decoration: underline;
  }
`;

const GlobeImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 32px;
`;

export default ShipmentManager; 