import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../client';
import { ENDPOINTS } from '../config';
import type { ApiResponse, PaginatedApiResponse } from '../../types/api';
import type { Kit } from '../../types/models/kit';
import type { Device, IoTDevice } from '../../types/models/device';

interface KitFilters {
  is_template?: boolean;
  device_id?: string;
  iot_device_id?: string;
}

export const kitApi = {
  // Basic CRUD
  getKits: async (params?: KitFilters): Promise<ApiResponse<PaginatedApiResponse<Kit>>> => {
    return api.get(ENDPOINTS.kits.base, { params });
  },

  getKit: async (id: string): Promise<ApiResponse<Kit>> => {
    return api.get(ENDPOINTS.kits.byId(id));
  },

  createKit: async (data: Partial<Kit>): Promise<ApiResponse<Kit>> => {
    return api.post(ENDPOINTS.kits.base, data);
  },

  updateKit: async (id: string, data: Partial<Kit>): Promise<ApiResponse<Kit>> => {
    return api.put(ENDPOINTS.kits.byId(id), data);
  },

  deleteKit: async (id: string): Promise<ApiResponse<void>> => {
    return api.delete(ENDPOINTS.kits.byId(id));
  },

  // Template operations
  getTemplates: async (): Promise<ApiResponse<PaginatedApiResponse<Kit>>> => {
    return api.get(ENDPOINTS.kits.templates);
  },

  createFromTemplate: async (templateId: string, data: Partial<Kit>): Promise<ApiResponse<Kit>> => {
    return api.post(`${ENDPOINTS.kits.templates}/${templateId}/create`, data);
  },

  // Device association
  assignDevice: async (id: string, deviceId: string): Promise<ApiResponse<Kit>> => {
    return api.post(`${ENDPOINTS.kits.byId(id)}/assign-device`, { device_id: deviceId });
  },

  assignIoTDevice: async (id: string, iotDeviceId: string): Promise<ApiResponse<Kit>> => {
    return api.post(`${ENDPOINTS.kits.byId(id)}/assign-iot-device`, { iot_device_id: iotDeviceId });
  }
};

// React Query hooks
export const useKits = (params?: KitFilters) => {
  return useQuery(['kits', params], () => kitApi.getKits(params));
};

export const useKit = (id: string) => {
  return useQuery(
    ['kit', id], 
    () => kitApi.getKit(id),
    { enabled: !!id }
  );
};

export const useCreateKit = () => {
  return useMutation(kitApi.createKit);
};

export const useUpdateKit = () => {
  return useMutation(
    ({ id, data }: { id: string; data: Partial<Kit> }) => 
      kitApi.updateKit(id, data)
  );
};

export const useDeleteKit = () => {
  return useMutation(kitApi.deleteKit);
};

export const useKitTemplates = () => {
  return useQuery(['kitTemplates'], kitApi.getTemplates);
};

export const useCreateFromTemplate = () => {
  return useMutation(
    ({ templateId, data }: { templateId: string; data: Partial<Kit> }) =>
      kitApi.createFromTemplate(templateId, data)
  );
}; 