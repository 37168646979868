import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../components/constants/breakpoints';
import EditableField from './EditableField';

interface ShipmentSummaryCardProps {
  type: 'outbound' | 'inbound';
  date: string;
  quantity?: string;
  trialId: string;
  address?: {
    name: string;
    street: string;
    cityStateZip: string;
  };
  service: {
    name: string;
    time: string;
  };
  contents: Array<{
    name: string;
    quantity: number;
  }>;
  estimatedCost?: string;
  onEdit?: () => void;
  onServiceClick?: () => void;
  onDeviceClick?: () => void;
  isEditing?: boolean;
  contactInfo?: {
    name: string;
    email: string;
    phoneNumber: string;
    phoneExtension?: string;
    company?: string;
  };
  packageDetails?: {
    weight: string;
    dimensions?: {
      length: string;
      width: string;
      height: string;
    };
    kitName?: string;
    sameAsOutbound?: boolean;
  };
  pickupDetails?: {
    date?: Date;
    timeWindow?: {
      earliest: string;
      latest: string;
    };
    location?: string;
  };
  references?: {
    shipmentId?: string;
    poNumber?: string;
    invoiceNumber?: string;
    departmentNumber?: string;
  };
  options?: {
    requireSignature: boolean;
    holdAtLocation: boolean;
    locationZip?: string;
  };
  onAddressClick?: () => void;
  onContactUpdate?: (field: string, value: string) => void;
  onDeviceUpdate?: (index: number, field: 'name' | 'quantity', value: string | number) => void;
  onDeviceRemove?: (index: number) => void;
  onPackageUpdate?: (field: string, value: string) => void;
  onOptionsUpdate?: (field: string, value: boolean) => void;
}

interface DeviceRowProps {
  isEditing?: boolean;
}

const Card = styled.div`
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 0.0625rem solid ${colors.border};
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const DateText = styled.div`
  color: ${colors.text.secondary};
`;

const TrialId = styled.div`
  color: ${colors.text.secondary};
`;

const EditButton = styled.button`
  padding: 0.5rem 1rem;
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.25rem;
  background: none;
  color: ${colors.primary};
  cursor: pointer;
  
  &:hover {
    background: ${colors.primary};
    color: white;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
`;

const SectionTitle = styled.div`
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: ${colors.text.primary};
`;

const AddressSection = styled.div<{ isEditable?: boolean }>`
  cursor: ${props => props.isEditable ? 'pointer' : 'default'};
  padding: 0.5rem;
  border-radius: 0.25rem;
  
  &:hover {
    background-color: ${props => props.isEditable ? 'rgba(0, 0, 0, 0.05)' : 'transparent'};
  }
`;

const AddressText = styled.div`
  color: ${colors.text.secondary};
  line-height: 1.5;
`;

const ServiceSection = styled.div<{ isEditable?: boolean }>`
  cursor: ${props => props.isEditable ? 'pointer' : 'default'};
  padding: 0.5rem;
  border-radius: 0.25rem;
  
  &:hover {
    background-color: ${props => props.isEditable ? 'rgba(0, 0, 0, 0.05)' : 'transparent'};
  }
`;

const ServiceTime = styled.div`
  color: ${colors.text.secondary};
`;

const ContentsSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeviceList = styled.div`
  margin-bottom: 1rem;
`;

const CostSection = styled.div`
  text-align: right;
`;

const Cost = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  color: ${colors.text.primary};
`;

const ContactInfo = styled.div`
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid ${colors.border};
  font-size: 0.875rem;
`;

const ContactName = styled.div`
  color: ${colors.text.secondary};
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid ${colors.border};
`;

const PackageSection = styled.div``;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const DetailLabel = styled.span`
  font-weight: 500;
`;

const DetailValue = styled.span`
  color: ${colors.text.secondary};
`;

const ReferencesSection = styled.div``;

const OptionsSection = styled.div``;

const SameAsOutboundNote = styled.div`
  color: ${colors.text.secondary};
  font-size: 0.875rem;
  font-style: italic;
  margin-top: 0.25rem;
`;

const DeviceRow = styled.div<DeviceRowProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  
  &:hover {
    background-color: ${props => props.isEditing ? 'rgba(0, 0, 0, 0.05)' : 'transparent'};
  }
`;

const DeviceInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
`;

const QuantityField = styled.div`
  width: 4rem;
`;

const RemoveButton = styled.button`
  background: none;
  border: 1px solid ${colors.border};
  border-radius: 4px;
  color: ${colors.text.secondary};
  font-size: 1.25rem;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 28px;
  transition: all 0.2s ease;
  
  &:hover {
    color: #ff4444;
    border-color: #ff4444;
    background-color: rgba(255, 68, 68, 0.1);
  }
`;

const DimensionsGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const DimensionSeparator = styled.span`
  color: ${colors.text.secondary};
`;

const WeightUnit = styled.span`
  color: ${colors.text.secondary};
  margin-left: 0.5rem;
`;

const ToggleButton = styled.button<{ isActive: boolean }>`
  padding: 0.25rem 0.75rem;
  border: 1px solid ${colors.border};
  border-radius: 0.25rem;
  background: ${props => props.isActive ? colors.primary : 'white'};
  color: ${props => props.isActive ? 'white' : colors.text.primary};
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background: ${props => props.isActive ? colors.primary : '#f5f5f5'};
  }
`;

const ContactRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Label = styled.span`
  color: ${colors.text.secondary};
  font-size: 0.875rem;
`;

const AddDeviceButton = styled.button`
  padding: 0.5rem 1rem;
  border: 1px solid ${colors.border};
  border-radius: 0.25rem;
  background: white;
  color: ${colors.primary};
  cursor: pointer;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  align-self: flex-start;
  
  &:hover {
    background: ${colors.primary};
    color: white;
  }
`;

const ContactCard = styled.div`
  background-color: #f8f9fa;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 2rem;
`;

const ContactCardTitle = styled.h3`
  font-size: 1rem;
  color: ${colors.text.primary};
  margin-bottom: 0.75rem;
`;

const ContactField = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

const ContactLabel = styled.span`
  color: ${colors.text.secondary};
  font-size: 0.875rem;
  min-width: 100px;
`;

const ShipmentSummaryCard: React.FC<ShipmentSummaryCardProps> = ({
  type,
  date,
  quantity,
  trialId,
  address,
  service,
  contents,
  estimatedCost,
  onEdit,
  onServiceClick,
  onDeviceClick,
  isEditing = false,
  contactInfo,
  packageDetails,
  pickupDetails,
  references,
  options,
  onAddressClick,
  onContactUpdate,
  onDeviceUpdate,
  onDeviceRemove,
  onPackageUpdate,
  onOptionsUpdate
}) => {
  return (
    <Card>
      <Header>
        <LeftSection>
          <DateText>{date}</DateText>
          {contactInfo && (
            <ContactName>Contact: {contactInfo.name}</ContactName>
          )}
        </LeftSection>
        <RightSection>
          <TrialId>Trial ID: {trialId}</TrialId>
          <EditButton onClick={onEdit}>
            {isEditing ? 'Save' : 'Edit'}
          </EditButton>
        </RightSection>
      </Header>

      <Content>
        <AddressSection onClick={isEditing ? onAddressClick : undefined} isEditable={isEditing}>
          <SectionTitle>{type === 'outbound' ? 'Deliver to:' : 'Return to:'}</SectionTitle>
          {address ? (
            <AddressText>
              {contactInfo && (
                <EditableField
                  value={contactInfo.name}
                  onSave={(value) => onContactUpdate?.('name', value)}
                  isEditing={isEditing}
                />
              )}
              <div>{address.street}</div>
              <div>{address.cityStateZip}</div>
              {contactInfo && (
                <ContactInfo>
                  <ContactRow>
                    <Label>Email:</Label>
                    <EditableField
                      value={contactInfo.email}
                      onSave={(value) => onContactUpdate?.('email', value)}
                      type="email"
                      isEditing={isEditing}
                    />
                  </ContactRow>
                  <ContactRow>
                    <Label>Phone:</Label>
                    <EditableField
                      value={contactInfo.phoneNumber}
                      onSave={(value) => onContactUpdate?.('phoneNumber', value)}
                      type="tel"
                      isEditing={isEditing}
                    />
                  </ContactRow>
                </ContactInfo>
              )}
            </AddressText>
          ) : (
            <AddressText>No address provided</AddressText>
          )}
        </AddressSection>

        <ServiceSection onClick={isEditing ? onServiceClick : undefined} isEditable={isEditing}>
          <SectionTitle>Service Level: {service.name}</SectionTitle>
          <ServiceTime>{service.time}</ServiceTime>
          {type === 'inbound' && packageDetails?.sameAsOutbound && (
            <SameAsOutboundNote>Using outbound shipment settings</SameAsOutboundNote>
          )}
        </ServiceSection>

        <ContentsSection>
          <SectionTitle>Contents</SectionTitle>
          <DeviceList>
            {contents && contents.length > 0 ? (
              contents.map((device, index) => (
                <DeviceRow key={index} isEditing={isEditing}>
                  <DeviceInfo>
                    <EditableField
                      value={device.name}
                      onSave={(value) => onDeviceUpdate?.(index, 'name', value)}
                      isEditing={isEditing}
                    />
                    <QuantityField>
                      <EditableField
                        value={device.quantity.toString()}
                        onSave={(value) => onDeviceUpdate?.(index, 'quantity', parseInt(value) || 0)}
                        type="text"
                        isEditing={isEditing}
                      />
                    </QuantityField>
                  </DeviceInfo>
                  {isEditing && onDeviceRemove && (
                    <RemoveButton 
                      onClick={() => onDeviceRemove(index)}
                      title="Remove device"
                      type="button"
                    >
                      ×
                    </RemoveButton>
                  )}
                </DeviceRow>
              ))
            ) : (
              <DetailRow>
                <DetailValue>No devices selected</DetailValue>
              </DetailRow>
            )}
          </DeviceList>
          {isEditing && onDeviceClick && (
            <AddDeviceButton onClick={onDeviceClick} type="button">
              + Add Device
            </AddDeviceButton>
          )}
        </ContentsSection>

        <CostSection>
          <SectionTitle>Estimated Invoice</SectionTitle>
          <Cost>{estimatedCost}</Cost>
        </CostSection>

        {type === 'inbound' && packageDetails && (
          <PackageSection>
            <SectionTitle>Package Details</SectionTitle>
            <DetailRow>
              <DetailLabel>Weight:</DetailLabel>
              <EditableField
                value={packageDetails.weight}
                onSave={(value) => onPackageUpdate?.('weight', value)}
                type="text"
                isEditing={isEditing}
              />
              <WeightUnit>lbs</WeightUnit>
            </DetailRow>
            {packageDetails.dimensions && (
              <DetailRow>
                <DetailLabel>Dimensions:</DetailLabel>
                <DimensionsGroup>
                  <EditableField
                    value={packageDetails.dimensions.length}
                    onSave={(value) => onPackageUpdate?.('length', value)}
                    type="text"
                    isEditing={isEditing}
                  />
                  <DimensionSeparator>×</DimensionSeparator>
                  <EditableField
                    value={packageDetails.dimensions.width}
                    onSave={(value) => onPackageUpdate?.('width', value)}
                    type="text"
                    isEditing={isEditing}
                  />
                  <DimensionSeparator>×</DimensionSeparator>
                  <EditableField
                    value={packageDetails.dimensions.height}
                    onSave={(value) => onPackageUpdate?.('height', value)}
                    type="text"
                    isEditing={isEditing}
                  />
                </DimensionsGroup>
              </DetailRow>
            )}
          </PackageSection>
        )}

        {options && (
          <OptionsSection>
            <SectionTitle>Shipping Options</SectionTitle>
            <DetailRow>
              <DetailLabel>Signature Required:</DetailLabel>
              {isEditing ? (
                <ToggleButton
                  onClick={() => onOptionsUpdate?.('requireSignature', !options.requireSignature)}
                  isActive={options.requireSignature}
                >
                  {options.requireSignature ? 'Yes' : 'No'}
                </ToggleButton>
              ) : (
                <DetailValue>{options.requireSignature ? 'Yes' : 'No'}</DetailValue>
              )}
            </DetailRow>
          </OptionsSection>
        )}

        {references && (
          <ReferencesSection>
            <SectionTitle>References</SectionTitle>
            {references.shipmentId && (
              <DetailRow>
                <DetailLabel>Shipment ID:</DetailLabel>
                <DetailValue>{references.shipmentId}</DetailValue>
              </DetailRow>
            )}
          </ReferencesSection>
        )}
      </Content>

      {contactInfo && (
        <ContactCard>
          <ContactCardTitle>Contact Information</ContactCardTitle>
          <ContactField>
            <ContactLabel>Contact Name:</ContactLabel>
            <EditableField
              value={contactInfo.name}
              onSave={(value) => onContactUpdate?.('name', value)}
              isEditing={isEditing}
            />
          </ContactField>
          <ContactField>
            <ContactLabel>Email:</ContactLabel>
            <EditableField
              value={contactInfo.email}
              onSave={(value) => onContactUpdate?.('email', value)}
              type="email"
              isEditing={isEditing}
            />
          </ContactField>
          <ContactField>
            <ContactLabel>Phone:</ContactLabel>
            <EditableField
              value={contactInfo.phoneNumber}
              onSave={(value) => onContactUpdate?.('phoneNumber', value)}
              type="tel"
              isEditing={isEditing}
            />
          </ContactField>
          {contactInfo.phoneExtension && (
            <ContactField>
              <ContactLabel>Extension:</ContactLabel>
              <EditableField
                value={contactInfo.phoneExtension}
                onSave={(value) => onContactUpdate?.('phoneExtension', value)}
                type="text"
                isEditing={isEditing}
              />
            </ContactField>
          )}
          {contactInfo.company && (
            <ContactField>
              <ContactLabel>Company:</ContactLabel>
              <EditableField
                value={contactInfo.company}
                onSave={(value) => onContactUpdate?.('company', value)}
                type="text"
                isEditing={isEditing}
              />
            </ContactField>
          )}
        </ContactCard>
      )}
    </Card>
  );
};

export default ShipmentSummaryCard;