import React, { createContext, useContext, useState, useEffect } from 'react';
import type { Shipment, ShipmentProfile } from '../types/models/shipment';
import { Address } from 'types/models/address';
import { CollectionDevice } from '../types/models/device';
import { SHIPPING_SERVICES, ShippingService } from '../types/models/shipping';

interface SendOutKitsContextType {
  selectedTrialId: string;
  setSelectedTrialId: (id: string) => void;
  selectedShipment: string;
  setSelectedShipment: (id: string) => void;
  selectedProfile: ShipmentProfile | null;
  setSelectedProfile: (profile: ShipmentProfile | null) => void;
  selectedService: string;
  setSelectedService: (service: string) => void;
  outboundShipment: OutboundShipment;
  setOutboundShipment: (data: Partial<OutboundShipment>) => void;
  updateOutboundShipment: (data: OutboundShipment) => void;
  returnShipment: ReturnShipment | null;
  setReturnShipment: (data: Partial<ReturnShipment> | null) => void;
  updateReturnShipment: (data: ReturnShipment) => void;
  collectionDevices: CollectionDevice[];
  setCollectionDevices: (devices: CollectionDevice[]) => void;
  formData: any;
  setFormData: (data: any) => void;
  clearStorage: () => void;
}

interface ContactInfo {
  name: string;
  email: string;
  phoneNumber: string;
  phoneExtension: string;
  company?: string;
}

export interface Dimensions {
  length: string;
  width: string;
  height: string;
}

export interface OutboundShipment {
  service: string;
  address: Address | null;
  pickupDate: Date | null;
  options: {
    requireSignature: boolean;
    holdAtLocation: boolean;
    locationZip?: string;
  };
  devices: CollectionDevice[];
  estimatedCost?: string;
  deliveryDate?: Date;
  contactInfo?: ContactInfo;
  packageDetails?: {
    weight?: string;
    dimensions?: Dimensions;
    kitName?: string;
  };
  metadata?: {
    emailNotifications?: boolean;
    notificationEmails?: string[];
    [key: string]: any;
  };
}

export interface ReturnShipment {
  service: string;
  address: Address | null;
  deliveryDate: Date | null;
  options: {
    requireSignature: boolean;
    holdAtLocation: boolean;
    locationZip?: string;
  };
  devices: CollectionDevice[];
  estimatedCost?: string;
  contactInfo?: ContactInfo;
  packageDetails?: {
    weight?: string;
    dimensions?: Dimensions;
    kitName?: string;
    sameAsOutbound?: boolean;
  };
  pickupDetails?: {
    date?: Date;
    timeWindow?: {
      earliest: string;
      latest: string;
    };
    location?: string;
  };
  references?: {
    shipmentId?: string;
    poNumber?: string;
    invoiceNumber?: string;
    departmentNumber?: string;
  };
}

const SendOutKitsContext = createContext<SendOutKitsContextType | undefined>(undefined);

export const SendOutKitsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedTrialId, setSelectedTrialId] = useState('');
  const [selectedShipment, setSelectedShipment] = useState('');
  const [selectedProfile, setSelectedProfile] = useState<ShipmentProfile | null>(null);
  const [selectedService, setSelectedService] = useState<string>('');
  
  // Load initial form data from localStorage
  const [formData, setFormData] = useState<any>(() => {
    const saved = localStorage.getItem('sendOutKitsFormData');
    return saved ? JSON.parse(saved) : {};
  });

  // Save form data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('sendOutKitsFormData', JSON.stringify(formData));
  }, [formData]);

  // Load initial outbound shipment from localStorage
  const [outboundShipment, setOutboundShipmentState] = useState<OutboundShipment>(() => {
    const saved = localStorage.getItem('outboundShipment');
    return saved ? JSON.parse(saved) : {
      service: '',
      address: null,
      pickupDate: null,
      options: {
        requireSignature: false,
        holdAtLocation: false
      },
      devices: []
    };
  });

  // Save outbound shipment to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('outboundShipment', JSON.stringify(outboundShipment));
  }, [outboundShipment]);

  // Load initial return shipment from localStorage
  const [returnShipment, setReturnShipmentState] = useState<ReturnShipment | null>(() => {
    const saved = localStorage.getItem('returnShipment');
    return saved ? JSON.parse(saved) : null;
  });

  // Save return shipment to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('returnShipment', JSON.stringify(returnShipment));
  }, [returnShipment]);

  const [collectionDevices, setCollectionDevices] = useState<CollectionDevice[]>([]);

  const getServiceDetails = (serviceName: string): ShippingService | undefined => {
    return SHIPPING_SERVICES.find(s => s.name === serviceName);
  };

  const addDays = (date: Date | null, days: number): Date | null => {
    if (!date) return null;
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const calculateDeliveryDate = (serviceName: string, pickupDate: Date | null): Date | undefined => {
    if (!pickupDate) return undefined;
    
    // Extract delivery days from service time string (e.g., "N(2)BD" means 2 days)
    const serviceDetails = getServiceDetails(serviceName);
    if (!serviceDetails) return undefined;
    
    const daysMatch = serviceDetails.deliveryTime.match(/N\((\d+)\)BD/);
    const days = daysMatch ? parseInt(daysMatch[1]) : 1;
    
    return addDays(pickupDate, days) || undefined;
  };

  const setOutboundShipment = (data: Partial<OutboundShipment>) => {
    setOutboundShipmentState(prev => {
      const updated = {
        ...prev,
        ...data,
        contactInfo: data.contactInfo || prev.contactInfo
      };
      
      console.log('Previous state:', prev);
      console.log('Update data:', data);
      console.log('Updated state:', updated);
      
      return updated;
    });
  };


  const setReturnShipment = (data: Partial<ReturnShipment> | null) => {
    if (data === null) {
      setReturnShipmentState(null);
    } else {
      setReturnShipmentState(prev => {
        if (!prev) {
          return {
            service: data.service || '',
            address: data.address || null,
            deliveryDate: data.deliveryDate || null,
            options: {
              requireSignature: data.options?.requireSignature || false,
              holdAtLocation: data.options?.holdAtLocation || false
            },
            devices: data.devices || [],
            pickupDetails: data.pickupDetails || {},
            references: data.references || {}
          };
        }
        return {
          ...prev,
          ...data,
          options: {
            ...prev.options,
            ...data.options
          }
        };
      });
    }
  };

  const updateOutboundShipment = (data: OutboundShipment) => {
    setOutboundShipmentState(data);
  };

  const updateReturnShipment = (data: ReturnShipment) => {
    setReturnShipmentState(data);
  };

  const clearStorage = () => {
    localStorage.removeItem('sendOutKitsFormData');
    localStorage.removeItem('outboundShipment');
    localStorage.removeItem('returnShipment');
    setFormData({});
    setOutboundShipmentState({
      service: '',
      address: null,
      pickupDate: null,
      options: {
        requireSignature: false,
        holdAtLocation: false
      },
      devices: []
    });
    setReturnShipmentState(null);
  };

  return (
    <SendOutKitsContext.Provider value={{
      selectedTrialId,
      setSelectedTrialId,
      selectedShipment,
      setSelectedShipment,
      selectedProfile,
      setSelectedProfile,
      selectedService,
      setSelectedService,
      outboundShipment,
      setOutboundShipment,
      updateOutboundShipment,
      returnShipment,
      setReturnShipment,
      updateReturnShipment,
      collectionDevices,
      setCollectionDevices,
      formData,
      setFormData,
      clearStorage
    }}>
      {children}
    </SendOutKitsContext.Provider>
  );
};

export const useSendOutKits = () => {
  const context = useContext(SendOutKitsContext);
  if (context === undefined) {
    throw new Error('useSendOutKits must be used within a SendOutKitsProvider');
  }
  return context;
}; 