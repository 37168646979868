import type { Address } from './address';
import type { Device } from './device';
import type { Site } from './site';
import type { Trial } from './trial';

export enum ShipmentStatus {
  PENDING = 'PENDING',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
  FAILED_PICKUP = 'FAILED_PICKUP'
}

export interface BasicShipment {
  id: string;
  tracking_number: string;
  carrier: string;
  service_type: string;
  status: ShipmentStatus;
  expected_delivery: string;
  pickup_date?: string;
  cost?: number | string;
  origin: string;
  destination: string;
  type: string;
  requires_signature?: boolean;
  hold_at_location?: boolean;
  trial_id?: string;
  kit_id?: string;
  failures?: any[];
  created_at: string;
  sample_ids?: string[];
}

export interface Shipment extends BasicShipment {
  trial?: Trial;
  site?: Site;
  devices?: Device[];
  origin_address?: Address;
  destination_address?: Address;
}

export interface CreateShipmentDTO {
  tracking_number: string;
  carrier: string;
  service_type: string;
  status: ShipmentStatus;
  expected_delivery: string;
  pickup_date?: string;
  origin: string;
  destination: string;
  type: string;
  requires_signature?: boolean;
  hold_at_location?: boolean;
  trial_id?: string;
  kit_id?: string;
  cost?: number | string;
}

export interface UpdateShipmentDTO {
  tracking_number?: string;
  carrier?: string;
  service_type?: string;
  status?: ShipmentStatus;
  expected_delivery?: string;
  pickup_date?: string;
  origin?: string;
  destination?: string;
  type?: string;
  requires_signature?: boolean;
  hold_at_location?: boolean;
  trial_id?: string;
  kit_id?: string;
  cost?: number | string;
}

export interface ShipmentProfile {
  id: string;
  name: string;
  carrier: string;
  service_level: string;
  is_return: boolean;
  created_at: string;
  updated_at: string;
}