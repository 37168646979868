import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../components/common/Layout/PageLayout';
import Select from '../../components/common/Select/Select';
import worldmap from '../../assets/shipmentmanagerglobe.png';
import InventoryLevelsTable from '../../components/Inventory/InventoryLevelsTable';

interface KitDetails {
  kitId: string;
  outstandingKits: number;
  manufacturer: string;
  kitName: string;
  expirationDate: string;
  batchNo: string;
  sampleType: string;
  tempRange: string;
  isHazardous: boolean;
}

interface InventoryKit {
  name: string;
  inventory: number;
  threshold: number;
  restockStatus: 'Scheduled' | 'Pending' | 'None';
}

interface SiteInventory {
  siteId: string;
  kits: InventoryKit[];
}

interface Shipment {
  trackingNumber: string;
  origin: string;
  destination: string;
  site: string;
  study: string;
  status: 'Cancelled' | 'Delayed' | 'On Time';
  expectedArrival: string;
  expectedArrivalDate: Date;
  sampleIds: string[];
  type: 'inbound' | 'outbound';
}

interface Kit {
  name: string;
  inventory: number;
  threshold: number;
  restockStatus: 'Scheduled' | 'Pending' | 'None';
}

interface StudyGroup {
  studyId: string;
  kits: Kit[];
}

const STARRED_SHIPMENTS_KEY = 'kitOverviewStarredShipments';

const KitInventoryWrapper: React.FC<{
  data: StudyGroup[];
  onRestock: (siteId: string, kitName: string) => void;
}> = ({ data, onRestock }) => {
  const [siteFilter, setSiteFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const [timeFilter, setTimeFilter] = useState('all');

  // Filter data based on selected site
  const filteredData = siteFilter === 'all' 
    ? data 
    : data.filter(group => group.studyId === siteFilter);

  return (
    <div>
      <TableHeader>
        <Title>Inventory Levels</Title>
        <FilterContainer>
          <FilterGroup>
            <FilterLabel>Active Sites</FilterLabel>
            <Select
              placeholder="Active Sites"
              value={siteFilter}
              onChange={setSiteFilter}
              options={[
                { value: 'all', label: 'All Sites' },
                { value: 'SITE001', label: 'SITE001' },
                { value: 'SITE002', label: 'SITE002' },
                { value: 'SITE003', label: 'SITE003' }
              ]}
            />
          </FilterGroup>
        </FilterContainer>
      </TableHeader>
      <InventoryLevelsTable
        data={filteredData}
        onRestock={onRestock}
        studyFilter={siteFilter}
        statusFilter={statusFilter}
        timeFilter={timeFilter}
        onStudyFilterChange={setSiteFilter}
        onStatusFilterChange={setStatusFilter}
        onTimeFilterChange={setTimeFilter}
      />
    </div>
  );
};

const KitOverviewPage: React.FC = () => {
  const navigate = useNavigate();
  const [studyFilter, setStudyFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const [timeFilter, setTimeFilter] = useState('latest');
  const [starredShipments, setStarredShipments] = useState<string[]>(() => {
    const saved = localStorage.getItem(STARRED_SHIPMENTS_KEY);
    return saved ? JSON.parse(saved) : [];
  });

  // New state for inventory levels
  const [inventoryStudyFilter, setInventoryStudyFilter] = useState('all');
  const [inventoryStatusFilter, setInventoryStatusFilter] = useState('all');
  const [inventoryTimeFilter, setInventoryTimeFilter] = useState('all');

  // Mock data - replace with real data later
  const kitDetails: KitDetails = {
    kitId: '749ADNJD',
    outstandingKits: 455,
    manufacturer: 'Cardinal Health',
    kitName: 'Tasso v0.2',
    expirationDate: '1/1/28',
    batchNo: '214241414',
    sampleType: 'Blood',
    tempRange: '2-8°C',
    isHazardous: false
  };

  const mockShipments: Shipment[] = [
    {
      trackingNumber: '788208439645',
      origin: 'SITE001',
      destination: '',
      site: 'SITE001',
      study: 'SITE001',
      status: 'Cancelled',
      expectedArrival: 'Unknown',
      expectedArrivalDate: new Date(),
      sampleIds: ['9946', '9947'],
      type: 'inbound'
    },
    {
      trackingNumber: '788208439645',
      origin: 'SITE002',
      destination: '',
      site: 'SITE002',
      study: 'SITE002',
      status: 'Delayed',
      expectedArrival: '10/2/24 10:30am',
      expectedArrivalDate: new Date(2024, 9, 2, 10, 30),
      sampleIds: ['9948'],
      type: 'inbound'
    },
    {
      trackingNumber: '788208439645',
      origin: 'SITE003',
      destination: '',
      site: 'SITE003',
      study: 'SITE003',
      status: 'On Time',
      expectedArrival: '10/1/24 10:30am',
      expectedArrivalDate: new Date(2024, 9, 1, 10, 30),
      sampleIds: ['9949'],
      type: 'inbound'
    },
    {
      trackingNumber: '788208439645',
      origin: '',
      destination: 'SITE001',
      site: 'SITE001',
      study: 'SITE001',
      status: 'Delayed',
      expectedArrival: '10/2/24 10:30am',
      expectedArrivalDate: new Date(2024, 9, 2, 10, 30),
      sampleIds: ['9950'],
      type: 'outbound'
    },
    {
      trackingNumber: '788208439645',
      origin: '',
      destination: 'SITE002',
      site: 'SITE002',
      study: 'SITE002',
      status: 'Cancelled',
      expectedArrival: 'Unknown',
      expectedArrivalDate: new Date(),
      sampleIds: ['9951', '9952'],
      type: 'outbound'
    },
    {
      trackingNumber: '788208439645',
      origin: '',
      destination: 'SITE003',
      site: 'SITE003',
      study: 'SITE003',
      status: 'On Time',
      expectedArrival: '10/1/24 10:30am',
      expectedArrivalDate: new Date(2024, 9, 1, 10, 30),
      sampleIds: ['9953'],
      type: 'outbound'
    }
  ];

  // Mock data for inventory levels
  const mockInventoryData: StudyGroup[] = [
    {
      studyId: 'SITE001',
      kits: [
        {
          name: 'Timestrip and Box v0.2',
          inventory: 3,
          threshold: 5,
          restockStatus: 'Scheduled'
        },
        {
          name: 'Timestrip and Box v0.1',
          inventory: 6,
          threshold: 5,
          restockStatus: 'Pending'
        },
        {
          name: 'Tasso Kit v0.1',
          inventory: 4,
          threshold: 3,
          restockStatus: 'None'
        }
      ]
    },
    {
      studyId: 'SITE002',
      kits: [
        {
          name: 'Tasso Kit v0.1',
          inventory: 5,
          threshold: 2,
          restockStatus: 'None'
        }
      ]
    },
    {
      studyId: 'SITE003',
      kits: [
        {
          name: 'Timestrip and Box v0.1',
          inventory: 5,
          threshold: 2,
          restockStatus: 'None'
        }
      ]
    }
  ];

  const getRowId = (shipment: Shipment) => {
    return `${shipment.type}-${shipment.trackingNumber}-${shipment.origin || shipment.destination}`;
  };

  const handleToggleShipmentStar = (shipment: Shipment) => {
    const rowId = getRowId(shipment);
    setStarredShipments(prev => 
      prev.includes(rowId)
        ? prev.filter(id => id !== rowId)
        : [...prev, rowId]
    );
  };

  const filterShipments = (shipments: Shipment[]) => {
    // Sort shipments to put starred items first
    const sortedShipments = [...shipments].sort((a, b) => {
      const aIsStarred = starredShipments.includes(getRowId(a));
      const bIsStarred = starredShipments.includes(getRowId(b));
      if (aIsStarred && !bIsStarred) return -1;
      if (!aIsStarred && bIsStarred) return 1;
      return 0;
    });

    return sortedShipments.filter(shipment => {
      // Always show starred items
      if (starredShipments.includes(getRowId(shipment))) {
        return true;
      }

      const matchesStudy = studyFilter === 'all' || shipment.study === studyFilter;
      const statusText = shipment.status.toLowerCase();
      const matchesStatus = statusFilter === 'all' || statusText === statusFilter.toLowerCase();

      if (matchesStudy && matchesStatus) {
        return true;
      }

      return false;
    }).sort((a, b) => {
      // Compare dates
      return timeFilter === 'latest'
        ? b.expectedArrivalDate.getTime() - a.expectedArrivalDate.getTime()
        : a.expectedArrivalDate.getTime() - b.expectedArrivalDate.getTime();
    });
  };

  const getStatusColor = (status: string): string => {
    switch (status) {
      case 'Cancelled':
        return '#FF0000';
      case 'On Time':
        return '#00A651';
      case 'Delayed':
        return '#FFA500';
      default:
        return '#666666';
    }
  };

  const handleSeeAll = () => {
    setStudyFilter('all');
    setStatusFilter('all');
    setTimeFilter('latest');
  };

  const handleRestock = (studyId: string, kitName: string) => {
    console.log(`Restocking ${kitName} for study ${studyId}`);
  };

  useEffect(() => {
    localStorage.setItem(STARRED_SHIPMENTS_KEY, JSON.stringify(starredShipments));
  }, [starredShipments]);

  const filteredShipments = filterShipments(mockShipments);

  return (
    <PageLayout>
      <Container>
        <HeaderContainer>
          <Title>Kit Overview</Title>
          <ActionButtonsContainer>
            <ActionButton onClick={() => navigate('/send-kits')}>
              Send Out Kits
            </ActionButton>
            <ActionButton onClick={() => navigate('/track-shipments')}>
              Track My Shipments
            </ActionButton>
            <ActionButton onClick={() => navigate('/schedule-pickup')}>
              Schedule Pickups
            </ActionButton>
          </ActionButtonsContainer>
        </HeaderContainer>

        <ContentSection>
          <MapSection>
            <WorldMap src={worldmap} alt="World Map" />
          </MapSection>

          <KitDetailsCard>
            <DetailTitle>Kit ID#{kitDetails.kitId}</DetailTitle>
            <DetailRow>
              <DetailLabel>Outstanding Kits:</DetailLabel>
              <DetailValue>{kitDetails.outstandingKits}</DetailValue>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Manufacturer:</DetailLabel>
              <DetailValue>{kitDetails.manufacturer}</DetailValue>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Kit Name:</DetailLabel>
              <DetailValue>{kitDetails.kitName}</DetailValue>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Expiration Date:</DetailLabel>
              <DetailValue>{kitDetails.expirationDate}</DetailValue>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Batch No.:</DetailLabel>
              <DetailValue>{kitDetails.batchNo}</DetailValue>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Sample Type:</DetailLabel>
              <DetailValue>{kitDetails.sampleType}</DetailValue>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Temp Range:</DetailLabel>
              <DetailValue>{kitDetails.tempRange}</DetailValue>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Hazardous:</DetailLabel>
              <DetailValue>{kitDetails.isHazardous ? 'Yes' : 'No'}</DetailValue>
            </DetailRow>
          </KitDetailsCard>
        </ContentSection>

        <KitInventoryWrapper
          data={mockInventoryData}
          onRestock={handleRestock}
        />

        <ShipmentsSection>
          <ShipmentsHeader>
            <ShipmentsTitle>Historical Shipments</ShipmentsTitle>
            <FilterContainer>
              <FilterGroup>
                <FilterLabel>Study</FilterLabel>
                <Select
                  placeholder="Study"
                  value={studyFilter}
                  onChange={setStudyFilter}
                  options={[
                    { value: 'all', label: 'All Studies' },
                    { value: 'SITE001', label: 'SITE001' },
                    { value: 'SITE002', label: 'SITE002' },
                    { value: 'SITE003', label: 'SITE003' }
                  ]}
                />
              </FilterGroup>
              <FilterGroup>
                <FilterLabel>Status</FilterLabel>
                <Select
                  placeholder="Status"
                  value={statusFilter}
                  onChange={setStatusFilter}
                  options={[
                    { value: 'all', label: 'All Statuses' },
                    { value: 'On Time', label: 'On Time' },
                    { value: 'Delayed', label: 'Delayed' },
                    { value: 'Cancelled', label: 'Cancelled' }
                  ]}
                />
              </FilterGroup>
              <FilterGroup>
                <FilterLabel>Date</FilterLabel>
                <Select
                  placeholder="Date"
                  value={timeFilter}
                  onChange={setTimeFilter}
                  options={[
                    { value: 'latest', label: 'Latest Date' },
                    { value: 'earliest', label: 'Earliest Date' }
                  ]}
                />
              </FilterGroup>
              <SeeAllButton onClick={handleSeeAll}>
                See All
              </SeeAllButton>
            </FilterContainer>
          </ShipmentsHeader>

          <ShipmentsTable>
            <thead>
              <tr>
                <Th>Tracking #</Th>
                <Th>Origin/Destination</Th>
                <Th>Study</Th>
                <Th>Status</Th>
                <Th>Expected Arrival</Th>
                <Th>Sample Id[s]</Th>
                <Th></Th>
              </tr>
            </thead>
            <tbody>
              {/* Inbound Shipments */}
              {filteredShipments.filter(s => s.type === 'inbound').length > 0 && (
                <>
                  <TableRow isHeader>
                    <Td colSpan={7}>Inbound</Td>
                  </TableRow>
                  {filteredShipments
                    .filter(s => s.type === 'inbound')
                    .map((shipment, index) => (
                      <TableRow 
                        key={`${shipment.trackingNumber}-${index}`}
                        isFirstInGroup={false}
                        isLastInGroup={false}
                      >
                        <Td>{shipment.trackingNumber}</Td>
                        <Td>{shipment.origin}</Td>
                        <Td>{shipment.study}</Td>
                        <Td>
                          <StatusText color={getStatusColor(shipment.status)}>
                            {shipment.status}
                          </StatusText>
                        </Td>
                        <Td>{shipment.expectedArrival}</Td>
                        <Td>
                          <SampleIdList>
                            {shipment.sampleIds.map((id, i) => (
                              <SampleId key={id}>{id}</SampleId>
                            ))}
                          </SampleIdList>
                        </Td>
                        <Td>
                          <StarButton 
                            onClick={() => handleToggleShipmentStar(shipment)}
                            isStarred={starredShipments.includes(getRowId(shipment))}
                          >
                            ★
                          </StarButton>
                        </Td>
                      </TableRow>
                    ))}
                </>
              )}

              {/* Outbound Shipments */}
              {filteredShipments.filter(s => s.type === 'outbound').length > 0 && (
                <>
                  <TableRow isHeader>
                    <Td colSpan={7}>Outbound</Td>
                  </TableRow>
                  {filteredShipments
                    .filter(s => s.type === 'outbound')
                    .map((shipment, index) => (
                      <TableRow 
                        key={`${shipment.trackingNumber}-${index}`}
                        isFirstInGroup={false}
                        isLastInGroup={false}
                      >
                        <Td>{shipment.trackingNumber}</Td>
                        <Td>{shipment.destination}</Td>
                        <Td>{shipment.study}</Td>
                        <Td>
                          <StatusText color={getStatusColor(shipment.status)}>
                            {shipment.status}
                          </StatusText>
                        </Td>
                        <Td>{shipment.expectedArrival}</Td>
                        <Td>
                          <SampleIdList>
                            {shipment.sampleIds.map((id, i) => (
                              <SampleId key={id}>{id}</SampleId>
                            ))}
                          </SampleIdList>
                        </Td>
                        <Td>
                          <StarButton 
                            onClick={() => handleToggleShipmentStar(shipment)}
                            isStarred={starredShipments.includes(getRowId(shipment))}
                          >
                            ★
                          </StarButton>
                        </Td>
                      </TableRow>
                    ))}
                </>
              )}
            </tbody>
          </ShipmentsTable>
        </ShipmentsSection>
      </Container>
    </PageLayout>
  );
};

const Container = styled.div`
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin: 0;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

const ContentSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
`;

const MapSection = styled.div`
  height: 300px;
  border-radius: 8px;
  overflow: hidden;
`;

const WorldMap = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const KitDetailsCard = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid #E0E0E0;
`;

const DetailTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 16px 0;
`;

const DetailRow = styled.div`
  margin-bottom: 12px;
`;

const DetailLabel = styled.span`
  font-weight: 500;
  margin-right: 8px;
`;

const DetailValue = styled.span`
  color: #666;
`;

const ShipmentsSection = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid #E0E0E0;
`;

const ShipmentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const ShipmentsTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin: 0;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-end;
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const FilterLabel = styled.label`
  font-size: 12px;
  color: #666;
  font-weight: 500;
`;

const SeeAllButton = styled.button`
  padding: 8px 16px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

const ShipmentsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  text-align: left;
  padding: 12px 16px;
  border-bottom: 1px solid #E0E0E0;
  color: #666;
  font-weight: 500;
  font-size: 14px;
`;

interface TableRowProps {
  isFirstInGroup?: boolean;
  isLastInGroup?: boolean;
  isHeader?: boolean;
}

const TableRow = styled.tr<TableRowProps>`
  ${props => props.isFirstInGroup && `
    border-top: 1px solid #E0E0E0;
  `}
  
  ${props => props.isLastInGroup && `
    border-bottom: 1px solid #E0E0E0;
  `}

  ${props => props.isHeader && `
    background: #F5F5F5;
    font-weight: 500;
  `}
`;

const Td = styled.td`
  padding: 12px 16px;
  color: #666;
  font-size: 14px;
  background: inherit;
`;

const StatusText = styled.span<{ color: string }>`
  color: ${props => props.color};
  font-weight: 500;
`;

const StarButton = styled.button<{ isStarred: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.isStarred ? '#FFD700' : '#E0E0E0'};
  font-size: 20px;
  padding: 4px;
  transition: color 0.2s ease;
  
  &:hover {
    color: ${props => props.isStarred ? '#FFC000' : '#FFD700'};
  }
`;

const SampleIdList = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const SampleId = styled.span`
  background: #F5F5F5;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export default KitOverviewPage;
