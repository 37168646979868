export const breakpoints = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
};

export const colors = {
  primary: '#007AFF',
  border: '#E0E0E0',
  text: {
    primary: '#333333',
    secondary: '#666666',
    placeholder: '#999999'
  }
};

export const spacing = {
  xs: '0.5rem',   // 8px
  sm: '1rem',     // 16px
  md: '1.5rem',   // 24px
  lg: '2rem',     // 32px
  xl: '3rem'      // 48px
}; 