import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../client';
import { ENDPOINTS } from '../config';
import type { ApiResponse, PaginatedApiResponse } from '../../types/api';
import type { Device, IoTDevice } from '../../types/models/device';

interface DeviceFilters {
  search?: string;
  manufacturer?: string;
  sample_type?: string;
}

export const deviceApi = {
  // Standard Device operations
  getDevices: async (filters?: DeviceFilters): Promise<Device[]> => {
    const response = await api.get(ENDPOINTS.devices.base, { params: filters });
    console.log('Device API Response:', response);
    return response.data;
  },

  getDevice: async (id: string): Promise<ApiResponse<Device>> => {
    return api.get(ENDPOINTS.devices.byId(id));
  },

  createDevice: async (data: Partial<Device>): Promise<ApiResponse<Device>> => {
    return api.post(ENDPOINTS.devices.base, data);
  },

  updateDevice: async (id: string, data: Partial<Device>): Promise<ApiResponse<Device>> => {
    return api.put(ENDPOINTS.devices.byId(id), data);
  },

  // IoT Device operations
  getIoTDevices: async (): Promise<ApiResponse<PaginatedApiResponse<IoTDevice>>> => {
    return api.get(ENDPOINTS.devices.iot);
  },

  getIoTDevice: async (id: string): Promise<ApiResponse<IoTDevice>> => {
    return api.get(ENDPOINTS.devices.iotById(id));
  },

  updateIoTDevice: async (id: string, data: Partial<IoTDevice>): Promise<ApiResponse<IoTDevice>> => {
    return api.put(ENDPOINTS.devices.iotById(id), data);
  },

  // Special operations
  checkBatchExpiry: async (batchNo: string): Promise<ApiResponse<{ is_expired: boolean }>> => {
    return api.get(`${ENDPOINTS.devices.base}/check-batch/${batchNo}`);
  }
};

// React Query hooks
export const useDevices = (params?: DeviceFilters) => {
  return useQuery(['devices', params], () => deviceApi.getDevices(params));
};

export const useDevice = (id: string) => {
  return useQuery(
    ['device', id], 
    () => deviceApi.getDevice(id),
    { enabled: !!id }
  );
};

export const useCreateDevice = () => {
  return useMutation(deviceApi.createDevice);
};

export const useUpdateDevice = () => {
  return useMutation(
    ({ id, data }: { id: string; data: Partial<Device> }) => 
      deviceApi.updateDevice(id, data)
  );
};

// IoT Device hooks
export const useIoTDevices = () => {
  return useQuery(['iotDevices'], deviceApi.getIoTDevices);
};

export const useIoTDevice = (id: string) => {
  return useQuery(
    ['iotDevice', id], 
    () => deviceApi.getIoTDevice(id),
    { enabled: !!id }
  );
};

export const useUpdateIoTDevice = () => {
  return useMutation(
    ({ id, data }: { id: string; data: Partial<IoTDevice> }) => 
      deviceApi.updateIoTDevice(id, data)
  );
};

export const useCollectionDevices = (searchTerm?: string) => {
  return useQuery(
    ['collectionDevices', searchTerm],
    () => deviceApi.getDevices({ search: searchTerm }),
    {
      enabled: searchTerm !== undefined && searchTerm.length > 0,
      select: (response) => {
        console.log('Raw API Response:', response);
        if (!response) return [];
        return response || [];
      },
      staleTime: 30000
    }
  );
}; 