import { useQuery } from '@tanstack/react-query';
import { deviceApi } from '../api/endpoints/devices';
import type { Device } from '../types/models/device';
import type { PaginatedApiResponse } from '../types/api';

interface DeviceFilters {
  search?: string;
  type?: string;
  limit?: number;
}

export const useCollectionDevices = (searchTerm?: string) => {
  return useQuery<Device[]>(
    ['collectionDevices', searchTerm],
    async () => {
      const response = await deviceApi.getDevices({ 
        //search: searchTerm,
        //limit: 10 
      });
      console.log('🔬 Collection devices response:', response);
      return response;
    },
    {
      enabled: true,
      staleTime: 300000,
      keepPreviousData: true
    }
  );
}; 