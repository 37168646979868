import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import PageLayout from '../../components/common/Layout/PageLayout';
import { Shipment, ShipmentStatus, UpdateShipmentDTO } from '../../types/models/shipment';
import { ShipmentService, useUpdateShipment } from '../../api/endpoints/shipments';
import { useQueryClient } from '@tanstack/react-query';

interface EditableFields {
  tracking_number: string;
  carrier: string;
  service_type: string;
  status: ShipmentStatus;
  pickup_date: string;
  expected_delivery: string;
  cost: string;
  type: string;
  trial_id: string;
  kit_id: string;
  requires_signature: string;
  hold_at_location: string;
}

const ShipmentProfile: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [shipment, setShipment] = useState<Shipment | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const updateShipmentMutation = useUpdateShipment();

  // State for editable fields
  const [editableFields, setEditableFields] = useState<EditableFields>({
    tracking_number: '',
    carrier: '',
    service_type: '',
    status: ShipmentStatus.PENDING,
    pickup_date: '',
    expected_delivery: '',
    cost: '',
    type: '',
    trial_id: '',
    kit_id: '',
    requires_signature: '',
    hold_at_location: ''
  });

  useEffect(() => {
    const fetchShipmentDetails = async () => {
      try {
        setIsLoading(true);
        const data = await ShipmentService.getShipmentById(id!);
        console.log('Fetched shipment data:', data);
        if (data) {
          setShipment(data);
          // Initialize editable fields with current values
          setEditableFields({
            tracking_number: data.tracking_number || '',
            carrier: data.carrier || '',
            service_type: data.service_type || '',
            status: data.status,
            pickup_date: data.pickup_date || '',
            expected_delivery: data.expected_delivery || '',
            cost: data.cost?.toString() || '',
            type: data.type || '',
            trial_id: data.trial_id || '',
            kit_id: data.kit_id || '',
            requires_signature: data.requires_signature?.toString() || '',
            hold_at_location: data.hold_at_location?.toString() || ''
          });
        } else {
          setError('Shipment not found');
        }
      } catch (error) {
        console.error('Error fetching shipment:', error);
        setError('Failed to load shipment details');
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchShipmentDetails();
    }
  }, [id]);

  const handleEditToggle = () => {
    if (isEditing) {
      // Submit changes
      handleSubmit();
    } else {
      // When entering edit mode, make sure fields are populated with current values
      if (shipment) {
        setEditableFields({
          tracking_number: shipment.tracking_number || '',
          carrier: shipment.carrier || '',
          service_type: shipment.service_type || '',
          status: shipment.status,
          pickup_date: shipment.pickup_date || '',
          expected_delivery: shipment.expected_delivery || '',
          cost: shipment.cost?.toString() || '',
          type: shipment.type || '',
          trial_id: shipment.trial_id || '',
          kit_id: shipment.kit_id || '',
          requires_signature: shipment.requires_signature?.toString() || '',
          hold_at_location: shipment.hold_at_location?.toString() || ''
        });
      }
    }
    setIsEditing(!isEditing);
  };

  const handleFieldChange = (field: keyof EditableFields, value: string) => {
    setEditableFields(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = async () => {
    if (!id || !shipment) return;

    try {
      // Create the update DTO with only the fields that can be updated
      const updateDTO: UpdateShipmentDTO = {
        tracking_number: editableFields.tracking_number,
        carrier: editableFields.carrier,
        service_type: editableFields.service_type,
        status: editableFields.status,
        pickup_date: editableFields.pickup_date,
        expected_delivery: editableFields.expected_delivery,
        cost: editableFields.cost ? parseFloat(editableFields.cost) || editableFields.cost : undefined,
        type: editableFields.type,
        trial_id: editableFields.trial_id,
        kit_id: editableFields.kit_id,
        requires_signature: editableFields.requires_signature === 'true',
        hold_at_location: editableFields.hold_at_location === 'true'
      };

      // Optimistically update the UI
      const previousShipment = shipment;
      const optimisticShipment = {
        ...shipment,
        ...updateDTO
      };
      setShipment(optimisticShipment);
      
      // Update the cache optimistically
      queryClient.setQueryData(['shipment', id], { data: optimisticShipment });
      
      // Make the API call
      await updateShipmentMutation.mutateAsync({
        id,
        data: updateDTO
      }, {
        onSuccess: (response) => {
          // Update the cache with the server response
          queryClient.setQueryData(['shipment', id], response);
          setShipment(response.data);
          setIsEditing(false);
        },
        onError: (error) => {
          // Revert optimistic update on error
          setShipment(previousShipment);
          queryClient.setQueryData(['shipment', id], { data: previousShipment });
          console.error('Error updating shipment:', error);
        }
      });

    } catch (error) {
      console.error('Error updating shipment:', error);
    }
  };

  const formatDate = (dateString?: string) => {
    if (!dateString) return '-';
    return new Date(dateString).toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const formatBoolean = (value?: boolean) => {
    if (value === undefined) return '-';
    return value ? 'Yes' : 'No';
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error || !shipment) {
    return <div>Error: {error || 'Shipment not found'}</div>;
  }

  const renderEditableField = (label: string, field: keyof EditableFields) => (
    <DetailItem>
      <Label>{label}:</Label>
      {isEditing ? (
        <EditInput
          type="text"
          value={editableFields[field]}
          onChange={(e) => handleFieldChange(field, e.target.value)}
          placeholder={shipment[field as keyof Shipment]?.toString() || ''}
        />
      ) : (
        <Value>
          {field === 'pickup_date' || field === 'expected_delivery' 
            ? formatDate(shipment[field as keyof Shipment] as string)
            : field === 'requires_signature' || field === 'hold_at_location'
            ? formatBoolean(shipment[field as keyof Shipment] as boolean)
            : (shipment[field as keyof Shipment] || '-').toString()}
        </Value>
      )}
    </DetailItem>
  );

  const getStatusColor = (status: ShipmentStatus): string => {
    switch (status) {
      case ShipmentStatus.CANCELLED:
        return '#FF0000';
      case ShipmentStatus.IN_TRANSIT:
        return '#FFA500';
      case ShipmentStatus.DELIVERED:
        return '#00A651';
      case ShipmentStatus.PENDING:
        return '#666666';
      case ShipmentStatus.FAILED_PICKUP:
        return '#FF0000';
      default:
        return '#000000';
    }
  };

  const getStatusMessage = (status: ShipmentStatus): string => {
    switch (status) {
      case ShipmentStatus.CANCELLED:
        return 'Shipment Cancelled';
      case ShipmentStatus.IN_TRANSIT:
        return 'In Transit';
      case ShipmentStatus.DELIVERED:
        return 'Delivered';
      case ShipmentStatus.PENDING:
        return 'Pending';
      case ShipmentStatus.FAILED_PICKUP:
        return 'Failed Pickup';
      default:
        return status;
    }
  };

  const getStatusReason = (status: ShipmentStatus): string => {
    switch (status) {
      case ShipmentStatus.CANCELLED:
        return 'Shipment was cancelled by the sender.';
      case ShipmentStatus.FAILED_PICKUP:
        return 'Package not found at pickup location.';
      case ShipmentStatus.IN_TRANSIT:
        return 'Package is in transit to destination.';
      case ShipmentStatus.PENDING:
        return 'Awaiting pickup.';
      case ShipmentStatus.DELIVERED:
        return 'Package has been delivered.';
      default:
        return '';
    }
  };

  return (
    <PageLayout>
      <Container>
        <HeaderContainer>
          <Title>Shipment Profile</Title>
          <ActionButtonsContainer>
            <ActionButton onClick={() => navigate('/send-kits')}>
              Send Out Kits
            </ActionButton>
            <ActionButton onClick={() => navigate('/track-shipments')}>
              Track My Shipments
            </ActionButton>
            <ActionButton onClick={() => navigate('/schedule-pickup')}>
              Schedule Pickups
            </ActionButton>
          </ActionButtonsContainer>
        </HeaderContainer>

        <ProfileCard>
          <ProfileHeader>
            <ShipmentId>Shipment ID# {shipment.id.substring(0, 8)}</ShipmentId>
            <EditButton onClick={handleEditToggle}>
              {isEditing ? 'Submit' : 'Edit'}
            </EditButton>
          </ProfileHeader>

          <MainContent>
            <LeftColumn>
              <Section>
                <SectionTitle>{shipment.type === 'inbound' ? 'Inbound' : 'Outbound'}</SectionTitle>
              </Section>

              <Section>
                <SectionTitle>Overview</SectionTitle>
                {renderEditableField('Tracking Number', 'tracking_number')}
                {renderEditableField('Carrier', 'carrier')}
                {renderEditableField('Service Type', 'service_type')}
                {renderEditableField('Status', 'status')}
              </Section>

              <Section>
                <SectionTitle>Dates</SectionTitle>
                {renderEditableField('Pickup Date', 'pickup_date')}
                {renderEditableField('Expected Delivery', 'expected_delivery')}
              </Section>

              <Section>
                <SectionTitle>Details</SectionTitle>
                {renderEditableField('Cost', 'cost')}
                {renderEditableField('Type', 'type')}
                {renderEditableField('Trial ID', 'trial_id')}
                {renderEditableField('Kit ID', 'kit_id')}
              </Section>

              <Section>
                <SectionTitle>Service Options</SectionTitle>
                {renderEditableField('Requires Signature', 'requires_signature')}
                {renderEditableField('Hold at Location', 'hold_at_location')}
              </Section>
            </LeftColumn>

            <RightColumn>
              <OrderStatus>
                <StatusHeader>
                  <StatusTitle>Order Status</StatusTitle>
                  <Location>{shipment.origin || 'Location Unknown'}</Location>
                </StatusHeader>
                <StatusDetail>
                  <DateTime>
                    <DateSpan>{formatDate(shipment.created_at)}</DateSpan>
                  </DateTime>
                  <StatusText status={shipment.status}>
                    {getStatusMessage(shipment.status)}
                  </StatusText>
                  {getStatusReason(shipment.status) && (
                    <ReasonBox>
                      <ReasonText>Reason:</ReasonText>
                      <ReasonDetail>{getStatusReason(shipment.status)}</ReasonDetail>
                    </ReasonBox>
                  )}
                </StatusDetail>
                <UpdateTempLogButton>Update Temp Log</UpdateTempLogButton>
              </OrderStatus>
            </RightColumn>
          </MainContent>
        </ProfileCard>
      </Container>
    </PageLayout>
  );
};

// Styled Components
const Container = styled.div`
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 500;
  margin: 0;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const ActionButton = styled.button`
  padding: 12px 24px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

const ProfileCard = styled.div`
  background: white;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 24px;
`;

const ProfileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

const ShipmentId = styled.h2`
  font-size: 24px;
  color: #666;
  font-weight: normal;
`;

const EditButton = styled.button`
  padding: 8px 24px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

const MainContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
`;

const LeftColumn = styled.div``;

const RightColumn = styled.div``;

const Section = styled.div`
  margin-bottom: 32px;
`;

const SectionTitle = styled.h3`
  font-size: 20px;
  margin-bottom: 16px;
`;

const DetailItem = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: center;
`;

const Label = styled.span`
  width: 150px;
  color: #666;
`;

const Value = styled.span`
  flex: 1;
`;

const OrderStatus = styled.div`
  background: #f5f5f5;
  padding: 24px;
  border-radius: 8px;
`;

const StatusHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const StatusTitle = styled.h3`
  font-size: 20px;
  margin: 0;
`;

const Location = styled.span`
  font-weight: 500;
`;

const StatusDetail = styled.div`
  margin-bottom: 24px;
`;

const DateTime = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 8px;
`;

const DateSpan = styled.span``;
const TimeSpan = styled.span``;

const StatusText = styled.div<{ status: ShipmentStatus }>`
  color: ${(props) => {
    switch (props.status) {
      case ShipmentStatus.CANCELLED:
        return '#FF0000';
      case ShipmentStatus.IN_TRANSIT:
        return '#FFA500';
      case ShipmentStatus.DELIVERED:
        return '#00A651';
      case ShipmentStatus.PENDING:
        return '#666666';
      case ShipmentStatus.FAILED_PICKUP:
        return '#FF0000';
      default:
        return '#000000';
    }
  }};
  font-weight: 500;
  margin-bottom: 8px;
`;

const ReasonBox = styled.div`
  background: white;
  padding: 12px;
  border-radius: 4px;
`;

const ReasonText = styled.span`
  color: #666;
  margin-right: 8px;
`;

const ReasonDetail = styled.span``;

const UpdateTempLogButton = styled.button`
  width: 100%;
  padding: 12px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

const EditInput = styled.input`
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background: white;

  &:focus {
    outline: none;
    border-color: #000;
  }

  &::placeholder {
    color: #999;
  }
`;

export default ShipmentProfile; 