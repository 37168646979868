import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { colors } from '../../components/constants/breakpoints';

interface EditableFieldProps {
  value: string;
  onSave: (value: string) => void;
  type?: 'text' | 'email' | 'tel';
  style?: React.CSSProperties;
  isEditing?: boolean;
}

const EditableField: React.FC<EditableFieldProps> = ({
  value,
  onSave,
  type = 'text',
  style,
  isEditing: isEditingProp = false
}) => {
  const [isLocalEditing, setIsLocalEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isLocalEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isLocalEditing]);

  useEffect(() => {
    setEditValue(value);
  }, [value]);

  const handleBlur = () => {
    setIsLocalEditing(false);
    if (editValue !== value) {
      onSave(editValue);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleBlur();
    } else if (e.key === 'Escape') {
      setEditValue(value);
      setIsLocalEditing(false);
    }
  };

  if (isLocalEditing && isEditingProp) {
    return (
      <Input
        ref={inputRef}
        type={type}
        value={editValue}
        onChange={(e) => setEditValue(e.target.value)}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        style={style}
      />
    );
  }

  return (
    <Text 
      onClick={() => isEditingProp && setIsLocalEditing(true)} 
      style={style}
      isEditable={isEditingProp}
    >
      {value}
    </Text>
  );
};

const Text = styled.div<{ isEditable?: boolean }>`
  cursor: ${props => props.isEditable ? 'pointer' : 'default'};
  padding: 0.25rem;
  border-radius: 0.25rem;
  
  &:hover {
    background-color: ${props => props.isEditable ? 'rgba(0, 0, 0, 0.05)' : 'transparent'};
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 0.25rem;
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.25rem;
  font-size: inherit;
  font-family: inherit;
  
  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }
`;

export default EditableField;
