import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors } from '../../components/constants/breakpoints';
import { useCollectionDevices } from '../../hooks/useCollectionDevices';
import { Device } from '../../types/models/device';

interface DeviceSelectionModalProps {
  onClose: () => void;
  onSelect: (device: { name: string; quantity: number }) => void;
}

const DeviceSelectionModal: React.FC<DeviceSelectionModalProps> = ({
  onClose,
  onSelect,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
  const [quantity, setQuantity] = useState(1);
  const { data: devices, isLoading } = useCollectionDevices(searchTerm);

  const handleSelect = (device: Device) => {
    setSelectedDevice(device);
  };

  const handleConfirm = () => {
    if (selectedDevice) {
      onSelect({
        name: selectedDevice.device_name,
        quantity: quantity
      });
      onClose();
    }
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value) || 1;
    setQuantity(Math.max(1, value)); // Ensure quantity is at least 1
  };

  return (
    <Modal onClick={onClose}>
      <Content onClick={e => e.stopPropagation()}>
        <Header>
          <Title>Add Device</Title>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </Header>

        <SearchBar>
          <SearchInput
            type="text"
            placeholder="Search devices..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </SearchBar>

        <DeviceList>
          {isLoading ? (
            <LoadingText>Loading devices...</LoadingText>
          ) : devices && devices.length > 0 ? (
            devices.map((device: Device) => (
              <DeviceItem
                key={device.id}
                onClick={() => handleSelect(device)}
                isSelected={selectedDevice?.id === device.id}
              >
                <DeviceName>{device.device_name}</DeviceName>
                <DeviceType>{device.sample_type}</DeviceType>
              </DeviceItem>
            ))
          ) : (
            <NoResults>No devices found</NoResults>
          )}
        </DeviceList>

        {selectedDevice && (
          <SelectedDeviceSection>
            <SelectedDeviceInfo>
              <strong>{selectedDevice.device_name}</strong>
              <QuantityControls>
                <QuantityButton 
                  onClick={() => setQuantity(q => Math.max(1, q - 1))}
                  disabled={quantity <= 1}
                >
                  -
                </QuantityButton>
                <QuantityInput
                  type="number"
                  min="1"
                  value={quantity}
                  onChange={handleQuantityChange}
                />
                <QuantityButton onClick={() => setQuantity(q => q + 1)}>
                  +
                </QuantityButton>
              </QuantityControls>
            </SelectedDeviceInfo>
            <AddButton onClick={handleConfirm}>
              Add to Shipment
            </AddButton>
          </SelectedDeviceSection>
        )}
      </Content>
    </Modal>
  );
};

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Content = styled.div`
  background: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  width: 90%;
  max-width: 40rem;
  max-height: 90vh;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 1.25rem;
  color: ${colors.text.primary};
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  color: ${colors.text.secondary};
  cursor: pointer;
  padding: 0.5rem;
  
  &:hover {
    color: ${colors.text.primary};
  }
`;

const SearchBar = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 0.75rem;
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }
`;

const DeviceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const DeviceItem = styled.div<{ isSelected?: boolean }>`
  padding: 1rem;
  border: 0.0625rem solid ${props => props.isSelected ? colors.primary : colors.border};
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: ${props => props.isSelected ? 'rgba(0, 122, 255, 0.05)' : 'transparent'};

  &:hover {
    border-color: ${colors.primary};
    background-color: rgba(0, 122, 255, 0.05);
  }
`;

const DeviceName = styled.div`
  font-weight: 500;
  margin-bottom: 0.25rem;
`;

const DeviceType = styled.div`
  font-size: 0.875rem;
  color: ${colors.text.secondary};
`;

const LoadingText = styled.div`
  text-align: center;
  color: ${colors.text.secondary};
  padding: 2rem;
`;

const NoResults = styled.div`
  text-align: center;
  color: ${colors.text.secondary};
  padding: 2rem;
`;

const SelectedDeviceSection = styled.div`
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid ${colors.border};
`;

const SelectedDeviceInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const QuantityControls = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const QuantityButton = styled.button<{ disabled?: boolean }>`
  padding: 0.25rem 0.75rem;
  border: 1px solid ${colors.border};
  border-radius: 0.25rem;
  background: white;
  color: ${props => props.disabled ? colors.text.secondary : colors.text.primary};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  font-size: 1.25rem;
  
  &:hover:not(:disabled) {
    background: ${colors.primary};
    color: white;
  }
`;

const QuantityInput = styled.input`
  width: 4rem;
  padding: 0.5rem;
  border: 1px solid ${colors.border};
  border-radius: 0.25rem;
  text-align: center;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }
`;

const AddButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background: ${colors.primary};
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    opacity: 0.9;
  }
`;

export default DeviceSelectionModal;
