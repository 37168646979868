import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../components/common/Layout/PageLayout';
import Select from '../../components/common/Select/Select';
import worldmap from '../../assets/shipmentmanagerglobe.png';

interface StudyDetails {
  trialId: string;
  type: string;
  siteCount: number;
  patientCount: number;
  collectionDevices: string[];
}

interface InventoryKit {
  name: string;
  inventory: number;
  threshold: number;
  restockStatus: 'Scheduled' | 'Pending' | 'None';
}

interface SiteInventory {
  siteId: string;
  kits: InventoryKit[];
}

interface Shipment {
  trackingNumber: string;
  origin: string;
  destination: string;
  site: string;
  study: string;
  status: 'Cancelled' | 'Delayed' | 'On Time';
  expectedArrival: string | 'Unknown';
  expectedArrivalDate?: Date;
  sampleIds: string[];
  type: 'inbound' | 'outbound';
}

const STARRED_SITES_KEY = 'studyOverviewStarredSites';
const STARRED_SHIPMENTS_KEY = 'starredShipments';

const StudyOverviewPage: React.FC = () => {
  const navigate = useNavigate();
  const [siteFilter, setSiteFilter] = useState('All');
  const [statusFilter, setStatusFilter] = useState('All');
  const [timeFilter, setTimeFilter] = useState('All');
  const [starredRows, setStarredRows] = useState<string[]>(() => {
    const saved = localStorage.getItem(STARRED_SITES_KEY);
    return saved ? JSON.parse(saved) : [];
  });
  const [shipmentStudyFilter, setShipmentStudyFilter] = useState('All');
  const [shipmentStatusFilter, setShipmentStatusFilter] = useState('All');
  const [shipmentTimeFilter, setShipmentTimeFilter] = useState('latest');
  const [starredShipments, setStarredShipments] = useState<string[]>(() => {
    const saved = localStorage.getItem(STARRED_SHIPMENTS_KEY);
    return saved ? JSON.parse(saved) : [];
  });
  const [visibleShipments, setVisibleShipments] = useState<string[]>(() => {
    const saved = localStorage.getItem(STARRED_SHIPMENTS_KEY);
    return saved ? JSON.parse(saved) : [];
  });

  // Mock data - replace with real data later
  const studyDetails: StudyDetails = {
    trialId: '#4729ABD',
    type: 'Multi-Site',
    siteCount: 176,
    patientCount: 54,
    collectionDevices: ['Tasso v0.2', 'Zindagi 0.1']
  };

  const inventoryData: SiteInventory[] = [
    {
      siteId: '#SITE001',
      kits: [
        {
          name: 'Timestrip and Box v0.2',
          inventory: 3,
          threshold: 5,
          restockStatus: 'Scheduled'
        },
        {
          name: 'Timestrip and Box v0.1',
          inventory: 6,
          threshold: 5,
          restockStatus: 'Pending'
        },
        {
          name: 'Tasso Kit v0.1',
          inventory: 4,
          threshold: 3,
          restockStatus: 'None'
        }
      ]
    },
    {
      siteId: '#SITE002',
      kits: [
        {
          name: 'Tasso Kit v0.1',
          inventory: 5,
          threshold: 2,
          restockStatus: 'None'
        }
      ]
    },
    {
      siteId: '#SITE003',
      kits: [
        {
          name: 'Timestrip and Box v0.1',
          inventory: 5,
          threshold: 2,
          restockStatus: 'None'
        }
      ]
    }
  ];

  const mockShipments: Shipment[] = [
    {
      trackingNumber: '788208439645',
      origin: 'SiteID-000',
      destination: '',
      site: 'EVGR-A-001',
      study: 'EVGR-A-001',
      status: 'Cancelled',
      expectedArrival: 'Unknown',
      expectedArrivalDate: undefined,
      sampleIds: ['9946', '9947'],
      type: 'inbound'
    },
    {
      trackingNumber: '788208439645',
      origin: 'SiteID-001',
      destination: '',
      site: 'EVGR-A-001',
      study: 'EVGR-A-001',
      status: 'Delayed',
      expectedArrival: '10/2/24 10:30am',
      expectedArrivalDate: new Date(2024, 9, 2, 10, 30),
      sampleIds: [],
      type: 'inbound'
    },
    {
      trackingNumber: '788208439645',
      origin: 'SiteID-002',
      destination: '',
      site: 'EVGR-A-001',
      study: 'EVGR-A-001',
      status: 'On Time',
      expectedArrival: '10/1/24 10:30am',
      expectedArrivalDate: new Date(2024, 9, 1, 10, 30),
      sampleIds: ['9948'],
      type: 'inbound'
    },
    {
      trackingNumber: '788208439645',
      origin: '',
      destination: 'SiteID-000',
      site: 'EVGR-A-001',
      study: 'EVGR-A-001',
      status: 'Cancelled',
      expectedArrival: 'Unknown',
      expectedArrivalDate: undefined,
      sampleIds: ['9946', '9947'],
      type: 'outbound'
    },
    {
      trackingNumber: '788208439645',
      origin: '',
      destination: 'SiteID-001',
      site: 'EVGR-A-001',
      study: 'EVGR-A-001',
      status: 'Delayed',
      expectedArrival: '10/2/24 10:30am',
      expectedArrivalDate: new Date(2024, 9, 2, 10, 30),
      sampleIds: [],
      type: 'outbound'
    }
  ];

  const getKitRowId = (siteId: string, kitName: string) => {
    return `${siteId}-${kitName}`;
  };

  const handleToggleStar = (siteId: string, kitName: string) => {
    const rowId = getKitRowId(siteId, kitName);
    setStarredRows(prev => 
      prev.includes(rowId)
        ? prev.filter(id => id !== rowId)
        : [...prev, rowId]
    );
  };

  const handleRestock = (siteId: string, kitName: string) => {
    console.log(`Restocking ${kitName} for site ${siteId}`);
    // Implement restock logic
  };

  const filterInventory = (data: SiteInventory[]) => {
    // Create a flat list of all kits with their site info
    const allKits = data.flatMap(site => 
      site.kits.map(kit => ({
        siteId: site.siteId,
        ...kit
      }))
    );

    // Apply filters to individual kits
    const filteredKits = allKits.filter(kit => {
      // Status filter
      if (statusFilter !== 'All' && kit.restockStatus !== statusFilter) {
        return false;
      }
      // Site filter
      if (siteFilter !== 'All' && kit.siteId !== siteFilter) {
        return false;
      }
      return true;
    });

    // Separate starred and non-starred kits
    const starredKits = filteredKits.filter(kit => 
      starredRows.includes(getKitRowId(kit.siteId, kit.name))
    );
    const nonStarredKits = filteredKits.filter(kit => 
      !starredRows.includes(getKitRowId(kit.siteId, kit.name))
    );

    // Group non-starred kits by site
    const siteMap = new Map<string, typeof nonStarredKits>();
    nonStarredKits.forEach(kit => {
      if (!siteMap.has(kit.siteId)) {
        siteMap.set(kit.siteId, []);
      }
      siteMap.get(kit.siteId)!.push(kit);
    });

    // Create virtual "sites" for starred kits
    const starredSites = starredKits.map(kit => ({
      siteId: kit.siteId,
      kits: [kit]
    }));

    // Create sites for non-starred kits
    const nonStarredSites = Array.from(siteMap.entries()).map(([siteId, kits]) => ({
      siteId,
      kits
    }));

    // Combine starred and non-starred sites
    return [...starredSites, ...nonStarredSites];
  };

  const handleSeeAll = () => {
    setSiteFilter('All');
    setStatusFilter('All');
    setTimeFilter('All');
  };

  const getRowId = (shipment: Shipment) => {
    return `${shipment.type}-${shipment.trackingNumber}-${shipment.origin || shipment.destination}`;
  };

  const filterShipments = (shipments: Shipment[]) => {
    // Separate starred and non-starred shipments
    const starredShipmentsList = shipments.filter(s => 
      starredShipments.includes(getRowId(s))
    );
    const nonStarredShipmentsList = shipments.filter(s => 
      !starredShipments.includes(getRowId(s))
    );

    // Apply filters to both lists
    const filterList = (list: Shipment[]) => {
      return list.filter(shipment => {
        const matchesStudy = shipmentStudyFilter === 'All' || shipment.study === shipmentStudyFilter;
        const statusText = shipment.status.toLowerCase();
        const matchesStatus = shipmentStatusFilter === 'All' || statusText === shipmentStatusFilter.toLowerCase();
        return matchesStudy && matchesStatus;
      });
    };

    // Sort each list by date
    const sortByDate = (list: Shipment[]) => {
      return [...list].sort((a, b) => {
        // Handle cases where one or both dates are unknown
        if (!a.expectedArrivalDate && !b.expectedArrivalDate) return 0;
        if (!a.expectedArrivalDate) return 1;  // Unknown dates go to the end
        if (!b.expectedArrivalDate) return -1;

        // Compare actual dates
        return shipmentTimeFilter === 'latest'
          ? b.expectedArrivalDate.getTime() - a.expectedArrivalDate.getTime()
          : a.expectedArrivalDate.getTime() - b.expectedArrivalDate.getTime();
      });
    };

    // Filter and sort each list separately
    const filteredStarred = sortByDate(filterList(starredShipmentsList));
    const filteredNonStarred = sortByDate(filterList(nonStarredShipmentsList));

    // Combine the lists with starred items always on top
    return [...filteredStarred, ...filteredNonStarred];
  };

  const handleToggleShipmentStar = (shipment: Shipment) => {
    const rowId = getRowId(shipment);
    setStarredShipments(prev => {
      const newStarred = prev.includes(rowId)
        ? prev.filter(id => id !== rowId)
        : [...prev, rowId];
      
      // Sync with localStorage
      localStorage.setItem(STARRED_SHIPMENTS_KEY, JSON.stringify(newStarred));
      return newStarred;
    });

    // Don't remove from visible items - they stay until page refresh
    // If it's a new star, add to visible items
    setVisibleShipments(prev => {
      if (!prev.includes(rowId)) {
        return [...prev, rowId];
      }
      return prev;
    });
  };

  const getShipmentStatusColor = (status: string): string => {
    switch (status) {
      case 'Cancelled':
        return '#FF0000';
      case 'On Time':
        return '#00A651';
      case 'Delayed':
        return '#FFA500';
      default:
        return '#666666';
    }
  };

  const handleShipmentSeeAll = () => {
    setShipmentStudyFilter('All');
    setShipmentStatusFilter('All');
    setShipmentTimeFilter('All');
  };

  useEffect(() => {
    localStorage.setItem(STARRED_SITES_KEY, JSON.stringify(starredRows));
  }, [starredRows]);

  useEffect(() => {
    localStorage.setItem(STARRED_SHIPMENTS_KEY, JSON.stringify(starredShipments));
  }, [starredShipments]);

  const getStatusColor = (status: string): string => {
    switch (status) {
      case 'Scheduled':
        return '#00A651';
      case 'Pending':
        return '#FFA500';
      default:
        return '#666666';
    }
  };

  const filteredInventory = filterInventory(inventoryData);
  const filteredShipments = filterShipments(mockShipments);

  return (
    <PageLayout>
      <Container>
        <HeaderContainer>
          <Title>Trials Overview</Title>
          <ActionButtonsContainer>
            <ActionButton onClick={() => navigate('/send-kits')}>
              Send Out Kits
            </ActionButton>
            <ActionButton onClick={() => navigate('/shipment-manager')}>
              Track My Shipments
            </ActionButton>
            <ActionButton onClick={() => navigate('/schedule-pickup')}>
              Schedule Pickups
            </ActionButton>
          </ActionButtonsContainer>
        </HeaderContainer>

        <ContentSection>
          <MapSection>
            <WorldMap src={worldmap} alt="World Map" />
          </MapSection>

          <StudyDetailsCard>
            <DetailTitle>Trial ID #{studyDetails.trialId}</DetailTitle>
            <DetailRow>
              <DetailLabel>Type:</DetailLabel>
              <DetailValue>
                {studyDetails.type} [{studyDetails.siteCount}]
              </DetailValue>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Patient Count:</DetailLabel>
              <DetailValue>{studyDetails.patientCount}</DetailValue>
            </DetailRow>
            <DetailRow>
              <DetailLabel>Associated Collection Devices:</DetailLabel>
              <DevicesList>
                {studyDetails.collectionDevices.map((device, index) => (
                  <DeviceItem key={index}>{device}</DeviceItem>
                ))}
              </DevicesList>
            </DetailRow>
          </StudyDetailsCard>
        </ContentSection>

        <InventorySection>
          <InventoryHeader>
            <InventoryTitle>Inventory Levels</InventoryTitle>
            <FilterContainer>
              <FilterGroup>
                <FilterLabel>Site</FilterLabel>
                <Select
                  placeholder="All Sites"
                  value={siteFilter}
                  onChange={setSiteFilter}
                  options={[
                    { value: 'All', label: 'All Sites' },
                    ...inventoryData.map(site => ({
                      value: site.siteId,
                      label: site.siteId
                    }))
                  ]}
                />
              </FilterGroup>
              <FilterGroup>
                <FilterLabel>Status</FilterLabel>
                <Select
                  placeholder="All Statuses"
                  value={statusFilter}
                  onChange={setStatusFilter}
                  options={[
                    { value: 'All', label: 'All Statuses' },
                    { value: 'Scheduled', label: 'Scheduled' },
                    { value: 'Pending', label: 'Pending' },
                    { value: 'None', label: 'None' }
                  ]}
                />
              </FilterGroup>
              <FilterGroup>
                <FilterLabel>Sample Creation Time</FilterLabel>
                <Select
                  placeholder="All Times"
                  value={timeFilter}
                  onChange={setTimeFilter}
                  options={[
                    { value: 'All', label: 'All Times' },
                    { value: 'today', label: 'Today' },
                    { value: 'this_week', label: 'This Week' },
                    { value: 'this_month', label: 'This Month' }
                  ]}
                />
              </FilterGroup>
              <SeeAllButton onClick={handleSeeAll}>
                See All
              </SeeAllButton>
            </FilterContainer>
          </InventoryHeader>

          <Table>
            <thead>
              <tr>
                <Th>Active Sites</Th>
                <Th>Kits</Th>
                <Th>Inventory Level</Th>
                <Th>Threshold</Th>
                <Th>Restock Status</Th>
                <Th></Th>
                <Th></Th>
              </tr>
            </thead>
            <tbody>
              {filteredInventory.map((site) => (
                site.kits.map((kit, kitIndex) => (
                  <TableRow 
                    key={getKitRowId(site.siteId, kit.name)}
                    isFirstInGroup={kitIndex === 0}
                    isLastInGroup={kitIndex === site.kits.length - 1}
                  >
                    {kitIndex === 0 && (
                      <Td rowSpan={site.kits.length} isGroupCell data-site-id={site.siteId}>
                        {site.siteId}
                      </Td>
                    )}
                    <Td>{kit.name}</Td>
                    <Td>{kit.inventory}</Td>
                    <Td>{kit.threshold}</Td>
                    <Td>
                      <StatusText color={getStatusColor(kit.restockStatus)}>
                        {kit.restockStatus}
                      </StatusText>
                    </Td>
                    <Td>
                      <RestockButton onClick={() => handleRestock(site.siteId, kit.name)}>
                        Restock
                      </RestockButton>
                    </Td>
                    <Td>
                      <StarButton 
                        onClick={() => handleToggleStar(site.siteId, kit.name)}
                        isStarred={starredRows.includes(getKitRowId(site.siteId, kit.name))}
                      >
                        ★
                      </StarButton>
                    </Td>
                  </TableRow>
                ))
              ))}
            </tbody>
          </Table>
        </InventorySection>

        <ShipmentsSection>
          <ShipmentsHeader>
            <ShipmentsTitle>Historical Shipments</ShipmentsTitle>
            <FilterContainer>
              <FilterGroup>
                <FilterLabel>Study</FilterLabel>
                <Select
                  placeholder="All Studies"
                  value={shipmentStudyFilter}
                  onChange={setShipmentStudyFilter}
                  options={[
                    { value: 'All', label: 'All Studies' },
                    { value: 'EVGR-A-001', label: 'EVGR-A-001' }
                  ]}
                />
              </FilterGroup>
              <FilterGroup>
                <FilterLabel>Status</FilterLabel>
                <Select
                  placeholder="All Statuses"
                  value={shipmentStatusFilter}
                  onChange={setShipmentStatusFilter}
                  options={[
                    { value: 'All', label: 'All Statuses' },
                    { value: 'On Time', label: 'On Time' },
                    { value: 'Delayed', label: 'Delayed' },
                    { value: 'Cancelled', label: 'Cancelled' }
                  ]}
                />
              </FilterGroup>
              <FilterGroup>
                <FilterLabel>Date</FilterLabel>
                <Select
                  placeholder="All Times"
                  value={shipmentTimeFilter}
                  onChange={setShipmentTimeFilter}
                  options={[
                    { value: 'All', label: 'All Times' },
                    { value: 'latest', label: 'Latest Date' },
                    { value: 'earliest', label: 'Earliest Date' }
                  ]}
                />
              </FilterGroup>
              <SeeAllButton onClick={handleShipmentSeeAll}>
                See All
              </SeeAllButton>
            </FilterContainer>
          </ShipmentsHeader>

          <ShipmentsTable>
            <thead>
              <tr>
                <Th>Tracking #</Th>
                <Th>Origin</Th>
                <Th>Destination</Th>
                <Th>Study</Th>
                <Th>Status</Th>
                <Th>Expected Arrival</Th>
                <Th>Sample Id[s]</Th>
                <Th></Th>
              </tr>
            </thead>
            <tbody>
              {filteredShipments.map((shipment, index) => (
                <TableRow 
                  key={`${shipment.trackingNumber}-${index}`}
                  onClick={() => navigate(`/trials-overview/shipment/${shipment.trackingNumber}`)}
                >
                  <Td>{shipment.trackingNumber}</Td>
                  <Td>{shipment.origin || '-'}</Td>
                  <Td>{shipment.destination || '-'}</Td>
                  <Td>{shipment.study}</Td>
                  <Td>
                    <StatusText color={getShipmentStatusColor(shipment.status)}>
                      {shipment.status}
                    </StatusText>
                  </Td>
                  <Td>{shipment.expectedArrival}</Td>
                  <Td>
                    <SampleIdList>
                      {shipment.sampleIds.map((id, i) => (
                        <SampleId key={id}>{id}</SampleId>
                      ))}
                    </SampleIdList>
                  </Td>
                  <Td>
                    <StarButton 
                      onClick={(e) => {
                        e.stopPropagation();  // Prevent row click when clicking star
                        handleToggleShipmentStar(shipment);
                      }}
                      isStarred={starredShipments.includes(getRowId(shipment))}
                    >
                      ★
                    </StarButton>
                  </Td>
                </TableRow>
              ))}
            </tbody>
          </ShipmentsTable>
        </ShipmentsSection>
      </Container>
    </PageLayout>
  );
};

const Container = styled.div`
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin: 0;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

const ContentSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
`;

const MapSection = styled.div`
  height: 300px;
  border-radius: 8px;
  overflow: hidden;
`;

const WorldMap = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StudyDetailsCard = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid #E0E0E0;
`;

const DetailTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 16px 0;
`;

const DetailRow = styled.div`
  margin-bottom: 12px;
`;

const DetailLabel = styled.span`
  font-weight: 500;
  margin-right: 8px;
`;

const DetailValue = styled.span`
  color: #666;
`;

const DevicesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 8px 0 0 0;
`;

const DeviceItem = styled.li`
  margin-bottom: 4px;
  color: #666;
  
  &:before {
    content: '•';
    margin-right: 8px;
  }
`;

const InventorySection = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid #E0E0E0;
`;

const InventoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const InventoryTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin: 0;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-end;
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const FilterLabel = styled.label`
  font-size: 12px;
  color: #666;
  font-weight: 500;
`;

const SeeAllButton = styled.button`
  padding: 8px 16px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  text-align: left;
  padding: 12px 16px;
  border-bottom: 1px solid #E0E0E0;
  color: #666;
  font-weight: 500;
  font-size: 14px;
`;

interface TableRowProps {
  isFirstInGroup?: boolean;
  isLastInGroup?: boolean;
  isHeader?: boolean;
}

const TableRow = styled.tr<TableRowProps>`
  border-bottom: 1px solid #E0E0E0;
  cursor: pointer;

  ${props => props.isHeader && `
    background: #F5F5F5;
    font-weight: 500;
    cursor: default;
  `}

  &:hover {
    background-color: ${props => props.isHeader ? '#F5F5F5' : '#FAFAFA'};
  }
`;

interface TdProps {
  isGroupCell?: boolean;
}

const Td = styled.td<TdProps>`
  padding: 12px 16px;
  color: #666;
  font-size: 14px;
  background: inherit;
  ${props => props.isGroupCell && `
    background: white;
  `}
`;

const StatusText = styled.span<{ color: string }>`
  color: ${props => props.color};
  font-weight: 500;
`;

const RestockButton = styled.button`
  padding: 6px 12px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  color: #000;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

const StarButton = styled.button<{ isStarred: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.isStarred ? '#FFD700' : '#E0E0E0'};
  font-size: 20px;
  padding: 4px;
  transition: color 0.2s ease;
  
  &:hover {
    color: ${props => props.isStarred ? '#FFC000' : '#FFD700'};
  }
`;

const ShipmentsSection = styled(InventorySection)`
  margin-top: 24px;
`;

const ShipmentsHeader = styled(InventoryHeader)``;

const ShipmentsTitle = styled(InventoryTitle)``;

const ShipmentsTable = styled(Table)``;

const SampleIdList = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const SampleId = styled.span`
  background: #F5F5F5;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
`;

export default StudyOverviewPage;
