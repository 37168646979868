import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import InventoryLevelsTable from './InventoryLevelsTable';

const STARRED_ROWS_KEY = 'inventoryLevelsStarredRows';

const mockData = [
  {
    studyId: 'EVGR-A-001',
    kits: [
      { name: 'Timestrip and Box v0.2', inventory: 3, threshold: 5, restockStatus: 'Scheduled' as const },
      { name: 'Timestrip and Box v0.1', inventory: 6, threshold: 5, restockStatus: 'Pending' as const },
      { name: 'Tasso Kit v0.2', inventory: 4, threshold: 3, restockStatus: 'None' as const }
    ]
  },
  {
    studyId: 'EVGR-A-002',
    kits: [
      { name: 'Tasso Kit v0.1', inventory: 5, threshold: 2, restockStatus: 'None' as const }
    ]
  },
  {
    studyId: 'EVGR-A-003',
    kits: [
      { name: 'Timestrip and Box v0.1', inventory: 5, threshold: 2, restockStatus: 'None' as const }
    ]
  }
];

const InventoryLevelsWidget: React.FC = () => {
  const [studyFilter, setStudyFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const [timeFilter, setTimeFilter] = useState('all');
  const [starredRows, setStarredRows] = useState<string[]>(() => {
    const saved = localStorage.getItem(STARRED_ROWS_KEY);
    return saved ? JSON.parse(saved) : [];
  });

  const handleRestock = (studyId: string, kitName: string) => {
    console.log(`Restocking ${kitName} for study ${studyId}`);
  };

  const handleToggleStar = (rowId: string) => {
    console.log('Toggle star clicked:', rowId);
    
    // Extract studyId and index from rowId (format is "row-studyId-index")
    const [_, studyId, index] = rowId.split('-');
    
    // Check if this study has multiple kits
    const study = mockData.find(s => s.studyId === studyId);
    const hasMultipleKits = study && study.kits.length > 1;
    
    console.log('Study info:', {
      studyId,
      hasMultipleKits,
      kitsCount: study?.kits.length
    });

    setStarredRows(prev => {
      if (prev.includes(rowId)) {
        // If this row is already starred, just unstar it
        return prev.filter(id => id !== rowId);
      } else {
        // If this row isn't starred, star only this specific row
        return [...prev, rowId];
      }
    });
  };

  useEffect(() => {
    localStorage.setItem(STARRED_ROWS_KEY, JSON.stringify(starredRows));
  }, [starredRows]);

  return (
    <Container>
      <InventoryLevelsTable
        data={mockData}
        onRestock={handleRestock}
        studyFilter={studyFilter}
        statusFilter={statusFilter}
        timeFilter={timeFilter}
        onStudyFilterChange={setStudyFilter}
        onStatusFilterChange={setStatusFilter}
        onTimeFilterChange={setTimeFilter}
        starredRows={starredRows}
        onToggleStar={handleToggleStar}
      />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 24px;
`;

export default InventoryLevelsWidget;
