import type { Address } from '../types/models/address';

interface FormattedAddress {
  name: string;
  street: string;
  cityStateZip: string;
}

export const formatAddressForDisplay = (address: Address): FormattedAddress => {
  if (!address) {
    return {
      name: 'Unknown',
      street: '',
      cityStateZip: ''
    };
  }

  return {
    name: address.label || 'Unknown',
    street: address.street || '',
    cityStateZip: `${address.city || ''}, ${address.state || ''} ${address.postal_code || ''}`.trim()
  };
}; 