import React, { useState, useMemo } from 'react';
import styled from '@emotion/styled';
import PageLayout from '../../components/common/Layout/PageLayout';
import { useNavigate } from 'react-router-dom';
import { usePickup } from '../../contexts/PickupContext';
import { useContactPersons } from '../../api/endpoints/contact-persons';
import { ContactPerson } from '../../types/models/contact-person';
import { useDebounce } from '../../hooks/useDebounce';
import { useAddresses } from '../../api/endpoints/addresses';
import { Address } from '../../types/models/address';

interface SavedAddress {
  country: string;
  line1: string;
  line2: string;
  zip: string;
  city: string;
  state: string;
}

const colors = {
  primary: '#007AFF',
  border: '#E0E0E0',
  text: {
    primary: '#333333',
    secondary: '#666666',
    placeholder: '#999999'
  }
} as const;

const spacing = {
  xs: '0.5rem',    // 8px
  sm: '1rem',      // 16px
  md: '1.5rem',    // 24px
  lg: '2rem',      // 32px
  xl: '2.5rem',    // 40px
} as const;

const SchedulePickupForm: React.FC = () => {
  const navigate = useNavigate();
  const { updatePickupData } = usePickup();
  
  // Contact search state
  const [contactSearchTerm, setContactSearchTerm] = useState('');
  const [selectedContact, setSelectedContact] = useState<ContactPerson | null>(null);
  const [contactHighlightedIndex, setContactHighlightedIndex] = useState(-1);
  const [isContactSearchFocused, setIsContactSearchFocused] = useState(false);
  const debouncedContactSearch = useDebounce(contactSearchTerm, 300);

  // Get contacts with search
  const { 
    data: contactPersonsResponse, 
    isLoading: contactsLoading,
    isFetching: contactsFetching 
  } = useContactPersons({ 
    search: debouncedContactSearch,
  });

  const [formData, setFormData] = useState({
    accountNo: '',
    contactName: '',
    shipmentType: '',
    phoneNumber: '',
    extension: '',
    address: {
      country: '',
      line1: '',
      line2: '',
      zip: '',
      city: '',
      state: ''
    },
    message: ''
  });

  // Filter contacts based on search term
  const filteredContacts = useMemo(() => {
    const contacts = Array.isArray(contactPersonsResponse) ? contactPersonsResponse : [];

    if (!debouncedContactSearch) {
      return contacts.slice(0, 5); // Show recent contacts
    }

    const searchTermLower = debouncedContactSearch.toLowerCase();
    return contacts.filter((contact: ContactPerson) => {
      const nameMatch = contact.name?.toLowerCase()?.includes(searchTermLower) || false;
      const emailMatch = contact.email?.toLowerCase()?.includes(searchTermLower) || false;
      const phoneMatch = contact.phone_number?.toLowerCase()?.includes(searchTermLower) || false;
      
      return nameMatch || emailMatch || phoneMatch;
    });
  }, [contactPersonsResponse, debouncedContactSearch]);

  const handleContactKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!filteredContacts.length) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setContactHighlightedIndex(prev => 
          prev < filteredContacts.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setContactHighlightedIndex(prev => prev > 0 ? prev - 1 : 0);
        break;
      case 'Enter':
        e.preventDefault();
        if (contactHighlightedIndex >= 0) {
          handleContactSelect(filteredContacts[contactHighlightedIndex]);
        }
        break;
      case 'Escape':
        setContactSearchTerm('');
        setContactHighlightedIndex(-1);
        break;
    }
  };

  const handleContactSelect = (contact: ContactPerson) => {
    console.log('Contact selected:', contact);
    
    // Update form data
    const formUpdate = {
      contactName: contact.name || '',
      phoneNumber: contact.phone_number || '',
      email: contact.email || '',
      extension: contact.phone_extension || '',
    };
    
    setFormData(prev => ({
      ...prev,
      ...formUpdate
    }));

    // Update UI state
    setSelectedContact(contact);
    setContactSearchTerm(contact.name || '');
    setContactHighlightedIndex(-1);
  };

  const formatPhoneNumber = (value: string) => {
    const digits = value.replace(/\D/g, '');
    
    if (digits.length <= 3) {
      return digits;
    } else if (digits.length <= 6) {
      return `${digits.slice(0, 3)}-${digits.slice(3)}`;
    } else {
      return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name.startsWith('address.')) {
      const addressField = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        address: {
          ...prev.address,
          [addressField]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPhoneNumber(e.target.value);
    if (formatted.length <= 12) {
      setFormData(prev => ({
        ...prev,
        phoneNumber: formatted
      }));
    }
  };

  const handleExtensionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    setFormData(prev => ({
      ...prev,
      extension: value
    }));
  };

  const isPhoneValid = () => {
    const digitsOnly = formData.phoneNumber.replace(/\D/g, '');
    return digitsOnly.length === 10;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!isPhoneValid()) {
      alert('Please enter a valid phone number');
      return;
    }

    updatePickupData({
      accountNo: formData.accountNo,
      contactName: formData.contactName,
      shipmentType: formData.shipmentType,
      phoneNumber: formData.phoneNumber,
      extension: formData.extension,
      address: formData.address,
      message: formData.message
    });
    
    navigate('/schedule-pickup/details');
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const debouncedSearch = useDebounce(searchTerm, 300);
  
  const { 
    data: addressesResponse, 
    isLoading: addressesLoading,
    isFetching: addressesFetching 
  } = useAddresses();

  const filteredAddresses = useMemo(() => {
    const addresses = Array.isArray(addressesResponse) ? addressesResponse : [];
    
    if (!debouncedSearch) {
      return addresses.slice(0, 5);
    }
    
    const searchTermLower = debouncedSearch.toLowerCase();
    return addresses.filter((address: Address) => {
      const streetMatch = address.street?.toLowerCase().includes(searchTermLower);
      const cityMatch = address.city?.toLowerCase().includes(searchTermLower);
      const stateMatch = address.state?.toLowerCase().includes(searchTermLower);
      const postalMatch = address.postal_code?.toLowerCase().includes(searchTermLower);
      
      return streetMatch || cityMatch || stateMatch || postalMatch;
    });
  }, [addressesResponse, debouncedSearch]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!filteredAddresses.length) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setHighlightedIndex(prev => 
          prev < filteredAddresses.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex(prev => prev > 0 ? prev - 1 : 0);
        break;
      case 'Enter':
        e.preventDefault();
        if (highlightedIndex >= 0) {
          handleAddressSelect(filteredAddresses[highlightedIndex]);
        }
        break;
      case 'Escape':
        setSearchTerm('');
        setHighlightedIndex(-1);
        break;
    }
  };

  const handleAddressSelect = (address: Address) => {
    setSelectedAddress(address);
    setSearchTerm('');
    setHighlightedIndex(-1);
    
    setFormData(prev => ({
      ...prev,
      address: {
        ...prev.address,
        line1: address.street || '',
        city: address.city || '',
        state: address.state || '',
        zip: address.postal_code || '',
        country: address.country || ''
      }
    }));
  };

  return (
    <PageLayout title="Schedule Pickups">
      <FormContainer onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Account No.</Label>
          <Input 
            type="text" 
            name="accountNo"
            value={formData.accountNo}
            onChange={handleChange}
            placeholder="Value" 
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Contact Name</Label>
          <SearchBar>
            <SearchInput 
              type="text" 
              placeholder={contactsLoading ? "Loading contacts..." : "Search contacts..."}
              value={contactSearchTerm}
              onChange={(e) => setContactSearchTerm(e.target.value)}
              onKeyDown={handleContactKeyDown}
              onFocus={() => setIsContactSearchFocused(true)}
              onBlur={() => {
                setTimeout(() => setIsContactSearchFocused(false), 200);
              }}
              disabled={contactsLoading}
            />
            {isContactSearchFocused && (
              <AddressDropdown>
                {contactsFetching ? (
                  <LoadingAddressOption>
                    <LoadingText>Searching contacts...</LoadingText>
                  </LoadingAddressOption>
                ) : filteredContacts.length > 0 ? (
                  filteredContacts.map((contact: ContactPerson, index: number) => (
                    <AddressOption 
                      key={contact.id}
                      onClick={() => handleContactSelect(contact)}
                      isHighlighted={index === contactHighlightedIndex}
                    >
                      <div>{contact.name}</div>
                      <div>{contact.email || contact.phone_number}</div>
                    </AddressOption>
                  ))
                ) : (
                  <LoadingAddressOption>
                    <LoadingText>
                      {debouncedContactSearch ? 'No contacts found' : 'Start typing to search contacts'}
                    </LoadingText>
                  </LoadingAddressOption>
                )}
              </AddressDropdown>
            )}
          </SearchBar>
        </FormGroup>

        <FormGroup>
          <Label>Shipment Type</Label>
          <Select 
            name="shipmentType"
            value={formData.shipmentType}
            onChange={handleChange}
            required
          >
            <option value="">Select Type</option>
            <option value="blood">Blood</option>
            <option value="saliva">Saliva</option>
            <option value="urine">Urine</option>
            <option value="other">Other</option>
          </Select>
        </FormGroup>

        <FormGroup>
          <Label>Phone no.</Label>
          <PhoneGroup>
            <PhoneInput 
              type="tel"
              placeholder="___-___-____"
              value={formData.phoneNumber}
              onChange={handlePhoneChange}
              pattern="\d{3}-\d{3}-\d{4}"
              required
            />
            <ExtLabel>ext.</ExtLabel>
            <ExtInput 
              type="text"
              value={formData.extension}
              onChange={handleExtensionChange}
              maxLength={6}
            />
          </PhoneGroup>
          {formData.phoneNumber && !isPhoneValid() && (
            <ErrorMessage>Please enter a valid 10-digit phone number</ErrorMessage>
          )}
        </FormGroup>

        <AddressSection>
          <Label>Address</Label>
          <SearchBar>
            <SearchInput 
              type="text" 
              placeholder={addressesLoading ? "Loading addresses..." : "Search Address Book"}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
              onFocus={() => setIsSearchFocused(true)}
              onBlur={() => {
                setTimeout(() => setIsSearchFocused(false), 200);
              }}
              disabled={addressesLoading}
            />
            {isSearchFocused && (debouncedSearch || !addressesLoading) && (
              <AddressDropdown>
                {addressesFetching ? (
                  <LoadingAddressOption>
                    <LoadingText>Searching addresses...</LoadingText>
                  </LoadingAddressOption>
                ) : filteredAddresses.length > 0 ? (
                  filteredAddresses.map((address: Address, index: number) => (
                    <AddressOption 
                      key={address.id}
                      onClick={() => handleAddressSelect(address)}
                      isHighlighted={index === highlightedIndex}
                    >
                      <div>{address.street}</div>
                      <div>{address.city}, {address.state}</div>
                    </AddressOption>
                  ))
                ) : (
                  <LoadingAddressOption>
                    <LoadingText>No addresses found</LoadingText>
                  </LoadingAddressOption>
                )}
              </AddressDropdown>
            )}
          </SearchBar>

          {selectedAddress && (
            <SelectedAddress>
              <p>{selectedAddress.street}</p>
              <p>{`${selectedAddress.city}, ${selectedAddress.state} ${selectedAddress.postal_code}`}</p>
              <p>{selectedAddress.country}</p>
            </SelectedAddress>
          )}

          <Input 
            type="text" 
            name="address.line1"
            value={formData.address.line1}
            onChange={handleChange}
            placeholder="Address Line 1" 
            required
          />
          <Input 
            type="text" 
            name="address.line2"
            value={formData.address.line2}
            onChange={handleChange}
            placeholder="Address Line 2" 
          />
          <AddressGrid>
            <Input 
              type="text" 
              name="address.zip"
              value={formData.address.zip}
              onChange={handleChange}
              placeholder="Zip" 
              required
            />
            <Input 
              type="text" 
              name="address.city"
              value={formData.address.city}
              onChange={handleChange}
              placeholder="City" 
              required
            />
            <Input 
              type="text" 
              name="address.state"
              value={formData.address.state}
              onChange={handleChange}
              placeholder="State" 
              required
            />
          </AddressGrid>
          <Input 
            type="text" 
            name="address.country"
            value={formData.address.country}
            onChange={handleChange}
            placeholder="Country/Territory" 
            required
          />
        </AddressSection>

        <FormGroup>
          <Label>Message</Label>
          <TextArea 
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Value" 
          />
        </FormGroup>

        <SubmitButton>Submit</SubmitButton>
      </FormContainer>
    </PageLayout>
  );
};

const FormContainer = styled.form`
  max-width: 800px;
  margin: 0 auto;
  padding: 24px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 24px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  font-size: 14px;

  &:focus {
    border-color: #000;
    outline: none;
  }
`;

const SearchBar = styled.div`
  position: relative;
  margin-bottom: 8px;
`;

const SearchInput = styled(Input)<{ disabled?: boolean }>`
  padding-right: 40px;
  opacity: ${props => props.disabled ? 0.7 : 1};
  cursor: ${props => props.disabled ? 'not-allowed' : 'text'};
`;

const AddressDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const AddressOption = styled.div<{ isHighlighted?: boolean }>`
  padding: 8px 12px;
  cursor: pointer;
  background: ${props => props.isHighlighted ? '#f5f5f5' : 'transparent'};
  
  &:hover {
    background: #f5f5f5;
  }
  
  div:first-of-type {
    font-weight: 500;
  }
  
  div:last-of-type {
    font-size: 14px;
    color: #666;
  }
`;

const LoadingAddressOption = styled.div`
  padding: 8px 12px;
`;

const LoadingText = styled.div`
  color: #666;
  text-align: center;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  font-size: 14px;
  background: white;

  &:focus {
    border-color: #000;
    outline: none;
  }
`;

const PhoneGroup = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const PhoneInput = styled(Input)`
  width: 200px;
`;

const ExtLabel = styled.span`
  color: #666;
`;

const ExtInput = styled(Input)`
  width: 100px;
`;

const AddressSection = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const AddressGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 12px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  font-size: 14px;
  min-height: 100px;
  resize: vertical;

  &:focus {
    border-color: #000;
    outline: none;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  background: #333;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #000;
  }
`;

const ErrorMessage = styled.span`
  color: #d32f2f;
  font-size: 12px;
  margin-top: 4px;
  display: block;
`;

const SelectedAddress = styled.div`
  padding: ${spacing.sm};
  border: 1px solid ${colors.border};
  border-radius: 0.5rem;
  margin-top: ${spacing.sm};
  margin-bottom: ${spacing.sm};
  
  p {
    margin: 0;
    line-height: 1.5;
  }
`;

export default SchedulePickupForm; 