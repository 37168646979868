import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../components/constants/breakpoints';
import { SHIPPING_SERVICES } from '../../types/models/shipping';

interface ServiceSelectionModalProps {
  onClose: () => void;
  onSelect: (serviceName: string) => void;
}

const ServiceSelectionModal: React.FC<ServiceSelectionModalProps> = ({
  onClose,
  onSelect,
}) => {
  return (
    <Modal onClick={onClose}>
      <Content onClick={e => e.stopPropagation()}>
        <Header>
          <Title>Select Service Level</Title>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </Header>
        <ServiceGrid>
          {SHIPPING_SERVICES.map((service) => (
            <ServiceCard
              key={service.name}
              onClick={() => {
                onSelect(service.name);
                onClose();
              }}
            >
              <ServiceName>{service.name}</ServiceName>
              <ServiceDetails>
                <DeliveryTime>{service.deliveryTime}</DeliveryTime>
                <Price>{service.price}</Price>
              </ServiceDetails>
            </ServiceCard>
          ))}
        </ServiceGrid>
      </Content>
    </Modal>
  );
};

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Content = styled.div`
  background: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  width: 90%;
  max-width: 40rem;
  max-height: 90vh;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 1.25rem;
  color: ${colors.text.primary};
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  color: ${colors.text.secondary};
  cursor: pointer;
  padding: 0.5rem;
  
  &:hover {
    color: ${colors.text.primary};
  }
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;

const ServiceCard = styled.div`
  padding: 1rem;
  border: 0.0625rem solid ${colors.border};
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: ${colors.primary};
    background-color: rgba(0, 122, 255, 0.05);
  }
`;

const ServiceName = styled.div`
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

const ServiceDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.text.secondary};
  font-size: 0.875rem;
`;

const DeliveryTime = styled.span``;

const Price = styled.span`
  font-weight: 500;
`;

export default ServiceSelectionModal;
