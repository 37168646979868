import React, { useState } from 'react';
import styled from '@emotion/styled';

interface SelectProps {
  placeholder: string;
  label?: string;
  options?: Array<{
    value: string;
    label: string;
  }>;
  onChange?: (value: string) => void;
  value?: string;
}

const Select: React.FC<SelectProps> = ({
  placeholder,
  label,
  options = [],
  onChange,
  value,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (newValue: string) => {
    onChange?.(newValue);
    setIsOpen(false);
  };

  return (
    <SelectWrapper>
      {label && <Label>{label}</Label>}
      <SelectContainer>
        <SelectButton
          onClick={() => setIsOpen(!isOpen)}
          type="button"
        >
          <span>{value ? value : placeholder || 'All'}</span>
          <ChevronIcon isOpen={isOpen}>▼</ChevronIcon>
        </SelectButton>

        {isOpen && (
          <OptionsContainer>
            {options.map((option) => (
              <Option
                key={option.value}
                onClick={() => handleSelect(option.value)}
                isSelected={value === option.value}
              >
                {option.label}
              </Option>
            ))}
          </OptionsContainer>
        )}
      </SelectContainer>
    </SelectWrapper>
  );
};

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #000;
`;

const SelectContainer = styled.div`
  position: relative;
  min-width: 200px;
`;

const SelectButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 4px 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  cursor: pointer;
  font-size: 14px;
  color: #000;

  &:hover {
    border-color: #999;
  }
`;

const ChevronIcon = styled.span<{ isOpen: boolean }>`
  font-size: 10px;
  color: #666;
  margin-left: 8px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const OptionsContainer = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  list-style: none;
  margin: 1px 0 0;
  padding: 0;
  z-index: 1000;
`;

const Option = styled.li<{ isSelected: boolean }>`
  padding: 4px 8px;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? '#f0f0f0' : 'transparent')};
  font-size: 14px;
  color: #000;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export default Select;