import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../components/common/Layout/PageLayout';
import Select from '../../components/common/Select/Select';
import worldmap from '../../assets/shipmentmanagerglobe.png';
import { ShipmentStatus } from '../../types/models/shipment';
import { useShipments } from '../../hooks/useShipments';

// Use the same key as ShipmentManager
const STARRED_SHIPMENTS_KEY = 'starredShipments';

// Helper functions for status display
const getStatusIcon = (type: string, status: string) => {
  if (status === 'In Transit') return '✓';
  if (status === 'Delayed') return '⚠️';
  if (status === 'Delivered') return '✓';
  return '!';
};

const getStatusColor = (type: string, status: string) => {
  if (status === 'In Transit') return '#00A651';
  if (status === 'Delayed') return '#1976D2';
  if (status === 'Delivered') return '#00A651';
  return '#666666';
};

interface WatchlistItem {
  type: 'shipment';
  id: string;
  tracking: string;
  trial: string;
  status: ShipmentStatus;
  details: {
    site: string;
    location: string;
    statusText: string;
  };
}

const WatchlistPage: React.FC = () => {
  const navigate = useNavigate();
  const [typeFilter, setTypeFilter] = useState('All');
  const [trialFilter, setTrialFilter] = useState('All');
  const [statusFilter, setStatusFilter] = useState('All');
  const { data: shipmentData, isLoading, error } = useShipments();
  
  // Track starred items (synced with localStorage)
  const [starredItems, setStarredItems] = useState<string[]>(() => {
    const saved = localStorage.getItem(STARRED_SHIPMENTS_KEY);
    return saved ? JSON.parse(saved) : [];
  });

  // Track which items should be visible on the current page session
  const [visibleItems, setVisibleItems] = useState<string[]>(() => {
    const saved = localStorage.getItem(STARRED_SHIPMENTS_KEY);
    return saved ? JSON.parse(saved) : [];
  });

  // Convert shipment data to watchlist items
  const getWatchlistItems = (): WatchlistItem[] => {
    if (!shipmentData) return [];

    const allShipments = [...shipmentData.inboundData, ...shipmentData.outboundData];
    console.log('All available shipments:', allShipments);
    console.log('All shipments trial_ids:', allShipments.map(s => s.trial_id));
    
    // Include all visible items (both starred and unstarred but visible)
    const items = allShipments
      .filter(shipment => visibleItems.includes(shipment.tracking_number))
      .map(shipment => {
        const item = {
          type: 'shipment' as const,
          id: shipment.id,
          tracking: shipment.tracking_number,
          trial: shipment.trial_id,
          status: shipment.status,
          details: {
            site: shipment.site_id,
            location: shipment.destination || 'Unknown',
            statusText: shipment.status === 'PENDING' ? 'Delayed' : 
                     shipment.status === 'DELIVERED' ? 'Delivered' : 
                     'In Transit'
          }
        };
        console.log('Mapped watchlist item:', item);
        return item;
      });
    
    console.log('Filtered watchlist items:', items);
    return items;
  };

  const handleToggleStar = (tracking: string) => {
    setStarredItems(prev => {
      const newStarred = prev.includes(tracking)
        ? prev.filter(t => t !== tracking)
        : [...prev, tracking];
      
      // Sync with localStorage - this will affect ShipmentManager too
      localStorage.setItem(STARRED_SHIPMENTS_KEY, JSON.stringify(newStarred));
      return newStarred;
    });

    // Don't remove from visible items - they stay until page refresh
    // If it's a new star, add to visible items
    setVisibleItems(prev => {
      if (!prev.includes(tracking)) {
        return [...prev, tracking];
      }
      return prev;
    });
  };

  const filterItems = (items: WatchlistItem[]) => {
    return items.filter(item => {
      // Type filter (always 'Shipment' for now)
      if (typeFilter !== 'All' && item.type !== typeFilter.toLowerCase()) {
        return false;
      }

      // Trial filter
      if (trialFilter !== 'All' && item.trial !== trialFilter) {
        return false;
      }

      // Status filter
      if (statusFilter !== 'All') {
        if (statusFilter === 'Delayed') {
          return item.details.statusText === 'Delayed';
        }
        return item.details.statusText === statusFilter;
      }

      return true;
    });
  };

  const handleItemClick = (item: WatchlistItem) => {
    navigate(`/shipment-manager/${item.id}`);
  };

  if (isLoading) {
    return (
      <PageLayout>
        <Container>
          <div>Loading watchlist...</div>
        </Container>
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <Container>
          <div>Error loading watchlist: {error.toString()}</div>
        </Container>
      </PageLayout>
    );
  }

  const watchlistItems = getWatchlistItems();
  const filteredItems = filterItems(watchlistItems);

  // Get unique trial IDs for filter options from the watchlist items only
  const trialOptions = Array.from(new Set(watchlistItems.map(item => item.trial)))
    .map(trial => ({ value: trial, label: trial }));
  
  console.log('Available trial options:', trialOptions);

  return (
    <PageLayout>
      <Container>
        <HeaderContainer>
          <Title>Watchlist</Title>
          <ActionButtonsContainer>
            <ActionButton onClick={() => navigate('/send-kits')}>
              Send Out Kits
            </ActionButton>
            <ActionButton onClick={() => navigate('/track-shipments')}>
              Track My Shipments
            </ActionButton>
            <ActionButton onClick={() => navigate('/schedule-pickup')}>
              Schedule Pickups
            </ActionButton>
          </ActionButtonsContainer>
        </HeaderContainer>

        <MapSection>
          <WorldMap src={worldmap} alt="World Map" />
        </MapSection>

        <WatchlistSection>
          <WatchlistHeader>
            <WatchlistTitle>Watchlist</WatchlistTitle>
            <FilterContainer>
              <FilterGroup>
                <FilterLabel>Type</FilterLabel>
                <Select
                  placeholder="Type"
                  value={typeFilter}
                  onChange={setTypeFilter}
                  options={[
                    { value: 'All', label: 'All' },
                    { value: 'Shipment', label: 'Shipments' }
                  ]}
                />
              </FilterGroup>
              <FilterGroup>
                <FilterLabel>Trial</FilterLabel>
                <Select
                  placeholder="Trial"
                  value={trialFilter}
                  onChange={setTrialFilter}
                  options={[
                    { value: 'All', label: 'All Trials' },
                    ...trialOptions
                  ]}
                />
              </FilterGroup>
              <FilterGroup>
                <FilterLabel>Status</FilterLabel>
                <Select
                  placeholder="Status"
                  value={statusFilter}
                  onChange={setStatusFilter}
                  options={[
                    { value: 'All', label: 'All Statuses' },
                    { value: 'In Transit', label: 'In Transit' },
                    { value: 'Delayed', label: 'Delayed' },
                    { value: 'Delivered', label: 'Delivered' }
                  ]}
                />
              </FilterGroup>
              <SeeAllButton onClick={() => {
                setTypeFilter('All');
                setTrialFilter('All');
                setStatusFilter('All');
              }}>
                See All
              </SeeAllButton>
            </FilterContainer>
          </WatchlistHeader>

          <WatchlistItems>
            {filteredItems.map((item, index) => (
              <WatchlistItem 
                key={item.tracking}
                onClick={() => handleItemClick(item)}
                style={{ cursor: 'pointer' }}
              >
                <ItemIconContainer 
                  type={item.type}
                  status={item.details.statusText}
                >
                  {getStatusIcon(item.type, item.details.statusText)}
                </ItemIconContainer>
                <ItemContent>
                  <ItemMainInfo>
                    <MainTitle>
                      Shipment ID {item.id}
                    </MainTitle>
                    <MainSubtitle>
                      Tracking # {item.tracking}
                    </MainSubtitle>
                  </ItemMainInfo>
                  <ItemDetails>
                    <ShipmentStatusContainer color={getStatusColor(item.type, item.details.statusText)}>
                      <StatusBar color={getStatusColor(item.type, item.details.statusText)} />
                      <StatusInfo>
                        <SiteInfo>{item.details.site}</SiteInfo>
                        <LocationInfo>{item.details.location}</LocationInfo>
                        <StatusText color={getStatusColor(item.type, item.details.statusText)}>
                          {item.details.statusText}
                        </StatusText>
                      </StatusInfo>
                    </ShipmentStatusContainer>
                  </ItemDetails>
                </ItemContent>
                <StarButton 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleToggleStar(item.tracking);
                  }}
                  isStarred={starredItems.includes(item.tracking)}
                >
                  ★
                </StarButton>
              </WatchlistItem>
            ))}
          </WatchlistItems>
        </WatchlistSection>
      </Container>
    </PageLayout>
  );
};

const Container = styled.div`
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin: 0;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

const MapSection = styled.div`
  width: 100%;
  height: 300px;
  margin-bottom: 24px;
  border-radius: 8px;
  overflow: hidden;
`;

const WorldMap = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const WatchlistSection = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid #E0E0E0;
`;

const WatchlistHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const WatchlistTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin: 0;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-end;
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const FilterLabel = styled.label`
  font-size: 12px;
  color: #666;
  font-weight: 500;
`;

const SeeAllButton = styled.button`
  padding: 8px 16px;
  border: 1px solid #000;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #000;
    color: white;
  }
`;

const WatchlistItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const WatchlistItem = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 16px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  transition: all 0.2s ease;

  &:hover {
    background: #f5f5f5;
  }
`;

const ItemIconContainer = styled.div<{ type: string; status: string }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-right: 16px;
  margin-top: 4px;
  background: ${props => {
    if (props.type === 'shipment') {
      if (props.status === 'On Time') return '#E8F5E9';
      if (props.status === 'Delayed') return '#E3F2FD';
      return '#F5F5F5';
    }
    return '#E3F2FD';
  }};
  color: ${props => {
    if (props.type === 'shipment') {
      if (props.status === 'On Time') return '#00A651';
      if (props.status === 'Delayed') return '#1976D2';
      return '#666666';
    }
    return '#1976D2';
  }};
  align-self: center;
`;

const ItemContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

const ItemMainInfo = styled.div`
  min-width: 300px;
`;

const MainTitle = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
`;

const MainSubtitle = styled.div`
  color: #666;
  font-size: 14px;
  margin-bottom: 4px;
`;

const ItemDetails = styled.div`
  flex: 1;
`;

const ShipmentStatusContainer = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StatusBar = styled.div<{ color: string }>`
  width: 4px;
  height: 40px;
  background: ${props => props.color};
  border-radius: 2px;
`;

const StatusInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const SiteInfo = styled.div`
  font-size: 14px;
  color: #666;
`;

const LocationInfo = styled.div`
  font-size: 14px;
  color: #666;
`;

const StatusText = styled.div<{ color: string }>`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.color};
`;

const StarButton = styled.button<{ isStarred: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.isStarred ? '#FFD700' : '#E0E0E0'};
  font-size: 20px;
  padding: 4px;
  transition: color 0.2s ease;
  
  &:hover {
    color: ${props => props.isStarred ? '#FFC000' : '#FFD700'};
  }
`;

export default WatchlistPage;
