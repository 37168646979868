import React from 'react';
import styled from '@emotion/styled';
import type { Trial } from '../../types/models/trial';
import type { Shipment, ShipmentProfile } from '../../types/models/shipment';

const colors = {
  primary: '#007AFF',
  border: '#E0E0E0',
  text: {
    primary: '#333333',
    secondary: '#666666',
    placeholder: '#999999'
  }
} as const;

interface ShipmentHeaderProps {
  selectedTrialId: string;
  selectedShipmentId: string;
  selectedProfile?: ShipmentProfile | null;
  onTrialSelect: (trialId: string) => void;
  onShipmentSelect: (shipmentId: string) => void;
  trials: Trial[];
  shipments: Shipment[];
  readOnly?: boolean;
  isLoading?: boolean;
  isLoadingShipments?: boolean;
}

const ShipmentHeader: React.FC<ShipmentHeaderProps> = ({
  selectedTrialId,
  selectedShipmentId = '',
  selectedProfile = null,
  onTrialSelect,
  onShipmentSelect,
  trials = [],
  shipments = [],
  isLoading = false,
  isLoadingShipments = false,
  readOnly = false
}) => {
  console.log('🎯 ShipmentHeader props:', {
    selectedTrialId,
    selectedShipmentId,
    trialsCount: trials.length,
    shipmentsCount: shipments.length,
    availableShipments: shipments.map(s => ({
      tracking: s.tracking_number,
      trial: s.trial?.trial_id || s.trial_id,
      status: s.status
    }))
  });

  const selectedShipment = shipments.find(s => s.tracking_number === selectedShipmentId);

  return (
    <>
      <ShipmentTypeSelect disabled>
        <option value="outbound">Outbound Shipment</option>
      </ShipmentTypeSelect>

      <SelectionRow>
        <SelectGroup>
          <SelectLabel>Shipment Profile</SelectLabel>
          {readOnly ? (
            <ReadOnlyField>
              {selectedShipment?.tracking_number || 'No shipment selected'}
            </ReadOnlyField>
          ) : (
            <StyledSelect
              value={selectedShipmentId}
              disabled={!selectedTrialId || isLoadingShipments}
              onChange={(e) => onShipmentSelect(e.target.value)}
            >
              <option value="">Select a Shipment</option>
              {shipments.map((shipment) => (
                <option key={shipment.tracking_number} value={shipment.tracking_number}>
                  {shipment.tracking_number}{shipment.status !== 'DELIVERED' ? ` (${shipment.status})` : ''}
                </option>
              ))}
            </StyledSelect>
          )}
        </SelectGroup>

        <SelectGroup>
          <SelectLabel>Trial ID</SelectLabel>
          {readOnly ? (
            <ReadOnlyField>{selectedTrialId || 'No trial selected'}</ReadOnlyField>
          ) : (
            <StyledSelect
              value={selectedTrialId}
              onChange={(e) => onTrialSelect(e.target.value)}
              disabled={isLoading}
            >
              <option value="">Select a Trial</option>
              {trials.map((trial) => (
                <option key={trial.trial_id} value={trial.trial_id}>
                  {trial.trial_id}
                </option>
              ))}
            </StyledSelect>
          )}
        </SelectGroup>
      </SelectionRow>
    </>
  );
};

// Styled components
const ShipmentTypeSelect = styled.select`
  width: 100%;
  padding: 12px;
  margin-bottom: 24px;
  border: 1px solid ${colors.border};
  border-radius: 4px;
  font-size: 16px;
  color: ${colors.text.primary};
  background-color: #f5f5f5;
`;

const SelectionRow = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
`;

const SelectGroup = styled.div`
  flex: 1;
`;

const SelectLabel = styled.div`
  font-size: 14px;
  color: ${colors.text.secondary};
  margin-bottom: 8px;
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 12px;
  border: 1px solid ${colors.border};
  border-radius: 4px;
  font-size: 16px;
  color: ${colors.text.primary};
  background-color: white;

  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
`;

const ReadOnlyField = styled.div`
  width: 100%;
  padding: 12px;
  border: 1px solid ${colors.border};
  border-radius: 4px;
  font-size: 16px;
  color: ${colors.text.primary};
  background-color: #f5f5f5;
`;

export default ShipmentHeader;