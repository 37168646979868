import api from '../client';
import { Trial } from 'types/models/trial';

export const trialApi = {
  getTrials: async () => {
    console.log('Making API request to /trials/');
    const response = await api.get<Trial[]>('/trials/');
    console.log('Raw API response:', response);
    return response;
  },
  getTrial: (trialId: string) => 
    api.get<Trial>(`/trials/${trialId}/`),
    
  createTrial: (data: Partial<Trial>) => 
    api.post<Trial>('/trials/', data),
    
  updateTrial: (trialId: string, data: Partial<Trial>) => 
    api.patch<Trial>(`/trials/${trialId}/`, data),
    
  deleteTrial: (trialId: string) => 
    api.delete(`/trials/${trialId}/`)
}; 