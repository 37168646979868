import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import RouteGuard from './components/RouteGuard';
import Login from './components/Login/Login';
import WorkflowSelection from './pages/WorkflowSelection';
import TrialSetup from './pages/CreateTrial/TrialSetup';
import DeviceCollection from './pages/CreateTrial/DeviceCollection';
import OutboundShipments from './pages/CreateTrial/OutboundShipments';
import Confirmation from './pages/CreateTrial/Confirmation';
import ShipmentSetup from './pages/SendOutKits/ShipmentSetup';
import CreateLabels from './pages/SendOutKits/CreateLabels';
import ConfirmationAndBilling from './pages/SendOutKits/ConfirmationAndBilling';
import PackagesConfirmed from './pages/SendOutKits/PackagesConfirmed';
import ShipmentManager from './pages/ManageInventory/ShipmentManager';
import ShipmentProfile from './pages/ManageInventory/ShipmentProfile';
import SchedulePickupForm from './pages/SchedulePickup/SchedulePickupForm';
import PickupDetails from './pages/SchedulePickup/PickupDetails';
import PickupConfirmation from './pages/SchedulePickup/PickupConfirmation';
import InventoryLevelsPage from './pages/Inventory/InventoryLevelsPage';
import SitePage from './pages/Sites/SitePage';
import SiteShipmentDetails from './pages/Sites/SiteShipmentDetails';
import { PickupProvider } from './contexts/PickupContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SendOutKitsLayout from './pages/SendOutKits/SendOutKitsLayout';
import { ShippingSelectionProvider } from './contexts/ShippingSelectionContext';
import WatchlistPage from './pages/Watchlist/WatchlistPage';
import StudyOverviewPage from './pages/StudyOverview/StudyOverviewPage';
import KitOverviewPage from './pages/KitOverview/KitOverviewPage';
import StudyShipmentDetails from './pages/StudyOverview/StudyShipmentDetails';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      retry: 1,
      refetchOnWindowFocus: true,
    },
  },
});

const App: React.FC = () => {
  const getTrialProgress = () => {
    try {
      const progress = localStorage.getItem('trialProgress');
      const parsedProgress = progress ? JSON.parse(progress) : {};
      return parsedProgress;
    } catch (err) {
      console.error('Error getting trial progress:', err);
      return {};
    }
  };

  return (
    <ShippingSelectionProvider>
      <QueryClientProvider client={queryClient}>
        <PickupProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Navigate to="/login" replace />} />
              <Route path="/login" element={<Login />} />
              
              <Route 
                path="/dashboard" 
                element={
                  <RouteGuard redirectTo="/login">
                    <WorkflowSelection />
                  </RouteGuard>
                } 
              />
              
              <Route path="/create-trial">
                <Route 
                  index 
                  element={<Navigate to="/create-trial/setup" replace />} 
                />
                <Route 
                  path="setup" 
                  element={
                    <RouteGuard redirectTo="/login">
                      <TrialSetup />
                    </RouteGuard>
                  } 
                />
                <Route 
                  path="devices/:trialId" 
                  element={
                    <RouteGuard redirectTo="/create-trial/setup">
                      <DeviceCollection />
                    </RouteGuard>
                  } 
                />
                <Route 
                  path="shipments/:trialId" 
                  element={
                    <RouteGuard redirectTo="/create-trial/devices">
                      <OutboundShipments />
                    </RouteGuard>
                  } 
                />
                <Route 
                  path="confirmation/:trialId" 
                  element={
                    <RouteGuard redirectTo="/create-trial/shipments">
                      <Confirmation />
                    </RouteGuard>
                  } 
                />
              </Route>

              <Route path="/send-kits" element={<SendOutKitsLayout />}>
                <Route index element={<ShipmentSetup />} />
                <Route path="create-labels" element={<CreateLabels />} />
                <Route path="confirmation" element={<ConfirmationAndBilling />} />
                <Route path="confirmed" element={<PackagesConfirmed />} />
              </Route>

              <Route 
                path="/inventory-levels" 
                element={
                  <RouteGuard redirectTo="/login">
                    <InventoryLevelsPage />
                  </RouteGuard>
                } 
              />

              <Route path="/shipment-manager">
                <Route 
                  index
                  element={
                    <RouteGuard redirectTo="/login">
                      <ShipmentManager />
                    </RouteGuard>
                  } 
                />
                <Route 
                  path=":id" 
                  element={
                    <RouteGuard redirectTo="/login">
                      <ShipmentProfile />
                    </RouteGuard>
                  } 
                />
              </Route>

              <Route path="/schedule-pickup">
                <Route 
                  index
                  element={
                    <RouteGuard redirectTo="/login">
                      <SchedulePickupForm />
                    </RouteGuard>
                  } 
                />
                <Route 
                  path="details"
                  element={
                    <RouteGuard redirectTo="/schedule-pickup">
                      <PickupDetails />
                    </RouteGuard>
                  } 
                />
                <Route 
                  path="confirmation"
                  element={
                    <RouteGuard redirectTo="/schedule-pickup/details">
                      <PickupConfirmation />
                    </RouteGuard>
                  } 
                />
              </Route>

              <Route path="/sites">
                <Route index element={<SitePage />} />
                <Route path=":siteId" element={<SitePage />} />
                <Route path=":siteId/shipments/:trackingNumber" element={<SiteShipmentDetails />} />
              </Route>

              <Route path="/watchlist">
                <Route
                  index
                  element={
                    <RouteGuard redirectTo="/login">
                      <WatchlistPage />
                    </RouteGuard>
                  }
                />
              </Route>

              <Route path="/trials-overview">
                <Route
                  index
                  element={
                    <RouteGuard redirectTo="/login">
                      <StudyOverviewPage />
                    </RouteGuard>
                  }
                />
                <Route
                  path="shipment/:trackingNumber"
                  element={
                    <RouteGuard redirectTo="/login">
                      <StudyShipmentDetails />
                    </RouteGuard>
                  }
                />
              </Route>

              <Route path="/kit-overview">
                <Route
                  index
                  element={
                    <RouteGuard redirectTo="/login">
                      <KitOverviewPage />
                    </RouteGuard>
                  }
                />
              </Route>

              <Route 
                path="*" 
                element={
                  localStorage.getItem('isLoggedIn') === 'true'
                    ? <Navigate to="/dashboard" replace /> 
                    : <Navigate to="/login" replace />
                } 
              />
            </Routes>
          </Router>
        </PickupProvider>
      </QueryClientProvider>
    </ShippingSelectionProvider>
  );
};

export default App; 