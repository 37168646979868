import { useQuery } from '@tanstack/react-query';
import { trialApi } from '../api/endpoints/trials';
import type { Trial } from '../types/models/trial';

export const useTrials = () => {
  const { 
    data: trials, 
    isLoading, 
    error 
  } = useQuery<Trial[]>(
    ['trials'],
    async () => {
      const response = await trialApi.getTrials();
      return response.data;
    },
    {
      staleTime: 30000, // Cache for 30 seconds
      refetchOnWindowFocus: false, // Don't refetch when window regains focus
      retry: 1, // Only retry once on failure
    }
  );

  return {
    trials: trials || [],
    isLoading,
    error
  };
}; 